import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Wizard } from 'src/components/Wizard';
import { Color } from 'src/constants/Color';
import {
  createOrder,
  openOrderForShoppers,
  poolRunnerApplications,
  assignAShopper,
  declineAShopper,
  rateARunner,
  getActiveOrder,
} from 'src/redux/order/actions/orderActionCreator';
import { IOrderReducer } from 'src/redux/order/orderReducer';
import { useStateSelector } from 'src/redux/reducers';
import { IUserState } from 'src/redux/user/userReducer';
import { WizardContainer } from './Orderpage.styles';
import { USER_TYPE } from 'src/constants';
import { validateUserProfile, validatePaymentProfile } from 'src/helpers/user';

const OrderPage: React.FC = () => {
  const { orderId, orderState, data } = useStateSelector<IOrderReducer>((state) => state.order);
  const { profile }: IUserState = useSelector<any, IUserState>((state) => state.user);
  const [intervalId, setIntervalId] = React.useState<any>('');
  const dispatch = useDispatch();
  const history = useHistory();

  const getUserShoppingListAndChatHistory = (shoppingOrder, estOrderAmount, numOfstores, context) => {
    if (profile?.id) {
      dispatch(
        createOrder({
          buyerId: profile?.id,
          shoppingList: shoppingOrder,
          estOrderAmount,
          numberOfStores: numOfstores,
          ...context,
        }),
      );
    }
  };

  const getRating = (rating: number) => {
    dispatch(rateARunner({ orderId, rating }));
  };

  const getRunnerAccepted = (isAccepted, runnerId) => {
    if (isAccepted) {
      dispatch(assignAShopper({ runnerId, orderId, orderState: 'Assigned' }));
    } else {
      dispatch(declineAShopper({ runnerId, orderId, decline: true }));
    }
  };

  const cancelOrder = () => {
    dispatch(openOrderForShoppers({ orderId, orderState: 'Cancelled' }));
  };

  React.useEffect(() => {
    if(!validateUserProfile(profile).isValid) {
      history.push("/profile");
    } else if (!validatePaymentProfile(USER_TYPE.BUYER, profile).isValid) {
      history.push("/payment");
    }

    if (orderState.toLowerCase() === 'cancelled') {
      clearInterval(intervalId);
      history.push('/');
      window.location.reload(); // TODO: Clear state in other way
    }
    if (!orderId) {
      dispatch(getActiveOrder());
    } else if (orderId && orderState.toLowerCase() === 'created') {
      dispatch(openOrderForShoppers({ orderId, orderState: 'Pending' }));
    } else if (orderId && orderState.toLowerCase() !== 'delivered') {
      if (!intervalId) {
        const timerId = setInterval(() => {
          dispatch(poolRunnerApplications({ orderId }));
        }, 10000);
        setIntervalId(timerId);
      }
    }

    if (orderId && orderState.toLowerCase() === 'delivered') {
      return () => clearInterval(intervalId);
    }
  }, [data, dispatch, history, intervalId, orderId, orderState, profile]);

  return (
    <WizardContainer>
      <Wizard
        orderId={orderId}
        shoppingDetails={data}
        buyerInfo={profile}
        userApprovalEventHandler={getUserShoppingListAndChatHistory}
        userRunnerEventHandler={getRunnerAccepted}
        getRating={getRating}
        cancelOrder={cancelOrder}
      />
    </WizardContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.order.orderId,
    orderSate: state.order.orderSate,
    runnerApplications: state.shoppingList.data.runnerApplications,
  };
};

export default connect(mapStateToProps, null)(OrderPage);
