// This is a WIP component, this will be modified and broken to modules and hooks respectively
import Location from '@material-ui/icons/LocationOn';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { shoppingAPI } from 'src/api/shopping';
import { Button } from 'src/components/Button';
import { Loading } from 'src/components/Loading';
import { getValuesByState } from 'src/helpers/orderHelpers';
import { ErrorMessage } from 'src/pages/onboarding/onboarding.styles';
import { getShoppingList } from 'src/redux/shoppingList/shoppingListActionCreator';
import { IShoppingListReducer } from 'src/redux/shoppingList/shoppingListReducer';
import { getShoppingDetailSelector } from 'src/selectors';
import { IShoppingDetail } from 'src/types';
import { StyledDiv } from './styles/orderDetails.style';
import { defaultMemoize } from 'reselect';

interface IProps extends RouteComponentProps<any> {
  hashId: string;
  isMap: boolean;
}

const OrderDetails: React.FC<IProps> = ({ hashId, isMap, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const dispatch = useDispatch();
  const formatMessage = useIntl();

  const { isLoading, loaded }: IShoppingListReducer = useSelector<any, IShoppingListReducer>(
    (state) => state.shoppingList,
  );

  const { isLoading: mapDataLoading, loaded: mapDataLoaded }: IShoppingListReducer = useSelector<
    any,
    IShoppingListReducer
  >((state) => state.mapDataList); // FIXME: am i redundant // FIXME: check the error cause

  const shoppingDetail: IShoppingDetail = useSelector(
    (state) => getShoppingDetailSelector(state, hashId, isMap),
    shallowEqual,
  );

  const applyShopping = React.useCallback(async () => {
    setLoading(true);
    try {
      await shoppingAPI
        .applyShoppingOrder(hashId)
        .then(() => dispatch({ type: getShoppingList.REQUESTED }))
        .then(() => rest.history.push('/runner'));
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  }, [dispatch, hashId, rest.history]);

  const cancelShopping = React.useCallback(async () => {
    setLoading(true);
    try {
      await shoppingAPI.cancelShoppingOrder(hashId).then(() => rest.history.push('/runner'));
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  }, [hashId, rest.history]);

  const startShopping = React.useCallback(async () => {
    setLoading(true);
    try {
      await shoppingAPI
        .updateShoppingOrder(hashId, { state: 'InProgress' })
        .then(() => rest.history.push(`/runner?active_id=${hashId}&active_shopping=true`));
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(true);
    }
  }, [hashId, rest.history]);

  if ((!loaded || !mapDataLoaded) && (isLoading || mapDataLoading)) return <Loading />;
  if ((loaded || mapDataLoaded) && !shoppingDetail) return <a href="/runner">go back</a>;

  const {
    id,
    data: { buyerStreetAddress, shoppingList, estOrderAmount, state, appliedByMe },
  } = shoppingDetail;

  let ButtonComponent = <Button fullWidth variant="primary" text="Error" color="#5995f2" />;

  switch (state) {
    case 'Pending':
      if (appliedByMe) {
        ButtonComponent = (
          <Button
            fullWidth
            variant="primary"
            buttonBackground={getValuesByState(state, appliedByMe).orderDetailsButtonColor}
            onClick={cancelShopping}
            text={getValuesByState(state, appliedByMe, formatMessage).orderDetailsButton}
          />
        );
      } else {
        ButtonComponent = (
          <Button
            fullWidth
            variant="primary"
            buttonBackground={getValuesByState(state, appliedByMe).orderDetailsButtonColor}
            onClick={applyShopping}
            text={getValuesByState(state, appliedByMe, formatMessage).orderDetailsButton}
          />
        );
      }
      break;
    case 'Assigned':
      ButtonComponent = (
        <Button
          fullWidth
          variant="primary"
          text={getValuesByState(state, appliedByMe, formatMessage).orderDetailsButton}
          onClick={startShopping}
          loading={loading}
          buttonBackground={getValuesByState(state, appliedByMe).orderDetailsButtonColor}
        />
      );
      break;
    case 'InProgress':
      ButtonComponent = (
        <Button
          fullWidth
          variant="primary"
          text={getValuesByState(state, appliedByMe, formatMessage).orderDetailsButton}
          onClick={() => rest.history.push(`/runner?active_id=${hashId}&active_shopping=true`)}
          loading={loading}
          buttonBackground={getValuesByState(state, appliedByMe, formatMessage).orderDetailsButtonColor}
        />
      );
      break;
    case 'Completed':
      ButtonComponent = (
        <Button
          fullWidth
          variant="primary"
          text="Continue Shopping"
          onClick={() => rest.history.push(`/runner?active_id=${hashId}&active_shopping=true`)}
          loading={loading}
          buttonBackground={getValuesByState(state, appliedByMe).orderDetailsButtonColor}
        />
      );
      break;
  }

  return (
    <StyledDiv key={id}>
      <div className="location">
        <Location />
        <span className="address">{buyerStreetAddress}</span>
      </div>
      <div className="shops">
        <ul>
          {shoppingList.map((item, index) => (
            <li key={item.storeNumber + index}>
              <div className="pointer" />
              <div className="shop-details">
                <div className="name">{item.store}</div>
                <div className="content" dangerouslySetInnerHTML={{ __html: item.items.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {shoppingDetail.data.hasSpecialDeliveryConditions && 
      <div className="delivery-conditions">
          <label>{formatMessage.formatMessage({id: "comment_from_buyer", defaultMessage: "Comment from buyer" })}</label>
          {shoppingDetail.data.specialDeliveryConditions}
      </div>}
      <div className="footer">
        <div className="summary">
          <label>{formatMessage.formatMessage({id: "common_estimatedcost", defaultMessage: "Estimated cost" })}</label>
          <span className="estimated-value">{estOrderAmount} kr</span>
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div className="buttonWrapper">
          {ButtonComponent}
        </div>
      </div>
    </StyledDiv>
  );
};

export default withRouter(OrderDetails);
