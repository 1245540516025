import * as React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MenuButton } from 'src/components/Button';
import { Map } from 'src/components/Map';
import { getOrdersMap } from 'src/redux/shoppingList/shoppingListActionCreator';
import { IShoppingListReducer } from 'src/redux/shoppingList/shoppingListReducer';

const MapContainer: React.FC<RouteComponentProps> = ({ ...rest }) => {
  const { data, loaded }: IShoppingListReducer = useSelector<any, IShoppingListReducer>(
    (state) => state.mapDataList,
    shallowEqual,
  ); // FIXME: check the error cause
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!loaded) {
      dispatch({ type: getOrdersMap.REQUESTED });
    }
  }, [data, dispatch, loaded]);

  return (
    <Map locations={data} {...rest}>
      <div
        style={{
          position: 'absolute',
          height: 20,
          width: 20,
          top: 10,
          left: 10,
          zIndex: 1,
          color: 'red',
        }}
      >
        <MenuButton />
      </div>
    </Map>
  );
};

const connected = withRouter(MapContainer);

export default connected;
