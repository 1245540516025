import * as React from 'react';
import { Bubble, BlueBubble } from 'src/components/Bubble';
import { MobButton } from 'src/components/Button';
import { Paragraph } from 'src/components/Typography';
import { typingDelayInMs } from 'src/constants/General';

export interface IProps {
  onClickHandler: (isAccepted: boolean, runnerId: string) => void;
  eventVal1: boolean;
  eventVal2: boolean;
  caption1: string;
  caption2: string;
  runnerId?: string;
  buyerResponse: string;
}

const RunnerNotification: React.FC<IProps> = (props) => {
  const onClickHandler = (isAccepted, runnerId) => {
    props.onClickHandler(isAccepted, runnerId);
  };

  const [showInput, setShowInput] = React.useState<boolean>(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowInput(true), typingDelayInMs);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Bubble typing={true}>
        <Paragraph>{props.children}</Paragraph>
      </Bubble>
      {showInput && (
        <React.Fragment>
          <div style={{ display: props.buyerResponse === '' ? 'block' : 'none' }}>
            <MobButton
              variant="mbutton"
              onClick={() => onClickHandler(props.eventVal2, props.runnerId)}
              text={props.caption2}
              impression="negative"
            />
            <MobButton
              variant="mbutton"
              onClick={() => onClickHandler(props.eventVal1, props.runnerId)}
              text={props.caption1}
              impression="positive"
            />
          </div>
          <div>{props.buyerResponse !== '' ? <BlueBubble width="349px">{props.buyerResponse}</BlueBubble> : ''}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default RunnerNotification;
