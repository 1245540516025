import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { useFirebase } from 'src/components/Firebase';
import { PhoneNumberInput } from 'src/components/Input';
import { Wrapper } from 'src/components/Wrapper';
import { StyledCentered } from 'src/styles';
import { Container, Logo, SubHeader, Description, PhoneInput, ErrorMessage } from '../onboarding.styles';

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const { firebase, signInWithPhoneNumber, setRecaptchaVerifier } = useFirebase();
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [error, setError] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('47');
  const { formatMessage } = useIntl();

  const handleLoginClick = async () => {
    try {
      const fullPhoneNumber = `+${countryCode}${phoneNumber}`;
      const result = await signInWithPhoneNumber(fullPhoneNumber);

      if (result) {
        history.push('/verification');
      }
    } catch (error) {
      setError(error?.message);
    }
  };

  React.useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    setRecaptchaVerifier(recaptchaVerifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper wrapperHeight="100vh">
      <Container>
        <Logo>
          <img src="images/svop_logo.png" />
        </Logo>
        <SubHeader>
          <FormattedMessage id="register_phone_title" defaultMessage="Create an account with your phone number" />
        </SubHeader>
        <PhoneInput>
          <PhoneNumberInput
            countryCode={countryCode}
            placeholder={formatMessage({
              id: 'register_phone_phoneplaceholder',
              defaultMessage: 'Mobile phone number',
            })}
            phoneNumber={phoneNumber}
            onChangeCountryCode={(evt: any) => {
              setCountryCode(evt?.target?.value);
            }}
            onChangePhoneNumber={(evt: any) => {
              setPhoneNumber(evt?.target?.value);
            }}
          ></PhoneNumberInput>
          <Button
            text={formatMessage({ id: 'register_phone_sendcode', defaultMessage: 'Send one time code' })}
            variant="primary"
            id="send-sms-button"
            fullWidth
            buttonSize="XS"
            onClick={handleLoginClick}
          />
        </PhoneInput>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Description>
          <FormattedMessage id="register_phone_footertext" defaultMessage=" " />
        </Description>
        <div id="recaptcha-container" />
      </Container>
    </Wrapper>
  );
};
