import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { QuestionWithRadio } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';

const step2 = ({ buyerStep2StoresHandler, stores, numberOfStores }) => (
  <div>
    <QuestionWithRadio
      typing={true}
      eventHandler={buyerStep2StoresHandler}
      stores={stores}
      buyerResponse={numberOfStores}
    >
      <Paragraph>
        <FormattedMessage
          id="buyer_wizard_numberofstores"
          defaultMessage="Superb. How many stores do \nyou need us to visit?"
        />
      </Paragraph>
    </QuestionWithRadio>
  </div>
);

export default step2;
