import * as React from 'react';
import { useDispatch } from 'react-redux';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'src/components/Firebase/FirebaseContext';
import { PRIVATE_ROUTES } from 'src/constants';
import { validateUserProfile, validatePaymentProfile } from 'src/helpers/user';
import { useStateSelector } from 'src/redux/reducers';
import { fetchLatestTerms, getUserProfile } from 'src/redux/user/userActionCreator';

export const AuthenticateRoute: React.FC<RouteConfigComponentProps> = ({ route }) => {
  const { firebase } = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = firebase.auth().currentUser;
  const { userType, latestTerms, profile, isUpdatingUserProfile, isLoadingUserProfile } = useStateSelector(
    ({ user }) => user,
  );

  React.useEffect(() => {
    if (user) {
      dispatch(getUserProfile());
      dispatch(fetchLatestTerms(userType));
    }
  }, [user, userType, dispatch]);

  React.useEffect(() => {
    if (!profile || !latestTerms || isUpdatingUserProfile || isLoadingUserProfile) {
      return;
    }

    const hasUserAcceptedLatestTerm = profile.terms.find(({ termsId }) => termsId === latestTerms.id);

    if (!hasUserAcceptedLatestTerm) {
      return history.push(PRIVATE_ROUTES.TERMS);
    }

    if (!validateUserProfile(profile).isValid) {
      return history.replace(PRIVATE_ROUTES.PROFILE);
    }

    if (!validatePaymentProfile(userType, profile).isValid) {
      return history.replace(PRIVATE_ROUTES.PAYMENT);
    }

    return history.replace(PRIVATE_ROUTES.ROOT);
  }, [latestTerms, profile, history, userType, isUpdatingUserProfile, isLoadingUserProfile]);

  // Add here authentication logic
  return (profile && route && renderRoutes(route.routes)) || null;
};
