import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { Bubble, BlueBubble } from 'src/components/Bubble';
import { MobButton } from 'src/components/Button';
import { Message, WelcomeMessage } from 'src/components/Message';
import RunnerNotification from 'src/components/Notifications/RunnerNotification';
import { QuestionWithAnswerInput, QuestionWithButton, EditAnswer } from 'src/components/Questions';
import Rating from 'src/components/Rating/Rating';
import { Paragraph } from 'src/components/Typography';
import { Fee, obligedMessage, getMessage1, getMessage2 } from 'src/constants/General';
import { cleanObject } from 'src/helpers/general';
import { IRunnerApplication } from 'src/types';
import { IProfile } from 'src/types/user';
import Element from './Element';
import Header from './header';
import generateChatHistory, { canCancelOrder } from './helpers';
import OrderHistory from './OrderHistory';
import { Step1, Step2, Step3, Step4, Step5, AlternateQuestion } from './steps';
import { Stub, WizardStep } from './Wizard.style';
import './css/styles.css';
import { shopping } from 'src/redux/shopping/shoppingActionCreator';

export interface IContext {
  acceptItemReplacements: boolean;
  alternateDeliveryAddress: boolean;
  buyerCity?: string;
  buyerZipCode?: string;
}

export interface IWizzardProp {
  userApprovalEventHandler: (shoppingList: any[], estAmount: number, numOfStores: number, context: IContext) => void;
  userRunnerEventHandler: (isAccepted: boolean, runnerId: string) => void;
  getRating: (rating: number) => void;
  buyerInfo?: IProfile;
  orderId?: string;
  shoppingDetails: any;
  cancelOrder: () => void;
}

interface IEditProp {
  editType: string;
  data: any;
}

const Wizard: React.FC<IWizzardProp> = ({
  userApprovalEventHandler,
  userRunnerEventHandler,
  getRating,
  buyerInfo,
  shoppingDetails,
  cancelOrder,
  orderId,
}) => {
  const [storeList, updateStoreList] = React.useState<any[]>([]);
  const [chatHistory, updateChatHistory] = React.useState<any[]>([]);

  const [isSpecialOrder, setIsSpecialOrder] = React.useState(false);
  const [alternateDicisionText, setalternateDicisionText] = React.useState('');

  const [isBuyerStep1, setBuyerStep1Status] = React.useState(false);
  const [buyerStep1Answer, setBuyerStep1Answer] = React.useState('');

  const [isBuyerStep3, setBuyerStep3Status] = React.useState(false);
  const [storesSelected, updateStoresSelected] = React.useState(0);
  const [freeTextAnswer, setFreeTextAnswer] = React.useState('');
  const [storeCounter, setStoreCounter] = React.useState(0);
  const [storeQCounter, setStoreQCounter] = React.useState(0);
  const [storeAnswers, updateStoreAnswers] = React.useState<any[]>([]);
  const [estOrderAmount, setEstOrderAmount] = React.useState(0);
  const [rating, setRating] = React.useState(0);
  const [isOrderConfirm, setGoAheadOrderStatus] = React.useState(false);
  const [goAheadAnswer, setGoAheadAnswer] = React.useState('');
  const [showHistory, setShowHistory] = React.useState(!!orderId && orderId != '');
  // Allow Replacement Items
  const [askAcceptItemReplacements, setAskAcceptItemReplacements] = React.useState(false);
  const [acceptItemReplacements, setAcceptItemReplacements] = React.useState(false);
  // alternate address
  const [askAlternateAddress, setAskAlternate] = React.useState(false);
  const [alternateDeliveryAddress, setalternateDeliveryAddress] = React.useState(false);
  const [alternateAddress, setAlternateAddress] = React.useState('');

  //Special Delivery Conditions
  const [askSpecialDeliveryCondition, setAskSpecialDeliveryCondition] = React.useState(false);
  const [specialDeliveryCondition, setSpecialDeliveryCondition] = React.useState(false);
  const [specialDeliveryText, setSpecialDeliveryText] = React.useState('');

  const [displayStep5, setDisplayStep5] = React.useState(false);
  
  const [editDisplay, setEditDisplay] = React.useState<boolean>(false);
  const [editIsFreeText, setEditIsFreeText] = React.useState<boolean>(false);
  const [editInputType, setEditInputType] = React.useState<string>('');
  const [editIdentifier, setEditIdentifier] = React.useState<string>('');
  const [editProps, setEditProps] = React.useState<IEditProp>();
  const [editPlaceHolder, setEditPlaceHolder] = React.useState<string>('');
  const [editValue, setEditValue] = React.useState<string>('');

  /*
    TODO: Will clean this up useless functions
  */
  const handleReplacementItem = (value) => {
    setAcceptItemReplacements(value);
    setAskAcceptItemReplacements(true);
  };

  const handleAddressAlternate = (value) => {
    setalternateDeliveryAddress(!value);
    if (value) setAskAlternate(value);
  };

  const handleSpecialDeliveryCondition = (value) => {
    setSpecialDeliveryCondition(value);
    setAskSpecialDeliveryCondition(true);
    if (!value) {
      setDisplayStep5(true);
    }
    //if (value === true) setDisplayStep5(true);
  };

  const [acceptedRunner, setRunner] = React.useState<IRunnerApplication | undefined>({
    runnerFirstName: '',
    runnerRating: 0,
    timeApplied: '',
  });
  const [isRunnerAccepted, setRunnerAccepted] = React.useState(false);

  const { runnerApplications, state, totalAmount, shoppingList, runnerId, receipts, orderNumber, isComplimentary } = shoppingDetails;
  const { formatMessage } = useIntl();
  const formatter = useIntl();
  const history = useHistory();
  const storesArray: number[] = [1, 2, 3];
  const questions: string[] = [
    formatMessage({ id: 'buyer_wizard_store_items', defaultMessage: 'What do you need us to buy from the ' }),
    formatMessage({ id: 'buyer_wizard_store_location', defaultMessage: ' What’s the name or type of the ' }),
  ];
  const storeLabels = [
    formatMessage({ id: 'store_label_1', defaultMessage: 'first store' }),
    formatMessage({ id: 'store_label_2', defaultMessage: 'second store' }),
    formatMessage({ id: 'store_label_3', defaultMessage: 'third store' }),
  ];

  const intlShape = useIntl();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const commonYes = formatMessage({ id: 'common_yes', defaultMessage: 'Yes' });
  const commonNo = formatMessage({ id: 'common_no', defaultMessage: 'No' });

  const updateViewportSize = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
      return;
    }

    setIsMobile(true);
  };

  React.useEffect(() => {
    setShowHistory(!!orderId && orderId != '');

    if(shoppingDetails) {
      if(shoppingDetails.isSpecialOrder !== undefined) { 
        setIsSpecialOrder(shoppingDetails.isSpecialOrder);
      }

      if(shoppingDetails.numberOfStores !== undefined) { 
        setStoreCounter(shoppingDetails.numberOfStores);
      }
    }
    
    if(runnerApplications) {
      const runner = runnerApplications.find(ra => ra.runnerId == runnerId);
      if(runner) {
        setRunner(runner);
      }
    }
  }, [orderId]);

  //TODO: move me to a custom hook
  React.useEffect(() => {
    updateViewportSize();

    window.addEventListener('resize', updateViewportSize);

    return () => {
      window.removeEventListener('resize', updateViewportSize);
    };
  }, []);

  React.useEffect(() => {
    if (!!buyerStep1Answer && freeTextAnswer == '') {
      Scroll.animateScroll.scrollToBottom({ smooth: true });
    }
  }, [buyerStep1Answer, freeTextAnswer]);

  const editStoreAnswer = (storeIndex: number, qstnIndex: number, answer: string) => {
    setEditDisplay(true);
    setEditIsFreeText(true);
    setEditIdentifier(storeIndex + "_" + qstnIndex);
    setEditInputType("text");
    setEditPlaceHolder("Placeholder");
    setEditValue(answer);
  }

  const printStep3Answers = () => {
    return storeAnswers.map((st, i) => {
      return st.store.map((answer, q) => {
        // TODO: will gone enable them for order detail history.
        // updateChatHistory(generateChatHistory(str, chatHistory));
        // updateChatHistory(generateChatHistory(answer, chatHistory));
        return (
          <div key={new Date().getTime() + '_' + Math.random()}>
            <Bubble>
              <Paragraph>{`${questions[q]} ${storeLabels[i]}?`}</Paragraph>
            </Bubble>
            <div className="answer">
              {/* <BlueBubble onClickHandler={() => { editStoreAnswer(i, q, answer) }}>{answer}</BlueBubble> */}
              <BlueBubble>{
                (!q && <span dangerouslySetInnerHTML={{ __html: answer.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></span>) || answer}</BlueBubble>
            </div>
          </div>
        );
      });
    });
  };

  const buyerStep1DecisionHandler = (isUserAccept: boolean, question: string) => {
    const text = isUserAccept
      ? `${formatMessage({ id: 'common_yes', defaultMessage: 'Yes' })}`
      : formatMessage({ id: 'common_no', defaultMessage: 'No' });
    setBuyerStep1Answer(text);
    setBuyerStep1Status(isUserAccept);
    updateChatHistory(generateChatHistory(question, chatHistory));
    updateChatHistory(generateChatHistory(text, chatHistory));
  };

  const alternateDicisionHandler = (isUserAccept: boolean, question: string) => {
    const text = isUserAccept
      ? `${formatMessage({ id: 'common_yes', defaultMessage: 'Yes' })}`
      : formatMessage({ id: 'common_no', defaultMessage: 'No' });
    setalternateDicisionText(text);
    setIsSpecialOrder(isUserAccept);
    updateChatHistory(generateChatHistory(question, chatHistory));
    updateChatHistory(generateChatHistory(text, chatHistory));
  };

  const goAheadHandler = (isUserConfirm: boolean, question: string) => {
    const text = isUserConfirm
      ? `${formatMessage({ id: 'common_goahead', defaultMessage: 'Go ahead' })}!`
      : formatMessage({ id: 'common_cancel', defaultMessage: 'Cancel' });
    setGoAheadAnswer(text);
    setGoAheadOrderStatus(isUserConfirm);
    updateChatHistory(generateChatHistory(question, chatHistory));
    updateChatHistory(generateChatHistory(text, chatHistory));
    if (isUserConfirm) {
      const context = {
        acceptItemReplacements,
        alternateDeliveryAddress,
        isSpecialOrder,
        buyerCity: alternateDeliveryAddress ? '' : undefined,
        buyerZipCode: alternateDeliveryAddress ? '' : undefined,
        buyerStreetAddress: alternateDeliveryAddress ? alternateAddress : undefined,
        hasSpecialDeliveryConditions: specialDeliveryCondition,
        specialDeliveryConditions: specialDeliveryText,
      };
      userApprovalEventHandler(storeList, estOrderAmount, storesSelected, cleanObject(context));
      setShowHistory(false);
    } else {
      history.push("/");
    }
  };

  const buyerRunnerDecisionHandler = (isBuyerAccept: boolean, runnerId: string) => {
    const text = isBuyerAccept
      ? `${formatMessage({ id: 'common_accept', defaultMessage: 'Accept' })}`
      : formatMessage({ id: 'common_notnow', defaultMessage: 'Not now' });
    const runner = runnerApplications?.find((runnerObj) => runnerObj.runnerId === runnerId);
    setRunnerAccepted(isBuyerAccept);
    setRunner(runner);
    updateChatHistory(generateChatHistory(runner?.runnerFirstName, chatHistory));
    updateChatHistory(generateChatHistory(text, chatHistory));
    userRunnerEventHandler(isBuyerAccept, runnerId);
  };

  const buyerStep2StoresHandler = (numberOfStores: number) => {
    setStoreCounter(storeCounter);
    setStoreQCounter(storeQCounter);
    updateStoresSelected(numberOfStores);
    updateChatHistory(generateChatHistory('Superb. How many stores do you need us to visit?', chatHistory));
    updateChatHistory(generateChatHistory(numberOfStores, chatHistory));
  };

  const onSendStepHandler = (identifier) => {
    if (identifier === 'estOrderAmount') {
      if (Number(freeTextAnswer) > 0) {
        setEstOrderAmount(Number(freeTextAnswer));
        updateChatHistory(
          generateChatHistory(
            formatMessage({
              id: 'buyer_wizard_estimatedcost',
              defaultMessage: 'Thanks! Approximately how much do you think your total shopping order will cost?',
            }),
            chatHistory,
          ),
        );
        updateChatHistory(generateChatHistory(freeTextAnswer, chatHistory));
        setFreeTextAnswer('');
      }
      return;
    }

    if (freeTextAnswer.trim() === '') {
      return;
    }

    const tempArray = identifier.split('_');
    const currentList = storeAnswers;
    if (!currentList[tempArray[0]]) {
      currentList.push({ store: [] });
      currentList[tempArray[0]].store[tempArray[1]] = freeTextAnswer;
    }
    currentList[tempArray[0]].store[tempArray[1]] = freeTextAnswer;
    const str = questions[tempArray[1]] + ' ' + storeLabels[tempArray[0]] + ' ?';
    updateChatHistory(generateChatHistory(str, chatHistory));
    updateChatHistory(generateChatHistory(freeTextAnswer, chatHistory));
    updateStoreAnswers(currentList);
    setFreeTextAnswer('');
    if (tempArray[0] < storesSelected - 1) {
      if (tempArray[1] < questions.length - 1) {
        setStoreQCounter(storeQCounter + 1);
      } else {
        const tempShoppingList = storeList;
        const Obj: any = {
          store: currentList[storeCounter].store[1],
          items: currentList[storeCounter].store[0],
          paidAmount: 0,
          popbyFee: storeCounter === 0 ? Fee.BASIC : Fee.OTHERS,
          storeNumber: storeCounter + 1,
        };
        tempShoppingList.push(Obj);
        updateStoreList(tempShoppingList);

        setStoreCounter(storeCounter + 1);
        setStoreQCounter(0);
      }
    } else if (Number(tempArray[0]) === storesSelected - 1 && tempArray[1] < questions.length - 1) {
      setStoreQCounter(storeQCounter + 1);
    } else {
      const tempShoppingList = storeList;
      const Obj: any = {
        store: currentList[storeCounter].store[1],
        items: currentList[storeCounter].store[0],
        paidAmount: 0,
        popbyFee: storeCounter === 0 ? Fee.BASIC : Fee.OTHERS,
        storeNumber: storeCounter + 1,
      };
      tempShoppingList.push(Obj);
      updateStoreList(tempShoppingList);
      setBuyerStep3Status(true);
    }
  };

  const onChangeHandler = (evt, identifier) => {
    if (identifier === 'estOrderAmount' && Number(evt.target.value) < 0) {
      return '';
    }
    setFreeTextAnswer(evt.target.value);
  };

  /*
    TODO: Remove me as no use
  */
  const onCommitAddress = () => {
    setAlternateAddress(freeTextAnswer);
    setAskAlternate(true);
    //setDisplayStep5(true);
  };
  const onCommitSpecialCondition = () => {
    setSpecialDeliveryText(freeTextAnswer);
    setDisplayStep5(true);
  };

  const showGoodNewsMessage = () => {
    if (!runnerId && runnerApplications && runnerApplications.length > 0) {
      const availableRunnersList = runnerApplications.filter((runner) => runner.declined === false);

      if (availableRunnersList.length > 1 && state !== 'Assigned') {
        return <Message>{getMessage2(buyerInfo?.firstName, formatter)}</Message>;
      } else if (availableRunnersList.length && state !== 'Assigned') {
        return <Message>{getMessage1(buyerInfo?.firstName, availableRunnersList, formatter)}</Message>;
      }
    }
  };

  /*
    TODO: Remove me as no use
  */
  const onChangeAddress = (evt, identifier) => {
    setFreeTextAnswer(evt.target.value);
  };

  const ratingHandler = (userRating: number) => {
    getRating(userRating);
    setRating(userRating);
  };

  const poolForRunnerApplication = () => {
    if (isRunnerAccepted && acceptedRunner) {
      const { runnerFirstName, runnerRating } = acceptedRunner;
      return (
        <>
          <Message>
            <div className="runner-name">{runnerFirstName}</div>
            <div className="runner-rating">
              <Rating rating={runnerRating} interactive={false} />
            </div>
          </Message>
          <div className="answer">
            <BlueBubble>
              <FormattedMessage id="common_accept" defaultMessage="Accept" />
            </BlueBubble>
          </div>
        </>
      );
    }

    if (runnerApplications?.length && !runnerId) {
      const availableRunners = runnerApplications.filter((runner) => runner.declined === false);
      return availableRunners.map(({ runnerFirstName, runnerId, runnerRating }) => {
        return (
          <RunnerNotification
            eventVal1={true}
            eventVal2={false}
            caption1={formatMessage({ id: 'common_accept', defaultMessage: 'Accept' })}
            caption2={formatMessage({ id: 'common_notnow', defaultMessage: 'Not now' })}
            runnerId={runnerId}
            key={runnerId}
            onClickHandler={buyerRunnerDecisionHandler}
            buyerResponse={''}
          >
            <div className="runner-name">{runnerFirstName}</div>
            <div className="runner-rating">
              <Rating rating={runnerRating} interactive={false} />
            </div>
          </RunnerNotification>
        );
      });
    }
    return '';
  };

  const poolForChanges = () => {
    let message2states = ["inprogress", "completed"];
    let message1states = ["assigned"].concat(message2states);

    return (
      <>
        { message1states.includes(state.toLowerCase()) && acceptedRunner?.runnerFirstName &&
          <Message>
            <FormattedMessage
              id="buyer_wizard_msg_accepted"
              values={{ runnerFirstName: acceptedRunner?.runnerFirstName }}
            />
          </Message>
        }
        { message2states.includes(state.toLowerCase()) && acceptedRunner?.runnerFirstName &&
          <Message>
            <FormattedMessage
              id="buyer_wizard_msg_runnerconfirmation"
              values={{ runnerFirstName: acceptedRunner?.runnerFirstName }}
            />
          </Message>
        }
      </>
    );
  };

  const poolForStorePayments = () => {
    return shoppingList.map(
      (storeItem) =>
        storeItem.paidAmount > 0 && (
          <Message>
            <FormattedMessage
              id="buyer_wizard_msg_shoppinglocationdone"
              values={{ [0]: storeItem.store, [1]: storeItem.paidAmount }}
            />
          </Message>
        ),
    );
  };

  const poolForCompletion = () => {
    let messageStates = ["completed", "delivered"];

    if (messageStates.includes(state.toLowerCase()) && acceptedRunner?.runnerFirstName) {
      return (
        <>
          <Message>
            <FormattedMessage
              id="buyer_wizard_msg_shoppingcompleted"
              values={{ [0]: totalAmount, [1]: acceptedRunner?.runnerFirstName }}
            />
            {!!receipts &&
              <FormattedMessage
                id="buyer_wizard_runner_receipts"
                values={{ runnerFirstName: acceptedRunner?.runnerFirstName }}
              /> &&
              receipts.map((receipt, index) => (
                <>
                  <br />
                  <br />
                  <strong>
                    <FormattedMessage id="common_receipt" defaultMessage="Receipt" /> {index + 1}
                  </strong>
                  <br />
                  <a href={receipt.receiptPhoto} rel="noopener noreferrer" target="_blank">
                    <img
                      src={receipt.receiptPhoto + (receipt.receiptPhoto.indexOf('?') > -1 ? '&' : '?') + 'width=300'}
                    />
                  </a>
                </>
              ))}
          </Message>
        </>
      );
    }
  };

  const poolForDelivery = () => {
    if (state.toLowerCase() === 'delivered' && acceptedRunner?.runnerFirstName) {
      return (
        <>
          <Message>
            <FormattedMessage
              id="buyer_wizard_msg_paymentconfirmed"
              values={{ [0]: acceptedRunner?.runnerFirstName }}
            />
            <div className="runner-name">{acceptedRunner?.runnerFirstName}</div>
            <div className="runner-rating">
              <Rating rating={rating} rateChangeHandler={ratingHandler} interactive={!rating} />
            </div>
          </Message>
          {rating > 0 && 
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <BlueBubble>{rating}</BlueBubble>
              </div>
              <Message>
                {rating == 5 && <FormattedMessage id="buyer_wizard_msg_afiver" defaultMessage="A fiver!" />}
                {rating < 5 && <FormattedMessage id="buyer_wizard_msg_rating" values={{"rating": rating}} defaultMessage="A fiver!" />}
              </Message>
            </>
          }
          <div className="wrapper-mob_button-goback">
            <MobButton
              variant="mbutton"
              text={formatMessage({ id: 'buyer_wizard_closeandgoback', defaultMessage: 'Close and go back' })}
              onClick={goHome}
            />
          </div>
        </>
      );
    }
  };

  const goHome = () => {
    window.location.reload(); // TODO: Clear state in other way
  };

  const handleCancelOrder = () => {
    const confirmed = confirm(
      formatMessage({
        id: 'buyer_cancelorder_confirm',
        defaultMessage: 'Are you sure you want to cancel this shopping request?',
      }),
    );
    if (!confirmed) {
      return;
    }

    const orderHasBeenCreated = shoppingDetails && state;
    if (!orderHasBeenCreated) {
      goHome();
      return;
    }

    cancelOrder();
  };


  return (
    <>
      <Header cancelOrder={handleCancelOrder} canCancelOrder={canCancelOrder(orderId || '', state)} />
      <WizardStep isMobile={isMobile}>
        <WelcomeMessage imageSrc={buyerInfo?.photo} />
        {orderId && showHistory && (
          <OrderHistory
            alternateDeliveryAddress={shoppingDetails.alternateDeliveryAddress}
            acceptItemReplacements={shoppingDetails.acceptItemReplacements}
            shoppingList={shoppingDetails.shoppingList}
            runnerApplications={shoppingDetails?.runnerApplications}
            estOrderAmount={shoppingDetails.estOrderAmount}
            state={shoppingDetails.state}
            runnerId={shoppingDetails?.runnerId}
            buyerName={buyerInfo?.firstName}
            formatter={formatter}
            orderNumber={orderNumber}
            buyerAddress={shoppingDetails.buyerStreetAddress}
            hasSpecialDeliveryConditions={shoppingDetails.hasSpecialDeliveryConditions}
            specialDeliveryConditions={shoppingDetails.specialDeliveryConditions}
            isSpecialOrder={isSpecialOrder}
            isComplimentary={isComplimentary}
          />
        )}    
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {!showHistory && (
          <Step1 buyerStep1DecisionHandler={buyerStep1DecisionHandler} buyerStep1Answer={buyerStep1Answer} />
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {buyerStep1Answer === formatMessage({ id: 'common_no', defaultMessage: 'No' }) && (
          <AlternateQuestion
            alternateDicisionHandler={alternateDicisionHandler}
            alternateDicisionText={alternateDicisionText}
          />
        )}
        {alternateDicisionText === formatMessage({ id: 'common_no', defaultMessage: 'No' }) &&
        <div
          style={{
            width: '349px',
            textAlign: 'center',
          }}
        >
          <MobButton
            variant="mbutton"
            text={formatMessage({ id: 'buyer_wizard_closeandgoback', defaultMessage: 'Close and go back' })}
            onClick={goHome}
          />
        </div>}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {(isBuyerStep1 || isSpecialOrder) && !storeCounter && (
          <Step2
            buyerStep2StoresHandler={buyerStep2StoresHandler}
            stores={storesArray}
            numberOfStores={storesSelected}
          />
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>{printStep3Answers()}</WizardStep>
      <WizardStep isMobile={isMobile} style={{ display: isBuyerStep3 ? 'none' : 'block' }}>
        {storesSelected >= 1 && (
          <Step3
            id={storeCounter + '_' + storeQCounter}
            freeTextAnswer={freeTextAnswer}
            onSendStepHandler={onSendStepHandler}
            onChangeHandler={onChangeHandler}
            questions={questions}
            storeLabels={storeLabels}
            storeQCounter={storeQCounter}
            storeCounter={storeCounter}
          />
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {isBuyerStep3 && (
          <Step4
            estOrderAmount={estOrderAmount}
            onSendStepHandler={onSendStepHandler}
            onChangeHandler={onChangeHandler}
          />
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {!askAcceptItemReplacements && estOrderAmount > 0 && (
          <QuestionWithButton
            userClickHandler={(value) => handleReplacementItem(value)}
            eventVal1={true}
            eventVal2={false}
            caption1={commonYes}
            caption2={commonNo}
            buyerResponse={askAcceptItemReplacements ? (acceptItemReplacements ? commonYes : commonNo) : ''}
            typing={true}
          >
            <Paragraph>
              <FormattedMessage id="buyer_wizard_msg_askreplacementitemn" />
            </Paragraph>
          </QuestionWithButton>
        )}

        {askAcceptItemReplacements && (
          <>
            <Bubble>
              <Paragraph>
                <FormattedMessage id="buyer_wizard_msg_askreplacementitemn" />
              </Paragraph>
            </Bubble>
            <div className="answer">
              <BlueBubble>{acceptItemReplacements ? commonYes : commonNo}</BlueBubble>
            </div>
          </>
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {estOrderAmount > 0 && askAcceptItemReplacements && !askAlternateAddress && (
          <QuestionWithButton
            userClickHandler={(value) => handleAddressAlternate(value)}
            eventVal1={true}
            eventVal2={false}
            caption1={commonYes}
            caption2={commonNo}
            buyerResponse={askAlternateAddress ? (alternateDeliveryAddress ? commonYes : commonNo) : ''}
            typing={true}
          >
            <Paragraph>
              <FormattedMessage id="buyer_wizard_msg_delivertohomeaddress" />
            </Paragraph>
          </QuestionWithButton>
        )}

        {askAlternateAddress && (
          <>
            <Bubble>
              <Paragraph>
                <FormattedMessage id="buyer_wizard_msg_delivertohomeaddress" />
              </Paragraph>
            </Bubble>
            <div className="answer">
              <BlueBubble> {!alternateDeliveryAddress ? commonYes : commonNo}</BlueBubble>
            </div>
          </>
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {alternateDeliveryAddress && !alternateAddress && (
          <QuestionWithAnswerInput
            type="text"
            identifier={'askAcceptReplacement'}
            onCommitAnswer={onCommitAddress}
            onChangeHandler={onChangeAddress}
            buyerResponse={alternateAddress}
            typing={true}
          >
            <Paragraph>
              <FormattedMessage id="buyer_wizard_msg_enterhomeaddress" defaultMessage="Please type your address" />
            </Paragraph>
          </QuestionWithAnswerInput>
        )}

        {alternateAddress && (
          <>
            <Bubble>
              <Paragraph>
                <FormattedMessage id="buyer_wizard_msg_enterhomeaddress" />
              </Paragraph>
            </Bubble>
            <div className="answer">
              <BlueBubble> {alternateAddress}</BlueBubble>
            </div>
          </>
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {estOrderAmount > 0 && askAcceptItemReplacements && askAlternateAddress && !askSpecialDeliveryCondition && (
          <QuestionWithButton
            userClickHandler={(value) => handleSpecialDeliveryCondition(value)}
            eventVal1={true}
            eventVal2={false}
            caption1={commonYes}
            caption2={commonNo}
            buyerResponse={askSpecialDeliveryCondition ? (specialDeliveryCondition ? commonYes : commonNo) : ''}
            typing={true}
          >
            <Paragraph>
              <FormattedMessage id="buyer_has_special_delivery_conditions" />
            </Paragraph>
          </QuestionWithButton>
        )}

        {specialDeliveryCondition && (
          <>
            <Bubble>
              <Paragraph>
                <FormattedMessage id="buyer_has_special_delivery_conditions" />
              </Paragraph>
            </Bubble>
            <div className="answer">
              <BlueBubble> {specialDeliveryCondition ? commonYes : commonNo}</BlueBubble>
            </div>
          </>
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {!displayStep5 && specialDeliveryCondition && !specialDeliveryText && (
          <QuestionWithAnswerInput
            type="text"
            identifier={'askDeliveryCondition'}
            onCommitAnswer={onCommitSpecialCondition}
            onChangeHandler={onChangeHandler}
            buyerResponse={specialDeliveryText}
            typing={true}
          >
            <Paragraph>
              <FormattedMessage
                id="buyer_special_delivery_conditions"
                defaultMessage="Inform the Runner about the delivery conditions"
              />
            </Paragraph>
          </QuestionWithAnswerInput>
        )}

        {specialDeliveryText && (
          <>
            <Bubble>
              <Paragraph>
                <FormattedMessage id="buyer_special_delivery_conditions" />
              </Paragraph>
            </Bubble>
            <div className="answer">
              <BlueBubble> {specialDeliveryText}</BlueBubble>
            </div>
          </>
        )}
      </WizardStep>
      {editDisplay && 
        <WizardStep isMobile={isMobile}>
          <EditAnswer 
            isFreeText={true} 
            value={editValue} 
            inputType={editInputType} 
            identifier={editIdentifier} 
            placeholder={editPlaceHolder} 
            onChangeHandler={() => {}} 
            onCommitChange={() => {}} />
        </WizardStep>
      }
      <WizardStep isMobile={isMobile}>
        {estOrderAmount > 0 && displayStep5 && (
          <Step5
            isSpecialOrder={isSpecialOrder}
            buyerName={buyerInfo?.firstName}
            storesSelected={storesSelected}
            goAheadHandler={goAheadHandler}
            goAheadAnswer={goAheadAnswer}
          />
        )}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {isOrderConfirm && <Message>{obligedMessage(intlShape, orderNumber || 'XXX', isSpecialOrder)}</Message>}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && showGoodNewsMessage()}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && poolForRunnerApplication()}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && poolForChanges()}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && poolForStorePayments()}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && poolForCompletion()}
      </WizardStep>
      <WizardStep isMobile={isMobile}>
        {runnerApplications && runnerApplications.length > 0 && poolForDelivery()}
      </WizardStep>
      {!!buyerStep1Answer && <Stub />}
    </>
  );
};

export default Wizard;
