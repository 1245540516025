import { ActionType, getType } from 'typesafe-actions';
import { increment } from './counterActionCreator';

export interface ICounter {
  count: number;
}

const initialState: ICounter = {
  count: 0,
};

export function counterReducer(state: ICounter = initialState, action: ActionType<typeof increment>): ICounter {
  switch (action.type) {
    case getType(increment):
      return {
        count: state.count + 1,
      };
    default:
      return state;
  }
}
