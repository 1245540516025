import axios from 'axios';
import { getToken } from 'src/helpers';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 10000,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers['ocp-apim-subscription-key'] = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});
