import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { QuestionWithButton } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';

const Step1 = ({ buyerStep1DecisionHandler, buyerStep1Answer }) => {
  const { formatMessage } = useIntl();
  return (
    <QuestionWithButton
      userClickHandler={buyerStep1DecisionHandler}
      eventVal1={true}
      eventVal2={false}
      caption1={formatMessage({ id: 'common_yes', defaultMessage: 'Yes' })}
      caption2={formatMessage({ id: 'common_no', defaultMessage: 'No' })}
      buyerResponse={buyerStep1Answer}
      typing={true}
    >
      <Paragraph>
        <FormattedMessage
          id="buyer_wizard_specialorder"
          defaultMessage="Can we buy the goods you need nearby you and carry them easily?"
        />
      </Paragraph>
    </QuestionWithButton>
  );
};

export default Step1;
