const generateChatHistory = (val: any, chatArray: string[]) => {
  if (chatArray.indexOf(val) === -1) {
    chatArray.push(val);
  }
  return chatArray;
};

export const canCancelOrder = (orderId: string, orderState: string): boolean =>
  !orderId || ['Created', 'Pending'].includes(orderState);

export default generateChatHistory;
