import autobind from 'autobind-decorator';
import { call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { orderAPI } from 'src/api/order';
import { orderActions } from 'src/redux/order/actions/orderActionCreator';
import { BaseSaga } from '../../BaseSaga';

export class OrderSaga extends BaseSaga {
  @autobind
  public *makeSpecialOrder(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      const data = yield call(orderAPI.makeSpecialOrder, args.data);
      yield put({ type: orderActions.SHOPPING_ORDER_SPECIAL_SUCCESS, payload: data });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *createOrder(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      console.warn(args.data);
      const data = yield call(orderAPI.createOrder, args.data);
      yield put({ type: orderActions.SHOPPING_ORDER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *openOrderForShoppers(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      const data = yield call(orderAPI.patchOrderForShoppers, args.data);
      yield put({ type: orderActions.OPEN_ORDER_FOR_SHOPPERS_SUCCESS, payload: data });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *poolForRunner(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      const data: any | undefined = yield call(orderAPI.poolForRunners, args.data);
      yield put({ type: orderActions.POOL_RUNNER_APPLICATIONS_SUCCESS, payload: data[0] });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *assignAShopper(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      yield call(orderAPI.assignAShopper, args.data);
      yield put({ type: orderActions.ASSIGN_A_SHOPPER_SUCCESS });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *declineAShopper(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      yield call(orderAPI.declineAShopper, args.data);
      yield put({ type: orderActions.DECLINE_A_SHOPPER_SUCCESS });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *rateARunner(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      yield call(orderAPI.rateARunner, args.data);
      yield put({ type: orderActions.RATE_A_RUNNER_SUCCESS });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *getActiveOrder(): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.REQUEST_PENDING });
      const data: any | undefined = yield call(orderAPI.getActiveOrder);
      yield put({ type: orderActions.ACTIVE_ORDER_SUCCESS, payload: { id: data[0]?.id, data: data[0]?.data } });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  @autobind
  public *cancelOrder(args): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: orderActions.CANCEL_ORDER_REQUEST });
      const data = yield call(orderAPI.patchOrderForShoppers, args.data);
      yield put({ type: orderActions.CANCEL_ORDER_SUCCESS, payload: data });
    } catch (e) {
      yield put({ type: orderActions.REQUEST_ERROR, error: e });
    }
  }

  protected *registerListeners(): IterableIterator<ForkEffect> {
    yield takeLatest(orderActions.SHOPPING_ORDER_SPECIAL, this.makeSpecialOrder);
    yield takeLatest(orderActions.SHOPPING_ORDER_CREATE, this.createOrder);
    yield takeLatest(orderActions.OPEN_ORDER_FOR_SHOPPERS, this.openOrderForShoppers);
    yield takeLatest(orderActions.POOL_RUNNER_APPLICATIONS, this.poolForRunner);
    yield takeLatest(orderActions.ASSIGN_A_SHOPPER, this.assignAShopper);
    yield takeLatest(orderActions.DECLINE_A_SHOPPER, this.declineAShopper);
    yield takeLatest(orderActions.RATE_A_RUNNER, this.rateARunner);
    yield takeLatest(orderActions.ACTIVE_ORDER, this.getActiveOrder);
    yield takeLatest(orderActions.CANCEL_ORDER_REQUEST, this.cancelOrder);
  }
}
