import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionWithButton } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';

const AlternateQuestion = ({ alternateDicisionHandler, alternateDicisionText }) => {
  const { formatMessage } = useIntl();
  return (
    <QuestionWithButton
      userClickHandler={alternateDicisionHandler}
      eventVal1={true}
      eventVal2={false}
      caption1={formatMessage({ id: 'common_yes', defaultMessage: 'Yes!' })}
      caption2={formatMessage({ id: 'common_no', defaultMessage: 'No' })}
      buyerResponse={alternateDicisionText}
      typing={true}
    >
      <Paragraph>
        <FormattedMessage
          id="buyer_wizard_specialorder_alternative"
          defaultMessage="OK. Our shoppers are foremost available for smaller assignments and are moving by foot or bike. So, we must inform
        you that if the goods are very expensive or they are located in stores which will require transportation, then it
        might take some time before we find a shopper for you. Do you want to continue?"
        />
      </Paragraph>
    </QuestionWithButton>
  );
};

export default AlternateQuestion;
