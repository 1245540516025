import { createAsyncType } from 'src/utils';

// Action Types
export const USER_PROFILE_SET_USER_TYPE = '@@USER_PROFILE/SET_USER_TYPE';
export const USER_PROFILE_GET = createAsyncType('@@USER_PROFILE/GET');
export const USER_PROFILE_UPDATE = createAsyncType('@@USER_PROFILE/UPDATE');
export const USER_PROFILE_IMAGE_UPLOAD = createAsyncType('@@USER_PROFILE_IMAGE_UPLOAD');
export const USER_TERM_GET = createAsyncType('@@USER_TERM/GET');
export const USER_SETUP_PAYMENT = createAsyncType('@@USER_SETUP_PAYMENT');
export const USER_CONFIRM_CARD = createAsyncType('@@USER_CONFIRM_CARD');
