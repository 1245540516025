// TODO: this is redundant, this function is similar to uplaod photos, refactor
import Camera from '@material-ui/icons/PhotoCamera';
import * as React from 'react';
import { HiddenFileInput, UploadLabel } from 'src/components/Avatar/Avatar.style';
import { FormattedMessage } from 'react-intl';

export interface IUploadReceipts {
  onUpload?: (file: File) => void;
}

const UploadReceipt: React.FC<IUploadReceipts> = ({ onUpload }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDivUpload = () => {
    if (!inputRef?.current?.click) {
      return;
    }

    inputRef.current.click();
  };

  const fileChangeHandler = () => {
    if (!inputRef?.current?.files) {
      return;
    }

    const [file] = inputRef?.current?.files;

    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    if (onUpload) {
      onUpload(file);
    }
  };

  return (
    <div className="location upload" onClick={handleDivUpload}>
      <HiddenFileInput type="file" accept="image/*" ref={inputRef} onChange={fileChangeHandler} />
      <Camera />
      <span className="address"><FormattedMessage id="runner_orderdetails_uploadreceipt" defaultMessage="Upload Photos of receipts" /></span>
      {onUpload && <UploadLabel />}
    </div>
  );
};

export { UploadReceipt };
