import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Firebase, FirebaseProvider } from 'src/components/Firebase';
import store from 'src/redux/configureStore';
import App from './App';
import * as serviceWorker from './serviceWorker';
import translation from './translations';

export const history = createBrowserHistory();
const fireInstance = Firebase();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');
// const [locale] = navigator.language.split(/[-_]/); TODO: enable it when implement locale change
const locale = 'no';

const AppWrapper = () => (
  <Provider store={store}>
    <FirebaseProvider firebaseInstance={fireInstance}>
      <Router history={history}>
        <IntlProvider locale={locale} defaultLocale="no" messages={translation[locale]}>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </IntlProvider>
      </Router>
    </FirebaseProvider>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
