import * as React from 'react';
import { typingDelayInMs } from 'src/constants/General';
import { Bubble, BlueBubble } from '../Bubble';
import { NumberPicker } from '../NumberPicker';

export interface IProps {
  eventHandler: (n: number) => void;
  stores: number[];
  buyerResponse: number;
  typing?: boolean;
}

const QuestionWithRadio: React.FC<IProps> = (props) => {
  const [showButtons, setShowButtons] = React.useState<boolean>(props.typing ? !props.typing : false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowButtons(true), typingDelayInMs);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <Bubble typing={props.typing}>{props.children}</Bubble>
      {showButtons && (
        <React.Fragment>
          <div style={{ display: props.buyerResponse === 0 ? 'flex' : 'none' }}>
            <NumberPicker stores={props.stores} onClick={props.eventHandler} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.buyerResponse !== 0 ? <BlueBubble>{props.buyerResponse}</BlueBubble> : ''}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default QuestionWithRadio;
