import autobind from 'autobind-decorator';
import { call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { userApi } from 'src/api/user';
import {
  USER_CONFIRM_CARD,
  USER_PROFILE_GET,
  USER_PROFILE_IMAGE_UPLOAD,
  USER_PROFILE_UPDATE,
  USER_SETUP_PAYMENT,
  USER_TERM_GET,
} from 'src/redux/user/userTypes';
import { BaseSaga } from './BaseSaga';

export class UserSaga extends BaseSaga {
  @autobind
  public *fetchUserProfile(): IterableIterator<CallEffect | PutEffect<any>> {
    try {
      yield put({ type: USER_PROFILE_GET.PENDING });
      const response = yield call(userApi.fetchUserProfile);
      yield put({ type: USER_PROFILE_GET.SUCCEEDED, payload: response });
    } catch (e) {
      yield put({ type: USER_PROFILE_GET.FAILED, error: e });
    }
  }

  public *fetchLatestTerm(action) {
    try {
      yield put({ type: USER_TERM_GET.PENDING });
      const response = yield call(userApi.fetchTerms, action.payload);
      yield put({ type: USER_TERM_GET.SUCCEEDED, payload: response });
    } catch (e) {
      yield put({ type: USER_TERM_GET.FAILED, error: e });
    }
  }

  public *updateUserProfile(action) {
    try {
      yield put({ type: USER_PROFILE_UPDATE.PENDING });
      const response = yield call(userApi.updateUserProfile, action.payload);
      yield put({ type: USER_PROFILE_UPDATE.SUCCEEDED, payload: response });
      yield put({ type: USER_PROFILE_GET.REQUESTED });
    } catch (e) {
      yield put({ type: USER_PROFILE_UPDATE.FAILED, error: e });
    }
  }

  public *setupPaymentCustomer() {
    try {
      yield put({ type: USER_SETUP_PAYMENT.PENDING });
      const response = yield call(userApi.setupPaymentCustomer);
      yield put({ type: USER_SETUP_PAYMENT.SUCCEEDED, payload: response.clientSecret });
    } catch (e) {
      yield put({ type: USER_SETUP_PAYMENT.FAILED, error: e });
    }
  }

  public *confirmCardRegistration() {
    try {
      yield put({ type: USER_CONFIRM_CARD.PENDING });
      yield call(userApi.confirmCardRegistration);
      yield put({ type: USER_CONFIRM_CARD.SUCCEEDED });
      yield put({ type: USER_PROFILE_GET.REQUESTED });
    } catch (e) {
      yield put({ type: USER_CONFIRM_CARD.FAILED, error: e });
    }
  }

  public *uploadUserProfile(action) {
    try {
      yield put({ type: USER_PROFILE_IMAGE_UPLOAD.PENDING });
      yield call(userApi.uploadUserProfile, action.payload);
      yield put({ type: USER_PROFILE_IMAGE_UPLOAD.SUCCEEDED });
    } catch (e) {
      yield put({ type: USER_PROFILE_IMAGE_UPLOAD.FAILED, error: e });
    }
  }

  protected *registerListeners(): IterableIterator<ForkEffect> {
    yield takeLatest(USER_PROFILE_GET.REQUESTED, this.fetchUserProfile);
    yield takeLatest(USER_TERM_GET.REQUESTED, this.fetchLatestTerm);
    yield takeLatest(USER_PROFILE_UPDATE.REQUESTED, this.updateUserProfile);
    yield takeLatest(USER_SETUP_PAYMENT.REQUESTED, this.setupPaymentCustomer);
    yield takeLatest(USER_CONFIRM_CARD.REQUESTED, this.confirmCardRegistration);
    yield takeLatest(USER_PROFILE_IMAGE_UPLOAD.REQUESTED, this.uploadUserProfile);
  }
}
