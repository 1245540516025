import * as firebase from 'firebase/app';
import React from 'react';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { PUBLIC_ROUTES } from 'src/constants/Routes';
import { Cookies, COOKIE_KEYS } from 'src/utils';

interface IFirebaseProvider {
  firebaseInstance: firebase.app.App;
}

interface IFirebaseContext {
  firebase: typeof firebase;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;
  setRecaptchaVerifier: (verifier: firebase.auth.RecaptchaVerifier) => void;
  signInWithPhoneNumber: (phoneNumber: string | undefined) => Promise<any>;
  confirmOtp: (otp: string | undefined) => Promise<firebase.auth.UserCredential | undefined>;
  phoneNumber: string;
  logout: () => Promise<any>;
}
const voidFunction = () => {
  return;
};

export const FirebaseContext = React.createContext<IFirebaseContext>({
  firebase,
  recaptchaVerifier: undefined,
  setRecaptchaVerifier: voidFunction,
  signInWithPhoneNumber: () => new Promise<void>(voidFunction),
  confirmOtp: () => new Promise<firebase.auth.UserCredential | undefined>(voidFunction),
  phoneNumber: '',
  logout: () => new Promise<void>(voidFunction),
});

export const useFirebase = () => React.useContext(FirebaseContext);

export const FirebaseProvider: React.FC<IFirebaseProvider> = ({ firebaseInstance, children }) => {
  const firebaseAuth = firebase.auth(firebaseInstance);
  const [recaptchaVerifier, setRecaptchaVerifier] = React.useState<firebase.auth.RecaptchaVerifier | undefined>();
  const [confirmationResult, setConfirmationResult] = React.useState<firebase.auth.ConfirmationResult | undefined>();
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');

  const _setRecaptchaVerifier = (verifier: firebase.auth.RecaptchaVerifier) => {
    setRecaptchaVerifier(verifier);
  };

  const signInWithPhoneNumber = async (phoneNumber: string | undefined) => {
    if (!recaptchaVerifier || !phoneNumber) {
      return;
    }

    const result = await firebaseAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
    setConfirmationResult(result);
    setPhoneNumber(phoneNumber);
    return true;
  };

  const confirmOtp = async (otp: string | undefined) => {
    if (!otp || !confirmationResult?.verificationId) {
      return;
    }

    const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, otp);

    return firebaseAuth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => firebaseAuth.signInWithCredential(credential));
  };

  const logout = async () => {
    Cookies.remove(COOKIE_KEYS.TOKEN);
    await firebase.auth().signOut();
  };

  const contextValue = {
    firebase,
    recaptchaVerifier,
    setRecaptchaVerifier: _setRecaptchaVerifier,
    signInWithPhoneNumber,
    confirmOtp,
    phoneNumber,
    logout,
  };

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
};
