import { getUserType } from 'src/helpers/user';
import { IProfile, ITerms, UserType } from 'src/types/user';
import {
  USER_CONFIRM_CARD,
  USER_PROFILE_GET,
  USER_PROFILE_SET_USER_TYPE,
  USER_PROFILE_UPDATE,
  USER_SETUP_PAYMENT,
  USER_TERM_GET,
} from './userTypes';

export interface IUserState {
  userType: UserType | undefined;
  profile: IProfile | undefined;
  latestTerms: ITerms | undefined;
  error: Error | undefined;
  stripeClientSecretKey: string | undefined;
  isLoadingUserProfile: boolean;
  isUpdatingUserProfile: boolean;
  isRequestingStripeClientKey: boolean;
}

const initialState: IUserState = {
  userType: getUserType(),
  profile: undefined,
  latestTerms: undefined,
  error: undefined,
  stripeClientSecretKey: undefined,
  isLoadingUserProfile: false,
  isUpdatingUserProfile: false,
  isRequestingStripeClientKey: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case USER_PROFILE_GET.REQUESTED:
      return {
        ...state,
        isLoadingUserProfile: false,
        error: undefined,
      };
    case USER_PROFILE_GET.PENDING:
      return {
        ...state,
        isLoadingUserProfile: true,
        error: undefined,
      };
    case USER_PROFILE_GET.SUCCEEDED:
      return {
        ...state,
        profile: { id: action.payload.id, ...action.payload.data },
        isLoadingUserProfile: false,
        error: undefined,
      };
    case USER_PROFILE_GET.FAILED:
      return {
        ...state,
        isLoadingUserProfile: false,
        error: action.payload,
      };
    case USER_TERM_GET.SUCCEEDED:
      return {
        ...state,
        latestTerms: { id: action.payload.id, ...action.payload.data },
      };
    case USER_PROFILE_UPDATE.REQUESTED:
    case USER_CONFIRM_CARD.REQUESTED:
      return {
        ...state,
        isUpdatingUserProfile: false,
      };
    case USER_PROFILE_UPDATE.PENDING:
    case USER_CONFIRM_CARD.PENDING:
      return {
        ...state,
        isUpdatingUserProfile: true,
      };
    case USER_PROFILE_UPDATE.SUCCEEDED:
    case USER_CONFIRM_CARD.SUCCEEDED:
      return {
        ...state,
        isUpdatingUserProfile: false,
      };
    case USER_SETUP_PAYMENT.PENDING:
      return {
        ...state,
        isRequestingStripeClientKey: true,
      };
    case USER_SETUP_PAYMENT.SUCCEEDED:
      return {
        ...state,
        stripeClientSecretKey: action.payload,
        isRequestingStripeClientKey: false,
      };

    default:
      return state;
  }
};
