import * as React from 'react';
import * as Scroll from 'react-scroll';
import { typingDelayInMs } from 'src/constants/General';
import { StyledBubble, StyledBlueBubble, StyledBlueBubbleTik, TypingAnimation } from './Bubble.style';
import { ReactComponent as BubbleTick } from './images/bubble-tick.svg';

export interface IProps {
  width?: string;
  display?: string;
  typing?: boolean;
  counter?: number;
  padding?: string;
  margin?: string;
  onClickHandler?: () => void;
}

interface ITypingContainerProps {
  typing?: boolean;
  counter?: number;
}

const TypingContainer: React.FC<ITypingContainerProps> = ({ children, typing, counter }) => {
  const [showAnim, setShowAnimation] = React.useState<boolean>(typing || false);
  const [currentCounter, setcurrentCounter] = React.useState<number>(counter || 0);

  React.useEffect(() => {
    if (counter !== undefined && currentCounter < counter) {
      setShowAnimation(true);
      setcurrentCounter(counter);
    }

    const timer = setTimeout(() => {
      let prevShowAnim = showAnim;

      setShowAnimation(false)

      if(prevShowAnim) {
        Scroll.animateScroll.scrollToBottom({smooth:true});
      }
  }, typingDelayInMs);
    return () => {
      clearTimeout(timer);
    };
  }, [counter, currentCounter]);

  return <React.Fragment>{(showAnim && <TypingAnimation />) || children}</React.Fragment>;
};

const Bubble: React.FC<IProps> = ({ width, typing, counter, display, padding, margin, children }) => (
  <StyledBubble width={width ? width : '100%'} display={display ? 'flex' : 'block'} padding={padding} margin={margin}>
    <TypingContainer typing={typing} counter={counter}>
      {children}
    </TypingContainer>
  </StyledBubble>
);

const BlueBubble: React.FC<IProps> = (props) => (
  <div>
    <StyledBlueBubble onClick={() => {props.onClickHandler && props.onClickHandler();}}>
      {props.children}
    </StyledBlueBubble>
    <StyledBlueBubbleTik>
      <BubbleTick />
    </StyledBlueBubbleTik>
  </div>
);

Bubble.defaultProps = {
  width: '349px',
};

export { Bubble, BlueBubble };
