import { RouteConfig } from 'react-router-config';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'src/constants';
import { MainPage } from 'src/pages/main';
import { OrderPage } from 'src/pages/main/buyer/order';
import {
  LandingPage,
  LoginPage,
  PaymentPage,
  PrivacyPolicyPage,
  ProfilePage,
  VerificationPage,
  WelcomePage,
} from 'src/pages/onboarding';
import { TermsPage } from 'src/pages/term-page';
import { AuthenticateRoute } from 'src/routes/AuthenticatedRoute';

const routes: RouteConfig[] = [
  {
    path: PUBLIC_ROUTES.LANDING,
    exact: true,
    component: LandingPage,
  },
  {
    path: PUBLIC_ROUTES.LOGIN,
    exact: true,
    component: LoginPage,
  },
  {
    path: PUBLIC_ROUTES.VERIFICATION,
    exact: true,
    component: VerificationPage,
  },
  {
    path: PUBLIC_ROUTES.PRIVACY_POLICY,
    exact: true,
    component: PrivacyPolicyPage,
  },
  {
    component: AuthenticateRoute,
    routes: [
      {
        path: PRIVATE_ROUTES.TERMS,
        exact: true,
        component: TermsPage,
      },
      {
        path: PRIVATE_ROUTES.PROFILE,
        exact: true,
        component: ProfilePage,
      },
      {
        path: PRIVATE_ROUTES.PAYMENT,
        exact: true,
        component: PaymentPage,
      },
      {
        path: PRIVATE_ROUTES.WELCOME,
        exact: true,
        component: WelcomePage,
      },
      {
        path: PRIVATE_ROUTES.ORDER,
        exact: true,
        component: OrderPage,
      },
      {
        component: MainPage,
      },
    ],
  },
];

export default routes;
