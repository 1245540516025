import * as React from 'react';

export type IMapStyle = 'streets-v11' | 'light-v10' | 'dark-v10' | 'outdoors-v11' | 'satellite-v9';

const MapStyleHooks = (type: IMapStyle) => {
  const [mapStyle, setMapStyle] = React.useState<IMapStyle>(type);
  return [mapStyle, setMapStyle];
};

export { MapStyleHooks };
