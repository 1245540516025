import * as qs from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { WelcomeRunner } from 'src/components/Dialog';
import { MapContainer } from 'src/containers/Map';
import { PopUpComponent } from 'src/containers/PopUp';

interface IProps {
  activeShopping: boolean;
  hashId: string;
  history: any;
  isMap;
}

const RunnerHome: React.FC<IProps> = ({ hashId, activeShopping, isMap }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [welcomeDismissed, setWelcomeDismissed] = React.useState(false);
  React.useEffect(() => {
    setWelcomeDismissed(!!localStorage.getItem('isMessageViewed'));
    setShowDialog(!welcomeDismissed);
  }, [welcomeDismissed]);

  const handleOnClose = (dismiss: boolean) => {
    if(dismiss) {
      localStorage.setItem('isMessageViewed', 'true');
    }
  }

  return (
    <>
      <MapContainer />
      {showDialog && <WelcomeRunner showDialog={showDialog} onCloseHandler={handleOnClose} />}
      <PopUpComponent hashId={hashId} activeShopping={activeShopping} isMap={isMap} />
    </>
  );
};

const mapStateToProps = (_state, ownProps): IProps => {
  // FIXME: move me to useSelectors
  const queryParams: any = qs.parse(ownProps.location.search, { parseBooleans: true });
  return {
    hashId: queryParams.active_id,
    activeShopping: queryParams.active_shopping,
    history: ownProps.history,
    isMap: queryParams.is_map,
  };
};

const connected = withRouter(connect(mapStateToProps)(RunnerHome));

export { connected as RunnerHome };
