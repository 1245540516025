import * as React from 'react';
import { InputWrapper, InputElement } from '../Input.style';
import { StyledPhoneNumberInput, Separator, PhoneText } from './PhoneNumberInput.style';

export interface IPhoneNumberInput extends React.HTMLProps<HTMLInputElement> {
  countryCode: string;
  phoneNumber: string;
  fullWidth?: boolean;
  onChangeCountryCode: (countryCode) => void;
  onChangePhoneNumber: (countryCode) => void;
}

export const PhoneNumberInput: React.FC<IPhoneNumberInput> = ({
  fullWidth,
  countryCode,
  phoneNumber,
  onChangeCountryCode,
  onChangePhoneNumber,
}) => {
  return (
    <InputWrapper fullWidth={fullWidth}>
      <InputElement>
        <div>
          <StyledPhoneNumberInput width="45px">
            <span>+</span>
            <input maxLength={2} type="numeric" pattern="[0-9]*" value={countryCode} onChange={onChangeCountryCode} />
          </StyledPhoneNumberInput>
          <Separator>
            <span>|</span>
          </Separator>
          <StyledPhoneNumberInput width="70%">
            <PhoneText type="numeric" pattern="[0-9]*" value={phoneNumber} onChange={onChangePhoneNumber} />
          </StyledPhoneNumberInput>
        </div>
      </InputElement>
    </InputWrapper>
  );
};
