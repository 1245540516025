import styled from '@emotion/styled';
import { Font, Color } from 'src/constants';
import { getValuesByState } from 'src/helpers/orderHelpers';
import { IOrderState } from 'src/types';
import { IType } from '../orderDetailsHeaderPopup';

interface IStyleProps {
  type: IType;
  state: IOrderState;
  isDefault?: boolean;
  appliedByMe: boolean;
}

function getBackgroundColor(
  type: IType,
  state: IOrderState,
  appliedByMe: boolean,
): { background: string; color: string } {
  if (type === 'orders')
    return {
      background: Color.ROMANCE,
      color: 'rgb(62, 62, 62)',
    };

  return {
    background: getValuesByState(state, appliedByMe).background,
    color: getValuesByState(state, appliedByMe).color,
  };
}

export const StyledDiv = styled.div<IStyleProps>`
  background: ${({ type, state, appliedByMe }: IStyleProps) => getBackgroundColor(type, state, appliedByMe).background};
  color: ${({ type, state, appliedByMe }: IStyleProps) => getBackgroundColor(type, state, appliedByMe).color};
  font-family: ${Font.HELVETICA};
  border-radius: inherit;
  width: 100%;
  height: 100%;
  padding: 25px 20px;
  font-size: ${(props: IStyleProps) => (props.isDefault ? '25px' : '18px')};
  display: flex;
  align-items: center;
  text-transform: uppercase;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    & > img {
      height: 100%;
    }
  }
`;
