export enum PUBLIC_ROUTES {
  LANDING = '/landing',
  LOGIN = '/login',
  VERIFICATION = '/verification',
  PRIVACY_POLICY = '/privacy-policy',
}

export enum PRIVATE_ROUTES {
  ROOT = '/',
  PAYMENT = '/payment',
  TERMS = '/terms',
  PROFILE = '/profile',
  RUNNER = '/runner',
  ORDER = '/order',
  WELCOME = '/welcome',
}

export const publicPaths: string[] = Object.keys(PUBLIC_ROUTES).map((key) => PUBLIC_ROUTES[key]);
