import styled from '@emotion/styled';
import { Width, Color } from 'src/constants';

interface IStyledProps {
  isOpen: boolean;
  activeShopping: boolean;
}

export const StyledDiv = styled.div<IStyledProps>`
  position: fixed;
  bottom: ${(props: IStyledProps) => (props.activeShopping ? '0px' : '75px')};
  left: 0;
  right: 0;
  z-index: 3;
  background: ${Color.ROMANCE};

  .popup-close {
    border: 1px solid ${Color.MANHATTAN};
  }

  .header {
    background: ${Color.ROMANCE};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    cursor: pointer;
    position: relative;

    .header--state-open {
      position: absolute;
      width: 90%;
      height: 100%;
      top: 0;
    }

    button {
      position: absolute;
      right: 15px;
      background: unset;
      color: ${Color.MANDY};
      border: none;
      float: right;
      padding: 5px 10px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;

      svg {
        height: 25px;
        width: 25px;
        color: white;
      }
    }
  }
  .body-content {
    height: 100%;
    overflow: scroll !important;
    background: ${Color.ROMANCE};
    overflow-x: hidden !important; //FIXME i dont want to be important
    /* overflow-y: hidden !important; */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
    .button-close {
      position: absolute;
      right: 15px;
      top: 22px;
      border: none;
      float: right;
      padding: 5px 10px;
      text-decoration: none;
      background: unset;
      
      color: #131313;
      font-family: Helvetica-Bold;
      font-size: 18px;

      svg {
        height: 25px;
        width: 25px;
        color: #131313;
      }
      z-index: 5;
    }
  }

  @media (min-width: ${Width.TABLET}) {
    bottom: ${(props: IStyledProps) => (props.activeShopping ? '0px' : '100px')};
    left: unset;
    right: 75px;
    width: 440px;
  }
`;
