import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionWithAnswerInput } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';

const Step4 = ({ estOrderAmount, onSendStepHandler, onChangeHandler }) => {
  const { formatMessage } = useIntl();
  return (
    <QuestionWithAnswerInput
      buyerResponse={estOrderAmount}
      onCommitAnswer={onSendStepHandler}
      onChangeHandler={onChangeHandler}
      identifier={'estOrderAmount'}
      type={'number'}
      typing={true}
    >
      <Paragraph>
        <FormattedMessage
          id="buyer_wizard_estimatedcost"
          defaultMessage="Thanks! Approximately how much do you think your total shopping order will cost?"
        />
      </Paragraph>
    </QuestionWithAnswerInput>
  );
};

export default Step4;
