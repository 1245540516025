import * as React from 'react';
import { FormattedMessage, useIntl, IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button } from 'src/components/Button';
import { LabeledInput } from 'src/components/Input';
import { InputForm } from 'src/components/Input/Input.style';
import { Wrapper } from 'src/components/Wrapper';
import { USER_TYPE } from 'src/constants';
import { validateUserProfile, isValidSsn, getUserType } from 'src/helpers/user';
import { useStateSelector } from 'src/redux/reducers';
import { updateUserProfile, uploadUserProfile } from 'src/redux/user/userActionCreator';
import { StyledCentered } from 'src/styles';
import { IProfile } from 'src/types/user';
import { Container, Description } from '../onboarding.styles';
import { InputWrapper, Header, StyledAvatar } from './Profile.style';

const Profile = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isRunner = getUserType() == USER_TYPE.RUNNER;
  const isBuyer = getUserType() == USER_TYPE.BUYER;

  const {
    isLoadingUserProfile,
    isUpdatingUserProfile,
    profile: {
      firstName,
      lastName,
      photo,
      streetAddress,
      city,
      zipCode,
      socialSecurityNumber,
      organizationNumber,
      birthDate,
      isVippsUser,
      email,
    },
  } = useStateSelector(({ user }) => user);

  const [userBirthDate, setUserBirthDate] = React.useState<string>(
    birthDate && new Date(birthDate).getFullYear() > 1 ? new Intl.DateTimeFormat().format(new Date(birthDate)) : '',
  );
  const [initialSsn, setInitialSsn] = React.useState<string>(socialSecurityNumber);

  const [userProfile, setUserProfile] = React.useState<Partial<IProfile>>({
    firstName,
    lastName,
    streetAddress,
    city,
    zipCode,
    socialSecurityNumber,
    organizationNumber,
    birthDate,
    isVippsUser,
    email,
  });

  const [isUserProfileComplete, setIsUserProfileComplete] = React.useState<boolean>(false);
  const [profileErrors, setProfileErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    const result = validateUserProfile(userProfile);
    
    setProfileErrors(result.errorFields);
    setIsUserProfileComplete(result.isValid);
  }, [userProfile, userBirthDate]);

  const onInputChanged = (evt) => {
    if (!evt || !evt?.target) {
      return;
    }

    const fieldName = evt.target.name;
    const fieldValue = evt.target.value;

    setUserProfile((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const onDateInputChanged = (evt) => {
    if (!evt || !evt?.target) {
      return;
    }

    const fieldValue = evt.target.value;

    setUserBirthDate(fieldValue);

    // TODO: Force NO locale registration. Should be changed.
    const fieldName = evt.target.name;
    let [date, month, year] = fieldValue.split('.');

    if (!date || !month || !year) {
      [date, month, year] = fieldValue.split('/');
    }
    if (fieldValue.length == 10 && !!date && !!month && !!year) {
      let dte = new Date(year + '-' + month + '-' + date);
      dte = new Date(dte.toUTCString());

      setUserProfile((prevState) => ({ ...prevState, [fieldName]: dte }));
    } else {
      setUserProfile((prevState) => ({ ...prevState, [fieldName]: null }));
    }
  };

  const formSubmitHandler = React.useCallback(
    (evt) => {
      evt.preventDefault();

      dispatch(updateUserProfile(userProfile));
    },
    [dispatch, userProfile],
  );

  const photoUploadHandler = (file: File) => {
    dispatch(uploadUserProfile(file));
  };

  const isValid = (fieldName: string) => !profileErrors.includes(fieldName);

  return (
    <Wrapper>
      <Container style={{ paddingTop: 40 }}>
        <StyledCentered>
          <InputForm fullWidth onSubmit={formSubmitHandler}>
            <Header>
              <FormattedMessage id="profile_title" defaultMessage="Step 1: Create a profile" />
            </Header>
            <Description>
              <FormattedMessage id="profile_subtitle" defaultMessage=" " />
            </Description>
            <InputWrapper>
              <StyledAvatar src={photo} onUpload={photoUploadHandler} />
              {isRunner && !isValidSsn(initialSsn) && (
                <LabeledInput
                  fullWidth
                  type="numeric"
                  pattern="[0-9]*"
                  name="socialSecurityNumber"
                  label={formatMessage({ id: 'profile_social_security_number', defaultMessage: 'Social security' })}
                  placeholder={formatMessage({ id: 'profile_ssn_placeholder', defaultMessage: 'ddmmyy xxxxx' })}
                  value={userProfile.socialSecurityNumber || ''}
                  onChange={onInputChanged}
                  isValid={isValid('socialSecurityNumber')}
                />
              )}
              {isRunner && (
                <LabeledInput
                  fullWidth
                  name="organizationNumber"
                  type="numeric"
                  pattern="[0-9]*"
                  label={formatMessage({ id: 'profile_organization_number', defaultMessage: 'Organization' })}
                  placeholder={formatMessage({ id: 'profile_orgno_placeholder', defaultMessage: 'xxx xxx xxx' })}
                  value={userProfile.organizationNumber || ''}
                  onChange={onInputChanged}
                  isValid={isValid('organizationNumber')}
                />
              )}
              {isBuyer && (
                <LabeledInput
                  fullWidth
                  name="birthDate"
                  label={formatMessage({ id: 'profile_birthdate_label', defaultMessage: 'Birth date' })}
                  value={userBirthDate}
                  placeholder={formatMessage({ id: 'profile_birthdate_placeholder', defaultMessage: 'dd.mm.yyyy' })}
                  onChange={onDateInputChanged}
                  isValid={isValid('birthDate')}
                />
              )}
              <LabeledInput
                fullWidth
                name="firstName"
                label={formatMessage({ id: 'profile_firstname_label', defaultMessage: 'First' })}
                placeholder={formatMessage({ id: 'profile_name_placeholder', defaultMessage: 'name' })}
                value={userProfile.firstName || ''}
                onChange={onInputChanged}
                isValid={isValid('firstName')}
              />
              <LabeledInput
                fullWidth
                name="lastName"
                label={formatMessage({ id: 'profile_lastname_label', defaultMessage: 'Last' })}
                placeholder={formatMessage({ id: 'profile_name_placeholder', defaultMessage: 'name' })}
                value={userProfile.lastName || ''}
                onChange={onInputChanged}
                isValid={isValid('lastName')}
              />
              <LabeledInput
                fullWidth
                name="streetAddress"
                label={formatMessage({ id: 'profile_street_label', defaultMessage: 'Street' })}
                placeholder={formatMessage({ id: 'profile_address_placeholder', defaultMessage: 'address' })}
                value={userProfile.streetAddress || ''}
                onChange={onInputChanged}
                isValid={isValid('streetAddress')}
              />
              <LabeledInput
                fullWidth
                name="zipCode"
                label={formatMessage({ id: 'profile_zip_label', defaultMessage: 'Zip' })}
                placeholder={formatMessage({ id: 'profile_zipcode_placeholder', defaultMessage: 'code' })}
                value={userProfile.zipCode || ''}
                onChange={onInputChanged}
                isValid={isValid('zipCode')}
              />
              <LabeledInput
                fullWidth
                name="city"
                label={formatMessage({ id: 'profile_city', defaultMessage: 'City' })}
                placeholder={formatMessage({ id: 'profile_address_placeholder', defaultMessage: 'address' })}
                value={userProfile.city || ''}
                onChange={onInputChanged}
                isValid={isValid('city')}
              />
              <LabeledInput
                fullWidth
                name="email"
                label={formatMessage({ id: 'profile_email_label', defaultMessage: 'Email' })}
                placeholder={formatMessage({ id: 'profile_email_placeholder', defaultMessage: 'hei@svop.com' })}
                value={userProfile.email || ''}
                onChange={onInputChanged}
                isValid={isValid('email')}
              />
            </InputWrapper>
            <Button
              text={formatMessage({ id: 'common_next', defaultMessage: 'Next' })}
              variant="primary"
              buttonSize="XS"
              disabled={!isUserProfileComplete || isLoadingUserProfile || isUpdatingUserProfile}
              fullWidth
            />
          </InputForm>
        </StyledCentered>
      </Container>
    </Wrapper>
  );
};

export default Profile;
