import * as React from 'react';
import { Wrapper } from 'src/components/Wrapper';
import { USER_TYPE } from 'src/constants';
import { Color } from 'src/constants/Color';
import { WelcomePage } from 'src/pages/onboarding/welcome';
import { useStateSelector } from 'src/redux/reducers';
import { RunnerHome } from './runner';

const MainPage = () => {
  const { userType } = useStateSelector(({ user }) => user);

  return userType === USER_TYPE.RUNNER ? (
    <Wrapper allowDesktopView wrapperHeight="100vh">
      <RunnerHome />
    </Wrapper>
  ) : (
    <Wrapper allowDesktopView>
      <WelcomePage />
    </Wrapper>
  );
};

export default MainPage;
