// This function is copied from Ananda, find a new solution or keep updating this
export interface IAsyncType {
  REQUESTED: string;
  PENDING: string;
  SUCCEEDED: string;
  FAILED: string;
}

/**
 * createAsyncType - utility for create async type.
 * eg. AUTH_LOGIN = createAsyncType('AUTH/LOGIN');
 * AUTH_LOGIN = {
 *   REQUESTED: AUTH/LOGIN_REQUESTED,
 *   PENDING: AUTH/LOGIN_PENDING,
 *   SUCCEEDED: AUTH/LOGIN_SUCCEEDED,
 *   FAILED: AUTH/LOGIN_FAILED,
 * };
 *
 * @param type - action type
 * @returns async action types
 */
export const createAsyncType = (type: string): IAsyncType => ({
  REQUESTED: `${type}_REQUESTED`,
  PENDING: `${type}_PENDING`,
  SUCCEEDED: `${type}_SUCCEEDED`,
  FAILED: `${type}_FAILED`,
});
