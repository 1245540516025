export enum Font {
  HELVETICA = 'Helvetica',
  HELVETICA_LIGHT = 'Helvetica-Light',
  HELVETICA_BOLD = 'Helvetica-Bold',
  SEGEOPRO = 'SegoePro',
  SEGEOPRO_BOLD = 'SegoePro-Bold',
  SEGEOPRO_SEMIBOLD = 'SegoePro-Semibold',
  SEGEOPRO_LIGHT = 'SegoePro-Light',
  SegoeProLightItalic = 'SegoePro-LightItalic',
  RobotoBold = 'Roboto-Bold',
  RobotoMedium = 'Roboto-Medium',
}
