import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { AvatarContainer, HiddenFileInput, IAvatarContainer, UploadLabel } from './Avatar.style';

export interface IAvatar extends IAvatarContainer {
  className?: string;
  src?: string;
  onUpload?: (file: File) => void;
}

const Avatar: React.FC<IAvatar> = ({ className, src, onUpload, ...rest }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(src);

  const avatarClickHandler = () => {
    if (!inputRef?.current?.click) {
      return;
    }

    inputRef.current.click();
  };

  const fileChangeHandler = () => {
    if (!inputRef?.current?.files) {
      return;
    }

    const [file] = inputRef?.current?.files;

    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = () => {
      setImageSrc(fileReader.result as string);
    };

    if (onUpload) {
      onUpload(file);
    }
  };

  return (
    <AvatarContainer
      className={className}
      onClick={avatarClickHandler}
      style={{ cursor: onUpload ? 'pointer' : 'inherite' }}
      {...rest}
    >
      {imageSrc && <img alt="profile image" src={imageSrc} />}
      <HiddenFileInput type="file" accept="image/*" ref={inputRef} onChange={fileChangeHandler} />
      {onUpload && (
        <UploadLabel>
          <FormattedMessage id="profile_photoupload" defaultMessage="Upload" />
        </UploadLabel>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
