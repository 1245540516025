import * as React from 'react';
import { IReceipts } from 'src/types';
import { StyledDiv } from '../styles/ReceiptItems.style';
import { FormattedMessage } from 'react-intl';

interface IProps {
  receipts: IReceipts[];
}

const ReceiptItems: React.FC<IProps> = ({ receipts }) => (
  <StyledDiv>
    {receipts.map((receipt, index) => (
      <a key={`receipt_item_${index}`} rel="noopener noreferrer" target="_blank" href={receipt.receiptPhoto}>
        <FormattedMessage id="runner_orderdetails_receipt" defaultMessage="Receipt " /> {index + 1}
      </a>
    ))}
  </StyledDiv>
);

export { ReceiptItems };
