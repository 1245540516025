import * as React from 'react';
import { Bubble } from 'src/components/Bubble';
import { Paragraph } from 'src/components/Typography';

const Message: React.FC = ({ children }) => (
  <Bubble typing={true}>
    <Paragraph>{children}</Paragraph>
  </Bubble>
);

export default Message;
