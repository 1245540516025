import * as React from 'react';
import StarRatingComponent from 'react-star-rating-component';

export interface IStarRatingProps {
  rating: number;
  interactive: boolean;
  rateChangeHandler?: (rating: number, name: string) => void;
}

const Rating: React.FC<IStarRatingProps> = ({ rating, interactive, rateChangeHandler }) => (
  <StarRatingComponent
    name="rater"
    editing={interactive}
    starCount={5}
    value={(rateChangeHandler ? 0 : (!rating && !rateChangeHandler ? 5 : rating))}
    emptyStarColor="#CCCCCC"
    onStarClick={rateChangeHandler}
  />
);

export default Rating;
