import styled from '@emotion/styled';
import { Font, Color } from 'src/constants';

export interface IStyledInputProps {
  fullWidth?: boolean;
  isValid?: boolean;
}

export const InputForm = styled.form`
  width: ${(props: IStyledInputProps) => (props.fullWidth ? 'calc( 100% - 10px)' : 'auto')};
`;

export const CheckBox = styled.input`
  width: 19px;
  height: 19px;
`;

export const Input = styled.input`
  height: 60px;
  background: ${Color.WHITE};
  
  font-size: 20px;
  font-family: ${Font.HELVETICA};
  text-align: center;
  margin: 5px;
  width: ${(props: IStyledInputProps) => (props.fullWidth ? 'calc( 100% - 10px)' : 'auto')};
`;

export const InputWrapper = styled.div`
  height: 60px;
  background: ${Color.WHITE};
  border: ${(props: IStyledInputProps) => (props.isValid === undefined || props.isValid ? `1px solid ${Color.MANHATTAN}` : `1px solid ${Color.RED}`)};
  
  font-size: 20px;
  font-family: ${Font.HELVETICA};
  text-align: center;
  margin: 10px 0px;
  width: ${(props: IStyledInputProps) => (props.fullWidth ? 'calc( 100% - 10px)' : 'auto')};
  display: flex;
  padding: 0;
  align-items: center;

  & > label {
    padding: 0 10px 0 20px;
    flex-shrink: 0;
    font-weight: bold;
  }
`;

export const InputElement = styled.div`
  flex: 1;

  & > input {
    background: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-family: ${Font.HELVETICA};
  }
`;

export const MobStyledText = styled.textarea`
  width: 100%;
  height: 104px;
  color: rgb(62, 62, 62);
  font-size: 20px;
  padding: 20px 77px 29px 20px;
  letter-spacing: 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(243, 243, 243);
  font-family: ${Font.HELVETICA};
  margin: 0px;
`;

export const MobStyledInput = styled.input`
  width: 100%;
  height: 50px;
  color: rgb(62, 62, 62);
  font-size: 20px;
  padding: 30px 77px 29px 32px;
  letter-spacing: 0px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(243, 243, 243);
  font-family: ${Font.HELVETICA};
  margin: 0px;
`;
