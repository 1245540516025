import * as React from 'react';
import { StyledButton, StyledMobButton, IStyledComponentProps } from './Button.style';

export interface IButtonProps extends IStyledComponentProps, React.HTMLProps<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
}

const Button: React.FC<IButtonProps> = ({ text, variant, buttonSize = 'S', loading = false, ...rest }) => (
  <StyledButton variant={variant} buttonSize={buttonSize} disabled={loading} {...rest}>
    {text}
  </StyledButton>
);

const MobButton: React.FC<IButtonProps> = ({ text, variant, buttonSize = 'S', ...rest }) => (
  <StyledMobButton variant={variant} buttonSize={buttonSize} {...rest}>
    {text}
  </StyledMobButton>
);

Button.defaultProps = {
  variant: 'primary',
  buttonSize: 'S',
};

MobButton.defaultProps = {
  variant: 'mbutton',
  buttonSize: 'BXS',
};

export { Button, MobButton };
