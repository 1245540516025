import * as Handlebars from 'handlebars';
import { IntlShape } from 'react-intl';
import { IOrderState } from 'src/types';
import { Color } from 'src/constants';
import { throwInvalidAsyncActionArgument } from 'typesafe-actions/dist/create-async-action';

interface IReturnValueByState {
  text: string;
  headerText: string;
  button: string;
  buttonBackground: string;
  background: string;
  color: string;
  orderDetailsButton: string;
  orderDetailsButtonColor: string;
}

export const getTemplate = (text, context) => {
  // FIXME: find appropriate name for the function
  if (context) context.publishedTime = getFormattedDate(context.timeRequested);
  const template = Handlebars.compile(text);
  return template(context);
};

function localize(id: string, defaultMessage: string, intl?: IntlShape, params?: any) {
  if (!intl) {
    return defaultMessage;
  }

  return intl.formatMessage({ id, defaultMessage }, params);
}

export function getValuesByState(state: IOrderState, appliedByMe?: boolean, intl?: IntlShape): IReturnValueByState {
  if (state === 'Pending' && appliedByMe) {
    // behave as ássigned
    return {
      text: localize('state_order_waitingforaccept', 'Cancel', intl),
      headerText: localize('runner_applied_title', 'Waiting for a response from the buyer', intl),
      button: localize('common_cancel', 'Cancel', intl),
      buttonBackground: Color.MANDY,
      background: Color.PORSCHE,
      color: 'white',
      orderDetailsButton: localize('runner_applied_swipetocancel', 'Cancel Order Request', intl),
      orderDetailsButtonColor: Color.ROOF_TERRACOTTA,
    };
  }
  switch (state) {
    case 'Pending':
      return {
        text: localize('state_order_waitingforaccept', 'Waiting for delivery', intl),
        headerText: localize('runner_apply_title', 'Order Published', intl, {
          [0]: '{{publishedTime}}',
        }), //TODO: Fix message to accept param
        button: localize('common_cancel', 'Cancel', intl),
        buttonBackground: Color.MANDY,
        background: Color.SCORPION,
        color: 'white',
        orderDetailsButton: localize('runner_apply_swipetoapply', 'Apply for this Order', intl),
        orderDetailsButtonColor: Color.MANDY,
      };
    case 'Assigned':
      return {
        text: localize('state_order_waitingfordelivery', 'Assigned', intl),
        headerText: localize('runner_accepted_buyerhasaccepted', 'somebody has accepted!', intl, {
          [0]: '{{buyerFirstName}}',
        }),
        button: localize('common_start', 'Start', intl),
        buttonBackground: Color.MANDY,
        background: Color.PORSCHE,
        color: 'white',
        orderDetailsButton: localize('runner_accepted_startshopping', 'Start shopping', intl),
        orderDetailsButtonColor: Color.MANDY,
      };
    case 'InProgress':
      return {
        text: localize('state_order_waitingfordelivery', 'InProgress', intl),
        headerText: localize('runner_accepted_buyerhasaccepted', 'somebody has accepted!', intl, {
          [0]: '{{buyerFirstName}}',
        }),
        button: localize('common_continue', 'Continue', intl),
        buttonBackground: Color.MANDY,
        background: Color.PORSCHE,
        color: 'white',
        orderDetailsButton: localize('runner_accepted_startshopping', 'Start Shopping', intl),
        orderDetailsButtonColor: Color.MANDY,
      };
    case 'Completed':
      return {
        text: localize('state_order_waitingforpayment', 'Completed', intl),
        headerText: localize('runner_accepted_buyerhasaccepted', 'somebody has accepted!', intl, {
          [0]: '{{buyerFirstName}}',
        }),
        button: localize('common_continue', 'Continue', intl),
        buttonBackground: Color.MANDY,
        background: Color.PORSCHE,
        color: 'white',
        orderDetailsButton: 'Continue Shopping',
        orderDetailsButtonColor: Color.MANDY,
      };
    default:
      return {
        text: 'Please wait',
        headerText: 'Please refresh',
        button: 'Continue',
        buttonBackground: '#5080c9',
        background: 'white',
        color: 'black',
        orderDetailsButton: 'N/A',
        orderDetailsButtonColor: 'N/A',
      };
  }
}

export const getFormattedDate = (timeAssigned: string): string => {
  const date = new Date(timeAssigned);

  return (date.getDate() < 10 ? "0" : "") + date.getDate() +
          "." + ((date.getMonth()+1) < 10 ? "0" : "") + (date.getMonth()+1) +
          " " + (date.getHours() < 10 ? "0" : "") + date.getHours() +
          ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
};

export const getMinuteDifference = (timeAssigned: string): number => {
  const diffMinute = (dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;

    return Math.abs(Math.round(diff));
  };

  const date = new Date(timeAssigned);

  return diffMinute(new Date(), date);
};
