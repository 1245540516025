import { Slide, Button, Dialog, IconButton } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'src/components/Firebase/FirebaseContext';
import { Wrapper } from 'src/components/Wrapper';
import { USER_TYPE } from 'src/constants';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'src/constants/Routes';
import { IUserState } from 'src/redux/user/userReducer';
import { Container, Header, Section, Close, ExternalAnchor, ExternalLaunchIcon } from './MenuButtonStyle';

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="right" ref={ref} {...props} />
  ),
);

const MenuButton: React.FC<any> = () => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const { userType } = useSelector<any, IUserState>((state) => state.user);
  const { logout } = useFirebase();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    history.push(PUBLIC_ROUTES.LANDING);
  };

  const browseWelcomePage = () => history.push(PRIVATE_ROUTES.WELCOME);

  const browseMyProfilePage = () => history.push(PRIVATE_ROUTES.PROFILE);

  const button = () => (
    <IconButton
      onClick={handleClickOpen}
      style={{
        border: 'solid 1px',
        borderColor: 'white',
        color: 'black',
        backgroundColor: 'white',
        boxShadow: '0 7px 14px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
      }}
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <div>
      {button()}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Wrapper>
          <Close>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Close>
          <Container>
            <Header><img src="images/svop_logo.png" /></Header>
            <Section>
            {userType === USER_TYPE.BUYER && (
              <p>
                <a onClick={browseWelcomePage}>
                  <FormattedMessage id="menu_how_to_order" defaultMessage="How to order" />
                </a>
              </p>
            )}
              <p>
                <a onClick={browseMyProfilePage}>
                  <FormattedMessage id="menu_myprofile" defaultMessage="My Profile" />
                </a>
              </p>
              <p>
                <ExternalAnchor href="http://svop.com/" target="_blank" rel="noopener noreferrer">
                  Svop.com
                </ExternalAnchor>
                <ExternalLaunchIcon>
                  <LaunchIcon fontSize="inherit" viewBox="-5 -5 26 26" />
                </ExternalLaunchIcon>
              </p>
              <p>
                <ExternalAnchor href="http://svop.com/privacy-no" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common_privacy" defaultMessage="Privacy" />
                </ExternalAnchor>
                <ExternalLaunchIcon>
                  <LaunchIcon fontSize="inherit" viewBox="-5 -5 26 26" />
                </ExternalLaunchIcon>
              </p>
            </Section>
            <Section>
              <p>
                <FormattedMessage id="menu_need_help" defaultMessage="Need help?" />
              </p>
              <br />
              {userType === USER_TYPE.RUNNER && (
                <p>
                  <FormattedMessage
                    id="menu_call_us"
                    values={{ phoneNumber: <a href="tel:+4741380009">+47 413 80 009</a> }}
                    defaultMessage="Call us on +47 413 80 009"
                  />
                </p>
              )}
              <p>
                <FormattedMessage id="menu_write_support" defaultMessage="Write us on support@svop.com" />
              </p>
            </Section>
            <Section>
              <p>
                <a onClick={handleLogout}>
                  <FormattedMessage id="common_logout" defaultMessage="Log out" />
                </a>
              </p>
            </Section>
          </Container>
        </Wrapper>
      </Dialog>
    </div>
  );
};

export { MenuButton };
