import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { geoPropTypes } from 'react-geolocated';
import { Color, Font } from 'src/constants/';

type StyledBubbleComponentProps = {
  width: string;
  display: string;
  padding?: string;
  margin?: string;
};

type DotDelayProps = {
  delay: string;
};

const bounce = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  50% {
    transform: translate3d(0, -10px, 0);
  }
`;

const Dot = styled.span<DotDelayProps>((props) => ({
  height: '10px',
  width: '10px',
  marginRight: '5px',
  backgroundColor: '#bbb',
  borderRadius: '50%',
  display: 'inline-block',
  animation: bounce + ' .9s linear 3',
  animationDelay: props.delay,
}));

const TypingAnimation = () => {
  return (
    <div>
      <Dot delay=".2s" />
      <Dot delay=".4s" />
      <Dot delay=".6s" />
    </div>
  );
};

const StyledBubble = styled.div<StyledBubbleComponentProps>((props) => ({
  background: Color.WHITE,
  borderRadius: '8px',
  width: props.width,
  display: props.display,
  color: Color.DARK_GREY,
  fontFamily: Font.HELVETICA,
  textAlign: 'left',
  fontSize: '20px',
  letterSpacing: 0,
  padding: !!props.padding ? props.padding : '16px 23px 16px 20px',
  margin: !!props.margin ? props.margin : '15px',
}));

const StyledBlueBubble = styled.div(() => ({
  background: Color.MINE_SHAFT,
  borderRadius: '23px',
  boxShadow: '0px 0px 58px -16px rgba(52, 126, 240, 0.18)',
  color: Color.WHITE,
  fontFamily: Font.HELVETICA,
  fontSize: '20px',
  letterSpacing: 0,
  textAlign: 'center',
  margin: '15px',
  padding: '10px 23px 10px 20px',
}));

const StyledBlueBubbleTik = styled.div`
  margin-top: -33px;
  margin-left: 77px;
  float: right;
`;

export { StyledBubble, StyledBlueBubble, StyledBlueBubbleTik, TypingAnimation };
