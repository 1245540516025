import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Terms } from 'src/components/Terms';
import { Wrapper } from 'src/components/Wrapper';
import { useStateSelector } from 'src/redux/reducers';
import { updateUserProfile } from 'src/redux/user/userActionCreator';

export const TermsPage = () => {
  const dispatch = useDispatch();
  const { latestTerms, userType } = useStateSelector(({ user }) => user);
  const { formatMessage } = useIntl();

  const onAcceptButtonClick = React.useCallback(() => {
    dispatch(
      updateUserProfile({
        terms: [
          {
            userType,
            termsId: latestTerms.id,
            timeAccepted: new Date().toISOString(),
          },
        ],
      }),
    );
  }, [dispatch, latestTerms, userType]);

  const terms = latestTerms
    ? latestTerms.text
    : formatMessage({ id: 'terms_loading', defaultMessage: 'Loading terms' });

  return (
    <Wrapper>
      <Terms
        terms={terms}
        buttonLabel={formatMessage({ id: 'common_accept', defaultMessage: 'Accept' })}
        onButtonClick={onAcceptButtonClick}
      />
    </Wrapper>
  );
};
