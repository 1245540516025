import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/api/axiosInstance';
import { IShoppingDetail, IUpdateCartAmount, IUpdateCartStatus } from 'src/types';

const fetchOrderListMap = (latitude: number, longitude: number, radius: number): Promise<IShoppingDetail[]> =>
  axiosInstance.get(`/order/shopper/${latitude}/${longitude}/${radius}`).then(({ data }: AxiosResponse) => data || []);

const fetchOrderList = (): Promise<IShoppingDetail[]> =>
  axiosInstance.get('/order/shopper/me').then(({ data }: AxiosResponse) => data || []);

const applyShoppingOrder = (orderId: string): Promise<IShoppingDetail[]> =>
  axiosInstance.post(`/order/apply/${orderId}`);

const cancelShoppingOrder = (orderId: string): Promise<IShoppingDetail[]> =>
  axiosInstance.delete(`/order/application/${orderId}`);

const completeShoppingOrder = (orderId: string): Promise<IShoppingDetail[]> =>
  axiosInstance.post(`/order/${orderId}/complete`);

const startShoppingOrder = (orderId: string): Promise<IShoppingDetail[]> => axiosInstance.patch(`/order/${orderId}`);

const updateAmount = (orderId: string, storeNumber: number, paidAmount: number) =>
  axiosInstance.put(`/order/${orderId}/update_amount/${storeNumber}`, { paidAmount });

const updateShoppingOrder = (
  orderId: string,
  payload: IUpdateCartAmount | IUpdateCartStatus | any, // FIXME: types
): Promise<IShoppingDetail> => axiosInstance.patch(`/order/${orderId}`, payload);

const uploadReceipt = (file: File, orderId: string) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosInstance({
    method: 'PUT',
    url: `/order/${orderId}/receipt`,
    headers: {
      'Content-Disposition': `form-data; name="file"; filename=${file.name}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const shoppingAPI = {
  updateAmount,
  uploadReceipt,
  fetchOrderList,
  fetchOrderListMap,
  applyShoppingOrder,
  startShoppingOrder,
  updateShoppingOrder,
  cancelShoppingOrder,
  completeShoppingOrder,
};
