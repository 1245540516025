import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { shoppingAPI } from 'src/api/shopping';
import { Paragraph } from 'src/components/Typography';
import { ErrorMessage } from 'src/pages/onboarding/onboarding.styles';
import { getShoppingList } from 'src/redux/shoppingList/shoppingListActionCreator';
import { IShoppingList } from 'src/types';
import { FormattedMessage } from 'react-intl';

const ColorCircularProgress = withStyles({
  root: {
    color: '#00695c',
  },
})(CircularProgress);

interface IShoppingItems {
  hashId: string;
  item: IShoppingList;
  allowEdit: boolean;
}

export const ShoppingItems: React.FC<IShoppingItems> = ({ hashId, item, allowEdit }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = React.useState<number>(0);
  const [error, setError] = React.useState('');
  const [isUpdating, setUpdateState] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (e) => {
    try {
      if (amount === item.paidAmount) throw new Error('Amount same as before');
      setLoading(true);
      e.preventDefault();

      setUpdateState(true);
      await shoppingAPI.updateAmount(hashId, item.storeNumber, amount).then(() => {
        dispatch({ type: getShoppingList.REQUESTED });
      });
      setUpdateState(false);
    } catch (error) {
      setUpdateState(false);
      setError(error?.message);
    }
  };

  React.useEffect(() => {
    if (item.paidAmount === amount) setLoading(false);
  }, [amount, item.paidAmount]);

  const onBlurHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAmount(Number(event.target.value));
  };

  return (
    <li key={item.storeNumber}>
      <div className="shop-details">
        <div className="shopping-cart-details__head">
          <Paragraph fontSize={22} isBold={true}>
            {item.store}
          </Paragraph>
          <span className="shopping-cart-details__head-item_price">
            {loading ? <ColorCircularProgress size={25} thickness={5} /> : `${item.paidAmount} kr`}
          </span>
        </div>
        <div className="content" dangerouslySetInnerHTML={{ __html: item.items.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></div>
        {allowEdit && (
          <form onSubmit={handleSubmit}>
            <input placeholder="Update actual cost" type="numeric" pattern="[0-9]*" onChange={onBlurHandler} />
            <button type="button" onClick={handleSubmit} disabled={isUpdating}>
              <FormattedMessage id="common_update" defaultMessage="Update" />
            </button>
          </form>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </li>
  );
};
