import styled from '@emotion/styled';
import { Font } from 'src/constants';

export interface IProps {
  isMobile?: boolean
}

export const Header = styled.div`
  padding-top: 100px;
  padding-bottom: 20px;
  text-align: center;
`;

export const UserTypeWrapper = styled.div`
  position: ${(props: IProps) => (props.isMobile ? 'absolute' : '')}; ;
  bottom: ${(props: IProps) => (props.isMobile ? '125px' : '')}; 
  padding: ${(props: IProps) => (props.isMobile ? '0 19px' : '100px 19px')}; ;
  text-align: center;

  & > Button {
    margin: 6px 0;
  }
`;

export const PrivacyPolicyButton = styled.a`
  background: none;
  color: rgb(62, 62, 62);
  font-size: 16px;
  text-decoration: none;
  margin-top: 15px;
  display: block;
  :hover {
    text-decoration: underline;
  }
`;
