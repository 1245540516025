import * as React from 'react';
import ReactLoading from 'react-loading';
import { Color } from 'src/constants';
import { LoadingScreenWrapper } from './LoadingScreen.style';

export const LoadingScreen = () => (
  <LoadingScreenWrapper>
    <ReactLoading type="spin" color={Color.BLUE} height={64} width={64} />
  </LoadingScreenWrapper>
);
