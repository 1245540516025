import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { Color } from 'src/constants/Color';

const SlideUp = keyframes`
  from {
    transform: translateY(100%);
  }
`;

export const InputFreeTextContainer = styled.div`
  background: ${Color.WHITE};
  width: 100%;
  height: 136px;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 0px;
  padding: 16px 5px 16px 5px;
  border-top: 1px solid ${Color.MANHATTAN};
  animation: ${SlideUp} 0.2s ease-in-out;
`;

export const InputContainer = styled.div`
  background: ${Color.WHITE};
  width: 100%;
  height: 90px;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 0px;
  padding: 16px 5px 16px 5px;
  border-top: 1px solid ${Color.MANHATTAN};
  animation: ${SlideUp} 0.2s ease-in-out;
`;

export const SendBtnFreeTextContainer = styled.span`
  position: fixed;
  right: 25px;
  bottom: 45px;
  cursor: pointer;
`;

export const SendBtnTextContainer = styled.span`
  position: fixed;
  right: 25px;
  bottom: 29px;
  cursor: pointer;
`;

export const StyledAnswerDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
