import styled from '@emotion/styled';
import { Avatar } from 'src/components/Avatar';
import { SubHeader } from '../onboarding.styles';

export const Header = styled(SubHeader)`
  margin-bottom: 10px;
`;

export const StyledAvatar = styled(Avatar)`
  & {
    margin: 0 auto 18px;
  }
`;

export const InputWrapper = styled.div`
  margin: 31px 0;
  width: 100%;
`;
