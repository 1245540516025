import { createAsyncType } from 'src/utils';

export const getShoppingList = createAsyncType('@@SHOPPING_LIST/GET');

export const applyShoppingOrder = createAsyncType('@@SHOPPING_LIST/APPLY');

export const startShoppingOrder = createAsyncType('@@SHOPPING_LIST/START');

export const getOrdersMap = createAsyncType('@@SHOPPING_LIST/FETCH_ORDERS_MAP');

export const UPDATE_RECEIPT = createAsyncType('@@SHOPPING_LIST/UPDATE_RECEIPT');

interface IUploadReceipt {
  file: File;
  hashId: string;
}

export const uploadReceipt = (payload: IUploadReceipt) => ({
  type: UPDATE_RECEIPT.REQUESTED,
  payload,
});
