import styled from '@emotion/styled';
import * as React from 'react';
import { Font } from 'src/constants';

interface IProps {
  fontSize?: number;
  isBold?: boolean;
  textAlign?: string;
}

export const StyledParagraph = styled.span<IProps>`
  color: rgb(62, 62, 62);
  font-size: ${(props: IProps) => (props.fontSize ? `${props.fontSize} px` : '19px')};
  font-family: ${(props: IProps) => (props.isBold ? Font.HELVETICA_BOLD : Font.HELVETICA)};
  text-align: ${(props: IProps) => (props.textAlign || 'center' )};
  letter-spacing: 0px;
  line-height: 27px;
`;

const Paragraph: React.FC<IProps> = ({ children, isBold, textAlign }) => (
  <StyledParagraph isBold={isBold} textAlign={textAlign}>{children}</StyledParagraph>
);

Paragraph.defaultProps = {
  isBold: false,
};

export default Paragraph;
