import styled from '@emotion/styled';
import { Color } from 'src/constants';

interface IAppWrapperProps {
  isMobile?: boolean;
  allowDesktopView?: boolean;
  backgroundColor?: string;
  wrapperHeight?: string;
}

export const AppWrapper = styled.div`
  height: ${(props: IAppWrapperProps) => (!!props.wrapperHeight ? props.wrapperHeight : '')};
  min-height: ${(props: IAppWrapperProps) => (!!props.wrapperHeight ? '' : '100vh')};
  width: 100%;
  max-width: ${(props: IAppWrapperProps) => (props.allowDesktopView ? '100%' : '500px')};
  margin: auto;
  padding-bottom: 40px;
  position: relative;
  background: ${(props: IAppWrapperProps) => (props.backgroundColor ? '' + props.backgroundColor + '' : '')};
`;
