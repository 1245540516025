import styled from '@emotion/styled';

export const StyledDiv = styled.div`
  margin: 25px 0;
  display: grid;
  grid-row-gap: 25px;
  a {
    color: rgb(80, 128, 201);
    font-size: 20px;
    font-family: Helvetica-Bold;
    font-weight: bold;
    text-decoration: underline;
  }
`;
