import * as React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Terms } from 'src/components/Terms';
import { Wrapper } from 'src/components/Wrapper';
import { PUBLIC_ROUTES } from 'src/constants/Routes';

export const PrivacyPolicyPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const terms = formatMessage({ id: 'privacy_policy_terms' });
  const onBackButtonClick = () => {
    if (history && history.length > 0) {
      history.goBack();
    } else {
      history.replace(PUBLIC_ROUTES.LANDING);
    }
  };

  return (
    <Wrapper>
      <Terms terms={terms} buttonLabel="Go Back" onButtonClick={onBackButtonClick} />
    </Wrapper>
  );
};
