import styled from '@emotion/styled';

interface IStyleDivProps {
  color?: string;
}

export const StyledDiv = styled.div<IStyleDivProps>`
  width: 100%;
  height: 100%;

  .map {
    width: 100%;
    height: 100%;
  }

  .custom-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props: IStyleDivProps) => (props.color ? props.color : 'white')};
    border-radius: 50%;
    font-family: Helvetica-Bold;
    font-size: 14px;
    font-weight: bold;
    height: 52px;
    width: 52px;
    z-index: 1;
    cursor: pointer;
  }
  .notch {
    background: ${(props: IStyleDivProps) => (props.color ? props.color : '#5080c9')};
    height: 20px;
    position: absolute;
    right: 25px;
    bottom: -19px;
    width: 5px;
    z-index: 0;
  }
`;
