import { setUserType as localUserType } from 'src/helpers/user';
import {
  USER_PROFILE_GET,
  USER_PROFILE_SET_USER_TYPE,
  USER_PROFILE_UPDATE,
  USER_TERM_GET,
  USER_SETUP_PAYMENT,
  USER_CONFIRM_CARD,
  USER_PROFILE_IMAGE_UPLOAD,
} from 'src/redux/user/userTypes';
import { IProfile, UserType } from 'src/types/user';

export const setUserType = (userType: UserType) => {
  localUserType(userType);

  return {
    type: USER_PROFILE_SET_USER_TYPE,
    payload: userType,
  };
};

export const getUserProfile = () => ({ type: USER_PROFILE_GET.REQUESTED });

export const updateUserProfile = (payload: Partial<IProfile>) => ({
  type: USER_PROFILE_UPDATE.REQUESTED,
  payload,
});

export const uploadUserProfile = (payload: File) => ({
  type: USER_PROFILE_IMAGE_UPLOAD.REQUESTED,
  payload,
});

export const fetchLatestTerms = (userType: UserType) => ({
  type: USER_TERM_GET.REQUESTED,
  payload: userType,
});

export const setupPaymentCustomer = () => ({
  type: USER_SETUP_PAYMENT.REQUESTED,
});

export const confirmCardRegistration = () => ({
  type: USER_CONFIRM_CARD.REQUESTED,
});
