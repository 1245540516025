import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, MenuButton } from 'src/components/Button';
import { Wrapper } from 'src/components/Wrapper';
import { PRIVATE_ROUTES } from 'src/constants/Routes';
import { getMyOrders } from 'src/redux/order/actions/orderActionCreator';
import { IOrderState } from 'src/types';
import { Container, Logo } from '../onboarding.styles';
import { Greeting, Header, Description, Circle, BulletItem } from './welcome-page.style';
import { USER_TYPE } from 'src/constants';
import { IUserState } from 'src/redux/user/userReducer';
import { validateUserProfile, validatePaymentProfile } from 'src/helpers/user';

export const WelcomePage: React.FC = () => {
  const history = useHistory();
  const { profile }: IUserState = useSelector<any, IUserState>((state) => state.user);
  const { formatMessage } = useIntl();
  const [hasActiveOrder, setHasActiveOrder] = useState(false);

  React.useEffect(() => {
    if(!validateUserProfile(profile).isValid) {
      history.push("/profile");
    } else if (!validatePaymentProfile(USER_TYPE.BUYER, profile).isValid) {
      history.push("/payment");
    }

    const loadData = async () => {
      const result = await getMyOrders(); // TODO: Call it via dispatch if need to persist in redux store.
      setHasActiveOrder(result?.length > 0);
    };

    loadData();
  }, [profile]);

  const handleOnClick = () => {
    history.push(PRIVATE_ROUTES.ORDER);
  };

  return (
    <Wrapper>
      <Container style={{ paddingTop: 40 }}>
        <Header>
          <div>
            <MenuButton />
          </div>
          <div>
            <Header>Svop</Header>
          </div>
        </Header>
        <Greeting>
          <FormattedMessage id="buyer_welcome_title" defaultMessage="Hello!" />
        </Greeting>
        <Description>
          <FormattedMessage
            id="welcome_description"
            defaultMessage="Quickly and easily hire shoppers to pick up items from around the city"
          />
        </Description>
        <BulletItem>
          <div>
            <Circle>1</Circle>
          </div>
          <div className="message">
            <FormattedMessage id="welcome_bullet_1" defaultMessage="Tell us what you need" />
          </div>
        </BulletItem>
        <BulletItem>
          <div>
            <Circle>2</Circle>
          </div>
          <div className="message">
            <FormattedMessage id="welcome_bullet_2" defaultMessage="We'll find your shoppper" />
          </div>
        </BulletItem>
        <BulletItem>
          <div>
            <Circle>3</Circle>
          </div>
          <div className="message">
            <FormattedMessage id="welcome_bullet_3" defaultMessage="Pay on delivery" />
          </div>
        </BulletItem>
        <div>&nbsp;</div>
        {hasActiveOrder && (
          <>
            <Description>
              <FormattedMessage
                id="welcome_description_continue"
                defaultMessage="Click continue to open your shopping list"
              />
            </Description>
            <div>&nbsp;</div>
          </>
        )}
        <Button
          variant="primary"
          text={
            hasActiveOrder
              ? formatMessage({ id: 'welcome_continue_shopping', defaultMessage: 'Continue shopping' })
              : formatMessage({ id: 'buyer_welcome_buton_findshopper', defaultMessage: 'Find a shopper now' })
          }
          buttonSize="XS"
          fullWidth
          onClick={handleOnClick}
        />
      </Container>
    </Wrapper>
  );
};
