import * as React from 'react';
import { Bubble } from 'src/components/Bubble';
import StyledNumbers from './NumberPicker.style';
export interface IProps {
  onClick: (i: number) => void;
  stores: number[];
}
const NumberPicker: React.FC<IProps> = (props) => (
  <Bubble display={'flex'} width={'auto'} padding={'15px 0px 15px 16px'} margin={'0px 15px'}>
    {props.stores.map((item) => (
      <StyledNumbers key={new Date().getTime() + '' + Math.random()} onClick={() => props.onClick(item)}>
        {item}
      </StyledNumbers>
    ))}
  </Bubble>
);

export default NumberPicker;
