import { default as useIsMounted } from 'ismounted';
import * as React from 'react';
import { useSwipeButtonHooks } from './hooks/SwipeButton.hooks';
import { StyledSwipButtonDiv } from './SwipeButton.style';

const isTouchDevice = 'ontouchstart' in document.documentElement; // hack to know if is a mobile device

export type ISwipeDirection = 'normal' | 'reverse';

interface IProps {
  swipeDirection: ISwipeDirection;
  defaultText?: string;
  color: string;
  onSuccess: () => void;
  textUnlocked: string;
}

const getClassName = (type: ISwipeDirection) => {
  switch (type) {
    case 'normal':
      return {
        main: 'rsbcSlider',
        arrow: 'rsbcSliderArrow',
        sliderCircle: 'rsbcSliderCircle',
      };
    case 'reverse':
      return {
        main: 'rsbcSliderRight',
        arrow: 'rsbcSliderArrowRight',
        sliderCircle: 'rsbcSliderCircleRight',
      };
  }
};

const SwipeButton: React.FC<IProps> = ({ color, swipeDirection, onSuccess, defaultText, textUnlocked }) => {
  const [unlockedText, setUnlockedText] = React.useState<null | string>(null);
  const isMounted = useIsMounted();
  const slider: any = React.useRef(null);
  const container: any = React.useRef(null);

  const [setDragging, setStartX, unlocked, setDefault] = useSwipeButtonHooks({
    slider,
    container,
    isTouchDevice,
    isReverse: swipeDirection === 'reverse',
  });

  const startDrag = (e) => {
    // Find types
    if (isMounted) {
      setDragging(true);
      if (isTouchDevice) {
        setStartX(e.touches[0].clientX);
      } else {
        setStartX(e.clientX);
      }
    }
  };

  React.useEffect(() => {
    if (unlocked) {
      onSuccess();
      setDefault();
      setUnlockedText(textUnlocked);
    }
  }, [onSuccess, setDefault, textUnlocked, unlocked]);

  const classNames = getClassName(swipeDirection);

  const getText = () => unlockedText || defaultText;

  return (
    <StyledSwipButtonDiv>
      <div className={'rsbContainer'} ref={(el: any): any => (container.current = el)}>
        <div
          className={classNames.main}
          ref={(el: any): any => (slider.current = el)}
          onMouseDown={startDrag}
          style={{ background: color }}
          onTouchStart={startDrag}
        >
          <span className={'rsbcSliderText'}>{getText()}</span>
          <span className={classNames.arrow}></span>
          <span className={classNames.sliderCircle} style={{ background: color }}></span>
        </div>
        <div className="rsbcText">{defaultText}</div>
      </div>
    </StyledSwipButtonDiv>
  );
};

SwipeButton.defaultProps = {
  swipeDirection: 'normal',
  defaultText: 'Slide',
};

export { SwipeButton };
