import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/api/axiosInstance';
import { IProfile, ITerms, UserType } from 'src/types/user';

// TODO: move this interface up
export interface IResponse<T> {
  id: string;
  data: Omit<T, 'id'>;
}

const fetchUserProfile = (): Promise<IResponse<IProfile>> =>
  axiosInstance.get('/user/me').then(({ data }: AxiosResponse) => data);

const uploadUserProfile = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance({
    method: 'PUT',
    url: '/user/me/photo',
    headers: {
      'Content-Disposition': `form-data; name="file"; filename=${file.name}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

axiosInstance.get('/user/me').then(({ data }: AxiosResponse) => data);

const updateUserProfile = (payload: Partial<IProfile>) => axiosInstance.patch('/user/me', payload);

const fetchTerms = (userType: UserType): Promise<IResponse<ITerms>> =>
  axiosInstance.get(`/terms/${userType.toLowerCase()}`).then(({ data }: AxiosResponse) => data);

const setupPaymentCustomer = () =>
  axiosInstance.post('/user/setup_payment_customer').then(({ data }: AxiosResponse) => data);

const confirmCardRegistration = () => axiosInstance.post('/user/registercard_succeeded');

export const userApi = {
  fetchUserProfile,
  fetchTerms,
  updateUserProfile,
  setupPaymentCustomer,
  confirmCardRegistration,
  uploadUserProfile,
};
