import styled from '@emotion/styled';
import { Button } from 'src/components/Button';
import { Font } from 'src/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 15px;
`;

export const HeaderContainer = styled.div`
  flex-shrink: 0;
`;

export const Header = styled.h1`
  margin: 34px 0;
  color: rgb(62, 62, 62);
  font-size: 36px;
  font-family: ${Font.HELVETICA};
  font-weight: bold;
`;

export const SubHeader = styled.h2`
  margin-bottom: 22px;
  color: rgb(62, 62, 62);
  font-size: 27px;
  font-family: ${Font.HELVETICA};
  font-weight: bold;
`;

export const Term = styled.div`
  flex-grow: 1;
  overflow: auto;
  font-family: Helvetica;
  font-size: 18px;
  line-height: 27px;

  h1 {
    font-size: 22px;
    font-weight: bold;
    margin: 20px 0;
  }

  p {
    margin: 15px 0;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
  margin: 22px auto;
`;
