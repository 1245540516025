import * as React from 'react';
import { AppWrapper } from './Wrapper.style';

interface IProps {
  allowDesktopView?: boolean;
  backgroundColor?: string;
  wrapperHeight?: string;
}

export const Wrapper: React.FC<IProps> = ({ allowDesktopView, backgroundColor, wrapperHeight, children }) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const updateViewportSize = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
      return;
    }

    setIsMobile(true);
  };

  React.useEffect(() => {
    updateViewportSize();

    window.addEventListener('resize', updateViewportSize);

    return () => {
      window.removeEventListener('resize', updateViewportSize);
    };
  }, []);
  return (
    <AppWrapper
      isMobile={isMobile}
      allowDesktopView={allowDesktopView}
      backgroundColor={backgroundColor}
      wrapperHeight={wrapperHeight}
    >
      {children}
    </AppWrapper>
  );
};
