import styled from '@emotion/styled';
import { Button } from 'src/components/Button';
import { Font } from 'src/constants';
import { SubHeader } from '../onboarding.styles';

export const Header = styled(SubHeader)`
  margin-bottom: 10px;
`;

export const Section = styled.section`
  width: 100%;
  margin-top: 40px;
`;

export const SectionHeader = styled.h3`
  font-size: 22px;
  color: rgb(62, 62, 62);
  font-family: ${Font.HELVETICA};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const SectionDescription = styled.p`
  font-family: ${Font.HELVETICA};
  font-size: 16px;
  line-height: 27px;
`;

export const StyledButton = styled(Button)`
  margin-top: 40px;
`;
