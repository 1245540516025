import styled from '@emotion/styled';
import { Font } from 'src/constants';

export const CardDetailsWrapper = styled.div`
  width: 70%;
`;

export const Error = styled.p`
  margin-top: 10;
  font-family: ${Font.HELVETICA};
  font-size: 16px;
  color: red;
`;
