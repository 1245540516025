import * as React from 'react';
import { OrderLine } from 'src/components/Orders/';
import { IShoppingDetail } from 'src/types';
import { StyledOrdrderLine } from './styles/openOrder.style';

interface IProps {
  data: IShoppingDetail[];
}

const openOrders: React.FC<IProps> = ({ data }) => {
  return (
    <StyledOrdrderLine>
      {data.map(({ id, data: { buyerFirstName, state, appliedByMe, buyerPhoto } }) => (
        <OrderLine
          key={id}
          id={id}
          name={buyerFirstName}
          status={state}
          appliedByMe={appliedByMe}
          buyerPhoto={buyerPhoto}
        />
      ))}
    </StyledOrdrderLine>
  );
};

export default openOrders;
