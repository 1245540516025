import styled from '@emotion/styled';
import { Width } from '../constants';

export const StyledCentered = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .inner_container {
    display: flex;

    .profile-image-container {
      background: #f5f5f5;
      border-radius: 50%;
      font-family: Font.SEGEOPRO_BOLD;
      height: 210px;
      width: 210px;
    }

    .stacked {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: ${Width.SMALLMOBILE}) {
      flex-direction: column;
    }
  }
`;
