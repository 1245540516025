import { Global } from '@emotion/core';
import * as React from 'react';
import './App.css';
import { renderRoutes } from 'react-router-config';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirebase } from 'src/components/Firebase/FirebaseContext';
import { LoadingScreen } from 'src/components/LoadingScreen';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, publicPaths } from 'src/constants';
import { clearToken, setToken } from 'src/helpers';
import { useStateSelector } from 'src/redux/reducers';
import routes from './routes/routes';
import { global } from './styles';

const App = () => {
  const { firebase } = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false);

  const { isLoadingUserProfile, isUpdatingUserProfile, isRequestingStripeClientKey } = useStateSelector(
    ({ user }) => user,
  );

  const isLoading = isAuthenticating || isLoadingUserProfile || isUpdatingUserProfile || isRequestingStripeClientKey;

  const setUserToken = async (user: firebase.User) => {
    const userToken = await user.getIdToken();

    setToken(userToken);
  };

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticating(true);
        setUserToken(user).then(() => {
          setIsAuthenticating(false);
          history.push(PRIVATE_ROUTES.ROOT);
        });
      } else {
        clearToken();

        if (!publicPaths.includes(location.pathname)) {
          history.push(PUBLIC_ROUTES.LANDING);
        }

        setIsAuthenticating(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase]);

  return (
    <div className="App">
      <Global styles={global} />
      {isLoading && <LoadingScreen />}
      {renderRoutes(routes)}
    </div>
  );
};

export default App;
