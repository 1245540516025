import styled from '@emotion/styled';

export interface IAvatarContainer {
  size?: number;
}

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  background-color: rgb(223, 233, 247);
  width: ${({ size = 128 }: IAvatarContainer) => `${size}px`};
  height: ${({ size = 128 }: IAvatarContainer) => `${size}px`};
  border-radius: 50%;
  overflow: hidden;

  & > img {
    height: 100%;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const UploadLabel = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10%;
  color: white;
  text-align: center;
`;
