import * as React from 'react';
import { Bubble, BlueBubble } from 'src/components/Bubble';
import { MobButton } from 'src/components/Button';
import { typingDelayInMs } from 'src/constants/General';

export interface IProps {
  userClickHandler: (bol: boolean, questions: any) => void;
  eventVal1: boolean;
  eventVal2: boolean;
  caption1: string;
  caption2: string;
  buyerResponse: string;
  typing?: boolean;
}

//TODO: FIXME
const QuestionWithButton: React.FC<IProps> = (props) => {
  const [showButtons, setShowButtons] = React.useState<boolean>(props.typing ? !props.typing : false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowButtons(true), typingDelayInMs);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const userClickHandler = (bol, obj) => {
    props.userClickHandler(bol, String(obj.props.children));
  };
  console.warn('the buyer response for radio is ', props.buyerResponse);
  return (
    <div>
      <Bubble typing={props.typing}>{props.children}</Bubble>
      {showButtons && (
        <React.Fragment>
          <div
            style={{
              display: props.buyerResponse === '' ? '' : 'none',
              width: '349px',
              textAlign: 'right',
              margin: '15px',
            }}
          >
            <MobButton
              variant="mbutton"
              onClick={() => userClickHandler(props.eventVal2, props.children)}
              text={props.caption2}
              impression="negative"
            />
            <MobButton
              variant="mbutton"
              onClick={() => userClickHandler(props.eventVal1, props.children)}
              text={props.caption1}
              impression="positive"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.buyerResponse !== '' ? <BlueBubble>{props.buyerResponse}</BlueBubble> : ''}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default QuestionWithButton;
