import * as React from 'react';
import { InputWrapper, InputElement, CheckBox } from './Input.style';

export interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  fullWidth?: boolean;
  inputElement?: React.ReactNode;
  isValid?: boolean;
}

export const LabeledInput: React.FC<IInputProps> = ({ fullWidth, label, inputElement, name, isValid, ...props }) => {
  return (
    <InputWrapper fullWidth={fullWidth} isValid={isValid}>
      {label && <label data-for={name || ''}>{label}</label>}
      <InputElement>{inputElement ? inputElement : <input name={name} {...props} />}</InputElement>
    </InputWrapper>
  );
};

export const CheckInput: React.FC<IInputProps> = ({ fullWidth, label, inputElement, name, isValid, ...props }) => {
  const checkbox = <CheckBox name={name} type="checkbox" {...props}></CheckBox>;

  return (
    <InputWrapper fullWidth={fullWidth} isValid={isValid}>
      {(label && (
        <label data-for={name}>
          {label}&nbsp;&nbsp;&nbsp;&nbsp;
          {checkbox}
        </label>
      )) || { checkbox }}
    </InputWrapper>
  );
};
