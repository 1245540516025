import { IShoppingDetail } from 'src/types';
import { getShoppingList, UPDATE_RECEIPT as uploadReceiptActionCreator } from './shoppingListActionCreator';

export interface IShoppingListReducer {
  data: IShoppingDetail[];
  isLoading: boolean;
  isUploadingReceipts: boolean;
  loaded: boolean;
  error: any;
}

const initialState: IShoppingListReducer = {
  data: [],
  isLoading: false,
  isUploadingReceipts: false,
  loaded: false,
  error: null,
};

export const shoppingListReducer = (state = initialState, action): IShoppingListReducer => {
  switch (action.type) {
    case getShoppingList.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case getShoppingList.SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        loaded: true,
      };
    case getShoppingList.FAILED:
    case uploadReceiptActionCreator.FAILED:
      return {
        ...state,
        isLoading: false,
        loaded: false,
        error: action.payload,
        isUploadingReceipts: false,
      };
    case uploadReceiptActionCreator.PENDING:
      return {
        ...state,
        isUploadingReceipts: true,
      };
    case uploadReceiptActionCreator.SUCCEEDED:
      return {
        ...state,
        isUploadingReceipts: false,
      };
    case 'UPDATE_AMOUNT':
      return {
        ...state,
      };
    default:
      return state;
  }
};
