import styled from '@emotion/styled';

export const Stub = styled.div`
  height: 150px;
`;

interface IWizardStepProps {
  isMobile?: boolean;
}

export const WizardStep = styled.div`
  width: 100%;
  max-width: ${(props: IWizardStepProps) => (props.isMobile ? '411px' : '800px')};
  margin-left: auto;
  margin-right: auto;
`;
