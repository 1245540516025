import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { getValuesByState, getTemplate } from 'src/helpers/orderHelpers';
import { getShoppingDetailSelector } from 'src/selectors';
import { IShoppingDetail, IOrderState } from 'src/types';
import { StyledDiv } from './styles/orderDetailsHeaderPopup.style';

export type IType = 'details' | 'orders';

interface IProps {
  text?: string;
  type: IType;
  hashId?: string;
  isMap: boolean;
  isDefault?: boolean;
}

const OrderDetailsHeaderPopup: React.FC<IProps> = ({ text, type, hashId, isMap, isDefault }) => {
  const shoppingDetail: IShoppingDetail = useSelector(
    (state) => getShoppingDetailSelector(state, hashId, isMap),
    shallowEqual,
  );
  const formatMessage = useIntl();
  const state = shoppingDetail?.data?.state ?? 'Open Orders';

  if (type === 'details') {
    text = getValuesByState(state, shoppingDetail?.data.appliedByMe, formatMessage).headerText;
  }
  text = getTemplate(text, shoppingDetail?.data);

  const stateShowImage: Partial<IOrderState[]> = ['Assigned', 'InProgress', 'Completed'];
  const showImage = stateShowImage.includes(state);

  return (
    <StyledDiv type={type} state={state} isDefault={isDefault} appliedByMe={shoppingDetail?.data?.appliedByMe}>
      {showImage && <img src={shoppingDetail?.data.buyerPhoto} />} {text}
    </StyledDiv>
  );
};

export { OrderDetailsHeaderPopup };
