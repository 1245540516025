import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Wrapper } from 'src/components/Wrapper';
import { USER_TYPE } from 'src/constants';
import { PUBLIC_ROUTES } from 'src/constants/Routes';
import { setUserType } from 'src/redux/user/userActionCreator';
import { StyledCentered } from 'src/styles';
import { Container, Logo, Description } from '../onboarding.styles';
import { Header, UserTypeWrapper, PrivacyPolicyButton } from './landing-page.style';

const LandingPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const updateViewportSize = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
      return;
    }

    setIsMobile(true);
  };

  //TODO: move me to a custom hook
  React.useEffect(() => {
    updateViewportSize();

    window.addEventListener('resize', updateViewportSize);

    return () => {
      window.removeEventListener('resize', updateViewportSize);
    };
  }, []);

  const navigateToLoginPage = React.useCallback(() => {
    history.push(PUBLIC_ROUTES.LOGIN);
  }, [history]);

  const shopperButtonClickHandler = () => {
    dispatch(setUserType(USER_TYPE.BUYER));
    navigateToLoginPage();
  };

  const runnerButtonClickHandler = () => {
    dispatch(setUserType(USER_TYPE.RUNNER));
    navigateToLoginPage();
  };

  return (
    <Wrapper wrapperHeight="100vh">
      <Container>
        <Logo>
          <img src="images/svop_logo.png" />
        </Logo>
        <Description padding={isMobile ? "50px 0" : "100px 0" }>
          <FormattedMessage id="home_text_slogan" values={{"\n": <br />}} defaultMessage="Fast and easy delivery when you need it most" />
        </Description>
        <UserTypeWrapper isMobile={isMobile}>
          <Button
            variant="primary"
            text={formatMessage({ id: 'home_login_buyer', defaultMessage: 'I need help shopping' }).toUpperCase()}
            buttonSize="XS"
            fullWidth
            onClick={shopperButtonClickHandler}
          />
          <Button
            variant="primary"
            text={formatMessage({ id: 'home_login_shopper', defaultMessage: 'I will help as a shopper' }).toUpperCase()}
            buttonSize="XS"
            fullWidth
            onClick={runnerButtonClickHandler}
          />
          <PrivacyPolicyButton href="https://svop.com/privacy-no" target="_blank">
            <FormattedMessage id="home_link_privacy_policy" defaultMessage="Privacy policy" />
          </PrivacyPolicyButton>
        </UserTypeWrapper>
      </Container>
    </Wrapper>
  );
};

export default LandingPage;
