import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Bubble, BlueBubble } from 'src/components/Bubble';
import { Message } from 'src/components/Message';
import { Paragraph } from 'src/components/Typography';
import { Color } from 'src/constants/Color';
import './css/styles.css';
import { Fee, feeCalculator, obligedMessage, getMessage1, getMessage2 } from 'src/constants/General';
import { IShoppingList, IRunnerApplication } from 'src/types';
import { Rating } from '../Rating';

export interface IProps {
  shoppingList: IShoppingList[];
  runnerApplications: IRunnerApplication[];
  estOrderAmount: number;
  state: string;
  runnerId: string;
  buyerName?: string;
  formatter: any;
  runnerName?: string;
  alternateDeliveryAddress: boolean;
  acceptItemReplacements: boolean;
  buyerAddress: string;
  hasSpecialDeliveryConditions: boolean;
  specialDeliveryConditions: string;
  orderNumber: string;
  isSpecialOrder: boolean;
  isComplimentary: boolean;
}

const OrderHistory: React.FC<IProps> = ({
  shoppingList,
  estOrderAmount,
  buyerName,
  runnerId,
  formatter,
  runnerApplications,
  alternateDeliveryAddress,
  acceptItemReplacements,
  buyerAddress,
  hasSpecialDeliveryConditions,
  specialDeliveryConditions,
  orderNumber,
  isSpecialOrder,
  isComplimentary
}) => {
  const { formatMessage } = useIntl();
  const questions: string[] = [
    formatMessage({ id: 'buyer_wizard_store_items', defaultMessage: 'What do you need us to buy from the ' }),
    formatMessage({ id: 'buyer_wizard_store_location', defaultMessage: ' What’s the name or type of the ' }),
  ];
  const storeLabels = [
    formatMessage({ id: 'store_label_1', defaultMessage: 'first store' }),
    formatMessage({ id: 'store_label_2', defaultMessage: 'second store' }),
    formatMessage({ id: 'store_label_3', defaultMessage: 'third store' }),
  ];
  const runner = runnerApplications?.find((runnerObj) => runnerObj.runnerId === runnerId);

  const commonYes = formatMessage({ id: 'common_yes', defaultMessage: 'Yes' });
  const commonNo = formatMessage({ id: 'common_no', defaultMessage: 'No' });
  const intlShape = useIntl();

  return (
    <div>
      <Bubble>
        <Paragraph>
          <FormattedMessage
            id="buyer_wizard_specialorder"
            defaultMessage="Can we buy the goods you need nearby you and carry them easily?"
          />
        </Paragraph>
      </Bubble>
      <div className="answer">
        <BlueBubble>{commonYes}</BlueBubble>
      </div>
      <Bubble>
        <Paragraph>
          <FormattedMessage
            id="buyer_wizard_numberofstores"
            defaultMessage="Superb. How many stores do \nyou need us to visit?"
          />
        </Paragraph>
      </Bubble>
      <div className="answer">
        <BlueBubble>{shoppingList?.length}</BlueBubble>
      </div>
      {shoppingList?.map((store, index) => (
        <div key={store.storeNumber}>
          <Bubble>
            <Paragraph>{`${questions[0]} ${storeLabels[index]}?`}</Paragraph>
          </Bubble>
          <div className="answer">
            <BlueBubble><span dangerouslySetInnerHTML={{ __html: store.items.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></span></BlueBubble>
          </div>
          <Bubble>
            <Paragraph>{`${questions[1]} ${storeLabels[index]}?`}</Paragraph>
          </Bubble>
          <div className="answer">
            <BlueBubble>{store.store}</BlueBubble>
          </div>
        </div>
      ))}
      <Bubble>
        <Paragraph>
          <FormattedMessage
            id="buyer_wizard_estimatedcost"
            defaultMessage="Thanks! Approximately how much do you think your total shopping order will cost?"
          />
        </Paragraph>
      </Bubble>
      <div className="answer">
        <BlueBubble>{estOrderAmount}</BlueBubble>
      </div>

      <>
        <Bubble>
          <Paragraph>
            <FormattedMessage id="buyer_wizard_msg_askreplacementitemn" />
          </Paragraph>
        </Bubble>
        <div className="answer">
          <BlueBubble>{acceptItemReplacements ? commonYes : commonNo}</BlueBubble>
        </div>

        <Bubble>
          <Paragraph>
            <FormattedMessage id="buyer_wizard_msg_delivertohomeaddress" />
          </Paragraph>
        </Bubble>
        <div className="answer">
          <BlueBubble> {!alternateDeliveryAddress ? commonYes : commonNo}</BlueBubble>
        </div>
      </>

      {alternateDeliveryAddress && (
        <>
          <Bubble>
            <Paragraph>
              <FormattedMessage id="buyer_wizard_msg_enterhomeaddress" />
            </Paragraph>
          </Bubble>
          <div className="answer">
            <BlueBubble> {buyerAddress}</BlueBubble>
          </div>
        </>
      )}

      <>
        <Bubble>
          <Paragraph>
            <FormattedMessage id="buyer_has_special_delivery_conditions" />
          </Paragraph>
        </Bubble>
        <div className="answer">
          <BlueBubble>{hasSpecialDeliveryConditions ? commonYes : commonNo}</BlueBubble>
        </div>
      </>

      {hasSpecialDeliveryConditions && 
      <>
        <Bubble>
          <Paragraph>
            <FormattedMessage id="buyer_special_delivery_conditions" />
          </Paragraph>
        </Bubble>
        <div className="answer">
          <BlueBubble> {specialDeliveryConditions}</BlueBubble>
        </div>
      </>}

      {estOrderAmount && (
        <div>
          <Message>
            <FormattedMessage
              id={isSpecialOrder ? "buyer_wizard_confirmorder_specialOrder" : (isComplimentary? "buyer_wizard_confirmorder_complimentary" : "buyer_wizard_confirmorder")}
              values={{
                name: buyerName,
                initialFee: Fee.BASIC,
                serviceFeeOtherStore: Fee.OTHERS,
                totalFee: <b style={{ color: Color.GREEN }}>{feeCalculator(shoppingList?.length)}</b>,
              }}
            />
          </Message>
          <div className="answer">
            <BlueBubble>{formatMessage({ id: 'common_goahead', defaultMessage: 'Go ahead' })}</BlueBubble>
          </div>
        </div>
      )}

      <Message>{obligedMessage(intlShape, orderNumber, isSpecialOrder)}</Message>
      {runnerId && (
        <div>
          <Message>{runnerApplications.length == 1 ? getMessage1(buyerName, runnerApplications, formatter) : getMessage2(buyerName, formatter)}</Message>
          <Message>
            <div className="runner-name">{runner?.runnerFirstName}</div>
            <div className="runner-rating">
              <Rating rating={runner?.runnerRating} interactive={false} />
            </div>
          </Message>
          <div className="answer">
            <BlueBubble>{formatMessage({ id: 'common_accept', defaultMessage: 'Accept' })}</BlueBubble>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
