import * as React from 'react';
import { MobStyledText, MobStyledInput } from './Input.style';

interface IMobProps {
  placeholder?: string;
  onChangeHandler: (evt, identifier) => void;
  identifier: string;
  value?: string;
}

interface IMobInputProps {
  placeholder?: string;
  onChangeHandler: (evt, identifier) => void;
  identifier: string;
  value?: string;
  type: string;
  onChange?: (value) => void;
}

const MobInputText: React.FC<IMobProps> = ({ placeholder, value, onChangeHandler, identifier }) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (!inputRef?.current?.focus) {
      return;
    }

    inputRef.current.focus();
  });

  return (
    <MobStyledText
      ref={inputRef}
      placeholder={placeholder}
      value={value}
      onChange={(evt) => {
        onChangeHandler(evt, identifier);
      }}
    />
  );
};

const MobInput: React.FC<IMobInputProps> = ({ placeholder, onChangeHandler, identifier, type }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!inputRef?.current?.focus) {
      return;
    }

    inputRef.current.focus();
  });

  return (
    <MobStyledInput
      ref={inputRef}
      placeholder={placeholder}
      type={type === 'number' || type === 'numeric' ? 'numeric' : 'text'}
      pattern={type === 'number' || type === 'numeric' ? '[0-9]*' : ''}
      onChange={(evt) => {
        onChangeHandler(evt, identifier);
      }}
    />
  );
};

export { MobInputText, MobInput };
