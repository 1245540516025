import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Bubble } from 'src/components/Bubble';
import { Paragraph } from 'src/components/Typography';
import { ReactComponent as Avatar } from './images/Kimmi.svg';
import { StyleBuyerWelcomDiv } from './Message.style';

export interface IProps {
  imageSrc: string | undefined;
}

const WelcomeMessage: React.FC<IProps> = ({ imageSrc }) => (
  <Bubble display="flex" typing={false}>
    <Avatar />
    <StyleBuyerWelcomDiv>
      <Paragraph>
        <FormattedMessage id="buyer_wizard_msg_start" defaultMessage=" " />
      </Paragraph>
    </StyleBuyerWelcomDiv>
  </Bubble>
);

export default WelcomeMessage;
