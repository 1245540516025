/* eslint-disable @typescript-eslint/camelcase */
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import * as React from 'react';
import { LabeledInput } from 'src/components/Input';
import { ICardInformationErrors } from 'src/pages/onboarding/payment-page/payment-page';
import { CardDetailsWrapper, Error } from './StripeCreditCard.style';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '20px',
      fontFamily: 'Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};

interface IStripeCreditCard {
  onChange?: (evt: StripeElementChangeEvent) => any;
  error?: string;
  card_label?: string;
  expiration_label?: string;
  security_label?: string;
  userRequiresCard: boolean;
  cardInformationErrors: ICardInformationErrors;
}

export const StripeCreditCard: React.FC<IStripeCreditCard> = ({
  onChange,
  error,
  card_label,
  expiration_label,
  security_label,
  cardInformationErrors,
  userRequiresCard,
}) => (
  <div>
    <LabeledInput
      type="numeric"
      pattern="[0-9]*"
      label={card_label}
      name="card"
      isValid={!userRequiresCard || cardInformationErrors.cardNumber}
      inputElement={<CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />}
    />
    <LabeledInput
      type="numeric"
      pattern="[0-9]*"
      label={expiration_label}
      name="expiration"
      isValid={!userRequiresCard || cardInformationErrors.cardExpiry}
      inputElement={<CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />}
    />
    <LabeledInput
      type="numeric"
      pattern="[0-9]*"
      label={security_label}
      name="security"
      isValid={!userRequiresCard || cardInformationErrors.cardCvc}
      inputElement={<CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />}
    />
    {error && <Error>{error}</Error>}
  </div>
);
