import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuButton } from 'src/components/Button';
import { Paragraph } from 'src/components/Typography';
import { MobHeader } from 'src/components/Typography/Header';
import { getDate } from 'src/constants/General';

interface IProps {
  cancelOrder: () => void;
  canCancelOrder: boolean;
}

const Header: React.FC<IProps> = ({ cancelOrder, canCancelOrder }) => {
  const intlShape = useIntl();

  return (
    <MobHeader>
      <div>
        <div className="inline left">
          <MenuButton />
        </div>
        <div className="inline center">
          <Paragraph>
            <p>
              <FormattedMessage id="buyer_screentitle" defaultMessage="Shopping request" />
            </p>
            <div style={{ fontWeight: 'normal' }}>{getDate(intlShape)}</div>
          </Paragraph>
        </div>
        <div className="inline right" style={{ visibility: canCancelOrder ? 'visible' : 'hidden' }}>
          <div className="cancel-button">
            <a href="#" onClick={cancelOrder} className="close" />
          </div>
        </div>
      </div>
    </MobHeader>
  );
};

export default Header;
