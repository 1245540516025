import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/api/axiosInstance';
import { IShoppingDetail } from 'src/types';

const makeSpecialOrder = (isSpecialOrder: boolean): Promise<IShoppingDetail> =>
  axiosInstance.post('/order', { isSpecialOrder: isSpecialOrder }).then(({ data }: AxiosResponse) => data);

const createOrder = (values): Promise<IShoppingDetail> =>
  axiosInstance
    .post('/order', {
      totalAmount: 0,
      paymentReference: '',
      rating: 0,
      ...values,
    })
    .then(({ data }: AxiosResponse) => data);

const patchOrderForShoppers = ({ orderId, orderState }): Promise<IShoppingDetail> =>
  axiosInstance.patch(`/order/${orderId}`, { state: orderState });

const assignAShopper = ({ orderId, runnerId, orderState }): Promise<IShoppingDetail> =>
  axiosInstance.patch(`/order/application/${orderId}`, { runnerId: runnerId, state: orderState });

const declineAShopper = ({ orderId, runnerId, decline }): Promise<IShoppingDetail> =>
  axiosInstance.patch(`/order/application/${orderId}`, {
    runnerApplications: [{ runnerId: runnerId, declined: decline }],
  });

const poolForRunners = ({ orderId }): Promise<IShoppingDetail> =>
  axiosInstance.get(`/order/buyer/${orderId}`).then(({ data }: AxiosResponse) => data);

const getActiveOrder = (): Promise<IShoppingDetail> =>
  axiosInstance.get('/order/buyer/me').then(({ data }: AxiosResponse) => data);

const rateARunner = ({ orderId, rating }): Promise<IShoppingDetail> =>
  axiosInstance.post(`/order/${orderId}/rating/${rating}`);

const getMyOrders = (): Promise<IShoppingDetail[]> =>
  axiosInstance.get('/order/buyer/me').then(({ data }: AxiosResponse) => data);

const getIsComplimentary = (): Promise<boolean> =>
  axiosInstance.get('/order/complimentary').then(({ data }: AxiosResponse) => data);

export const orderAPI = {
  makeSpecialOrder,
  createOrder,
  patchOrderForShoppers,
  poolForRunners,
  assignAShopper,
  declineAShopper,
  rateARunner,
  getActiveOrder,
  getMyOrders,
  getIsComplimentary
};
