import { USER_TYPE } from 'src/constants';
import { IProfile, UserType } from 'src/types/user';

export const getUserType = () => (localStorage.getItem('userType') as UserType) || undefined;

export const setUserType = (userType: UserType) => localStorage.setItem('userType', userType);

export const isValidSsn = (socialSecurityNumber) => {
  return !!socialSecurityNumber && socialSecurityNumber.length == 11;
};

export const userRequiresCard = (userType: USER_TYPE) => userType === USER_TYPE.BUYER;

export class ValidationResult {
  isValid: boolean;
  errorFields: string[];

  constructor(hasUserProfileCompleted, errorFields) {
    this.isValid = hasUserProfileCompleted;
    this.errorFields = errorFields;
  }
}

export const validateUserProfile = (profile: Partial<IProfile> | undefined): ValidationResult => {
  if (!profile) {
    return new ValidationResult(false, []);
  }

  const userType = getUserType();
  let incompletedField: string[] = [''];

  if (userType === USER_TYPE.BUYER) {
    incompletedField = validateBuyer(profile);
  } else if (userType == USER_TYPE.RUNNER) {
    incompletedField = validateRunner(profile);
  }

  return new ValidationResult(incompletedField.length === 0, incompletedField);
};

export const validatePaymentProfile = (userType: UserType, profile: Partial<IProfile> | undefined): ValidationResult => {
  const errors: string[] = [];

  if (userType === USER_TYPE.RUNNER) {
    if (!profile?.isVippsUser) {
      errors.push('isVippsUser');
    }

    if (
      !profile?.bankAccountNumber ||
      !/(NO|no)?[0-9][0-9]{3}(\.| )?[0-9]{2}(\.| )?[0-9]{5}/.test(profile.bankAccountNumber)
    ) {
      errors.push('bankAccountNumber');
    }

    return new ValidationResult(errors.length == 0, errors);
  } else if (userType === USER_TYPE.BUYER) {
    if (!profile?.isVippsUser) {
      errors.push('isVippsUser');
    }
    if (!profile?.isCardRegistered) {
      errors.push('isCardRegistered');
    }

    return new ValidationResult(errors.length == 0, errors);
  }

  return new ValidationResult(false, []);
};

const validateBuyer = (buyerProfile: Partial<IProfile>): string[] => {
  const buyerRequiredFields = ['birthDate', 'firstName', 'lastName', 'streetAddress', 'city', 'zipCode', 'email'];
  const fieldErrors: string[] = [];

  for (const field of buyerRequiredFields) {
    if (!buyerProfile[field]) {
      fieldErrors.push(field);
    } else if (field === 'email' && !isValidEmail(buyerProfile.email)) {
      fieldErrors.push(field);
    } else if (field === 'birthDate' && !isValidBirthDate(buyerProfile.birthDate)) {
      fieldErrors.push(field);
    }
  }

  return fieldErrors;
};

const validateRunner = (runnerProfile: Partial<IProfile>): string[] => {
  const runnerRequiredFields = ['firstName', 'lastName', 'streetAddress', 'city', 'zipCode', 'email'];
  const fieldErrors: string[] = [];

  for (const field of runnerRequiredFields) {
    if (!runnerProfile[field]) {
      fieldErrors.push(field);
    } else if (field === 'email' && !isValidEmail(runnerProfile.email)) {
      fieldErrors.push(field);
    }
  }

  if (runnerProfile.organizationNumber && !isValidOrgNumber(runnerProfile.organizationNumber)) {
    fieldErrors.push('organizationNumber');
  }

  if (runnerProfile.socialSecurityNumber && !isValidSsn(runnerProfile.socialSecurityNumber)) {
    fieldErrors.push('socialSecurityNumber');
  }

  if (!runnerProfile.organizationNumber && !runnerProfile.socialSecurityNumber) {
    fieldErrors.push('organizationNumber');
    fieldErrors.push('socialSecurityNumber');
  }

  return fieldErrors;
};

const isValidEmail = (email?: string): boolean =>
  !!email && /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

const isValidBirthDate = (birthDate: any) => {
  const input = new Date(birthDate);
  const result = !!birthDate && input.getFullYear() > 1 && input < new Date();
  return result;
};

const isValidOrgNumber = (orgNumber: string) => /[1-9][0-9]{2}(( )?[0-9]{3}){2}(MVA)?/.test(orgNumber);
