import * as React from 'react';
import { useIntl } from 'react-intl';
import { QuestionWithFreeText } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';

const Step3 = ({
  id,
  freeTextAnswer,
  onSendStepHandler,
  onChangeHandler,
  questions,
  storeLabels,
  storeQCounter,
  storeCounter,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <QuestionWithFreeText
        buyerResponse={freeTextAnswer}
        onCommitAnswer={onSendStepHandler}
        onChangeHandler={onChangeHandler}
        identifier={id}
        typing={true}
        counter={(storeQCounter + 1) * (storeCounter + 1)}
        placeholder={formatMessage({
          id: storeQCounter == 0 ? 'buyer_wizard_items_placeholder' : 'buyer_wizard_store_placeholder',
          defaultMessage: 'Write here..',
        })}
      >
        <Paragraph>{`${questions[storeQCounter]} ${storeLabels[storeCounter]}?`}</Paragraph>
      </QuestionWithFreeText>
    </div>
  );
};

export default Step3;
