import styled from '@emotion/styled';
import { Font, Color } from 'src/constants';

export interface IProps {
  padding?: string
}

export const Container = styled.div`
  height: 100%;
  margin: 0 15px;
  padding: ${(props: IProps) => props.padding || ''}
`;

export const Logo = styled.div`
  padding-top: 100px;
  padding-bottom: 20px;
  text-align: center;
`;

export const SubHeader = styled.h1`
  color: rgb(62, 62, 62);
  font-size: 27px;
  font-family: ${Font.HELVETICA};
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
`;

export const Description = styled.p`
  padding: ${(props: IProps) => props.padding || ''};
  text-align: center;
  color: rgb(62, 62, 62);
  white-space: pre-line;
  font-family: ${Font.HELVETICA};
  line-height: 27px;
  font-size: 18px;
`;

export const PhoneInput = styled.div`
  width: 100%;
  margin: 32px auto;
  
  & > input {
    width: calc(100% - 10px); // TOFIX: remove margin from low-level component
    margin-bottom: 18px;
    border: 1px solid ${Color.MANHATTAN};
  }
`;

export const ErrorMessage = styled(Description)`
  margin-bottom: 33px;
  color: red;
  font-size: 18px;
`;
