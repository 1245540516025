import { IShoppingDetail } from 'src/types';
import { getOrdersMap } from './shoppingListActionCreator';

export interface IShoppingListReducer {
  data: IShoppingDetail[];
  isLoading: boolean;
  loaded: boolean;
  error: any;
}

const initialState: IShoppingListReducer = {
  data: [],
  isLoading: false,
  loaded: false,
  error: null,
};

export const getOrdersMapReducer = (state = initialState, action): IShoppingListReducer => {
  switch (action.type) {
    case getOrdersMap.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case getOrdersMap.SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        loaded: true,
      };
    case getOrdersMap.FAILED:
      return {
        ...state,
        isLoading: false,
        loaded: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
