import * as MapBox from 'mapbox-gl';
import * as React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
// import Select from 'react-select';
import useGeolocation from 'react-hook-geolocation';
import { getValuesByState } from 'src/helpers/orderHelpers';
import { IShoppingDetail } from 'src/types';
import { IMapStyle, MapStyleHooks } from './hooks/mapStyle.hooks'; // FIXME: https://stackoverflow.com/questions/37263357/how-to-declare-and-import-typescript-interfaces-in-a-separate-file
import { StyledDiv } from './map.style';

const API_KEY = process.env.REACT_APP_MAPBOX_KEY;

const currentMarkers: any = [];

interface IMapValue {
  lng: number;
  lat: number;
  zoom: number;
}

interface IStyleOptions {
  value: IMapStyle;
  label: string;
}

const mapValues: IMapValue = {
  // Oslo location
  lng: 10.7522,
  lat: 59.9139,
  zoom: 13,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const options: IStyleOptions[] = [
  { value: 'streets-v11', label: 'Street' },
  { value: 'light-v10', label: 'Light' },
  { value: 'dark-v10', label: 'Dark' },
  { value: 'outdoors-v11', label: 'Outdoor' },
  { value: 'satellite-v9', label: 'Satellite' },
];

interface ICooridnates {
  latitude: number;
  longitude: number;
}

interface IProps {
  locations: IShoppingDetail[];
  history?: any;
}

const Map: React.FC<IProps> = ({ locations, children, history }) => {
  const geolocation = useGeolocation();

  const latitude = geolocation?.latitude || mapValues.lat;
  const longitude = geolocation?.longitude || mapValues.lng;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapStyle, setMapStyle]: any = MapStyleHooks('outdoors-v11');
  const [map, setMap] = React.useState<any | null>(null);
  const mapContainer = React.useRef(null);

  React.useEffect(() => {
    if (map) {
      const center = new MapBox.LngLat(longitude, latitude);

      map.setCenter(center);
    }
  }, [latitude, longitude, map]);

  React.useEffect(() => {
    const geoJson: any = {
      type: 'FeatureCollection',
      features: locations.map(() => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
        },
        properties: {
          icon: 'monument',
        },
      })),
    };

    const initializeMap: any = ({ setMap, mapContainer, mapStyle }: any) => {
      const mapBoxController = new MapBox.Map({
        accessToken: API_KEY,
        container: mapContainer.current,
        center: [longitude, latitude],
        zoom: mapValues.zoom,
      });

      mapBoxController.once('load', () => {
        mapBoxController.addSource('buyer', {
          type: 'geojson',
          data: geoJson,
        });

        mapBoxController.addControl(
          new MapBox.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          }),
        );

        setMap(mapBoxController);
        mapBoxController.resize();
      });
      mapBoxController.addControl(new MapBox.NavigationControl());
      mapBoxController.setStyle(`mapbox://styles/mapbox/${mapStyle}`);
    };

    if (!map) initializeMap({ setMap, mapContainer, mapStyle });
  }, [history, latitude, locations, longitude, map, mapStyle]);

  React.useEffect(() => {
    const geoJson: any = {
      type: 'FeatureCollection',
      features: locations.map((location) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.data.buyerLocation.longitude, location.data.buyerLocation.latitude],
        },
        properties: {
          icon: 'monument',
          hashId: location.id,
          description: location.data.estOrderAmount,
          state: location.data.state,
        },
      })),
    };

    if (map) {
      try {
        if (currentMarkers !== null) {
          for (let i = currentMarkers.length - 1; i >= 0; i--) {
            currentMarkers[i].remove();
          }
        }

        geoJson.features.forEach((marker) => {
          // create a HTML element for each feature
          const el = document.createElement('div');
          el.className = 'marker';
          el.innerHTML = `<div>
                          <div class='custom-marker' style='background-color:${
                            getValuesByState(marker.properties.state).buttonBackground
                          }'>
                           ${marker.properties.description} <br/> 
                            kr
                          </div>
                          <div class='notch'  style='background-color:${
                            getValuesByState(marker.properties.state).buttonBackground
                          }'/>
                      </div>`;
          // make a marker for each feature and add to the map
          el.onclick = () => {
            history.push(`/runner?active_id=${marker.properties.hashId}&is_map=true`);
          };

          const markerLocation = new MapBox.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
          currentMarkers.push(markerLocation);
        });
        map.getSource('buyer').setData(geoJson);
      } catch (e) {
        console.error(e); // TODO: show a dialog
      }
    }
  }, [history, locations, map]);

  // const handleInputChange = ({ value }: any): any => {
  //   setMap(null);
  //   setMapStyle(value);
  // };

  return (
    <>
      {/* <div>
        <Select options={options} onChange={handleInputChange} />
      </div> */}
      <StyledDiv>
        <div className="map" ref={(el: any): any => (mapContainer.current = el)} />
        {children}
      </StyledDiv>
    </>
  );
};

export { Map };
