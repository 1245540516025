import { all, AllEffect } from 'redux-saga/effects';
import { UserSaga } from 'src/sagas/UserSaga';
import { OrderSaga } from './modules/Order/OrderSaga';
import { OrderListMapSaga } from './modules/Shopping/OrdersMaps';
import { ShoppingListSaga } from './modules/Shopping/ShoppingListSaga';

export default function* rootSaga(): IterableIterator<AllEffect<any>> {
  yield all([
    new ShoppingListSaga().watch(),
    new UserSaga().watch(),
    new OrderListMapSaga().watch(),
    new OrderSaga().watch(),
  ]);
}
