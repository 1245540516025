import styled from '@emotion/styled';
import { Font, Color } from 'src/constants';

export interface IPhoneNumberInputProps {
  width?: string;
}

export const StyledPhoneNumberInput = styled.div`
  display: inline;
  & > input {
    height: 58px;
    background: ${Color.WHITE};
    font-size: 20px;
    font-family: ${Font.HELVETICA};
    width: ${(props: IPhoneNumberInputProps) => props.width || 'auto'};
  }
`;

export const Separator = styled.span`
  color: #9baeca;
`;

export const PhoneText = styled.input`
  padding-left: 15px;
`;
