import * as JsCookies from 'js-cookie';

interface ICookie {
  set: (key: COOKIE_KEYS, value) => void;
  get: (key: COOKIE_KEYS) => string | undefined;
  remove: (key: COOKIE_KEYS) => void;
}

export enum COOKIE_KEYS {
  TOKEN = 'token', // TODO: change me
}

const isConnectionSecure: boolean = process.env.NODE_ENV !== 'development';
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : process.env.DOMAIN;

export const Cookies: ICookie = {
  set: (key, value) => JsCookies.set(key, value, { secure: isConnectionSecure, domain, sameSite: 'strict' }),
  get: (key) => JsCookies.get(key),
  remove: (key) => JsCookies.remove(key, { domain }),
};
