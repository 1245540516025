import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { counterReducer } from './counter/counterReducer';
import { orderReducer } from './order/orderReducer';
import { getOrdersMapReducer } from './shoppingList/getOrdersMapsReducer';
import { shoppingListReducer } from './shoppingList/shoppingListReducer';
import { userReducer } from './user/userReducer';

const rootReducer = combineReducers({
  counter: counterReducer,
  shoppingList: shoppingListReducer,
  order: orderReducer,
  mapDataList: getOrdersMapReducer,
  user: userReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;
