// FIXME: rename the file to something that fits its purpose

import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Location from '@material-ui/icons/LocationOn';
import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { shoppingAPI } from 'src/api/shopping';
import { Avatar } from 'src/components/Avatar';
import { Button } from 'src/components/Button';
import { Paragraph } from 'src/components/Typography';
import { getValuesByState } from 'src/helpers/orderHelpers';
import { ErrorMessage } from 'src/pages/onboarding/onboarding.styles';
import {
  getShoppingList,
  uploadReceipt as uploadReceiptActionCreator,
} from 'src/redux/shoppingList/shoppingListActionCreator';
import { IShoppingListReducer } from 'src/redux/shoppingList/shoppingListReducer';
import { getShoppingDetailSelector } from 'src/selectors';
import { IShoppingDetail } from 'src/types';
import { StyledDiv } from '../styles/RunnerStartShopping.style';
import { ReceiptItems } from './ReceiptItems';
import { ShoppingItems } from './RunnerStartShoppingLine';
import { UploadReceipt } from './UploadReceipt';

const ColorCircularProgress = withStyles({
  root: {
    color: '#00695c',
  },
})(CircularProgress);

interface IProps extends RouteComponentProps {
  hashId: string;
}
const RunnerStartShopping: React.FC<IProps> = ({ hashId, history }) => {
  const dispatch = useDispatch();
  const T = useIntl();

  const [error, setError] = React.useState('');
  const [isUpdating, setUpdateState] = React.useState(false); // Duplicate, move me to common hooks
  const [receiptsUploaded, setReceiptsUploaded] = React.useState<boolean>();

  const { isLoading, loaded, isUploadingReceipts }: IShoppingListReducer = useSelector<any, IShoppingListReducer>(
    (state) => state.shoppingList,
  );
  const shoppingDetail: IShoppingDetail = useSelector(
    (state) => getShoppingDetailSelector(state, hashId),
    shallowEqual,
  );
  
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if(isUploadingReceipts) {
      setReceiptsUploaded(true);
    } else if(receiptsUploaded === undefined) {
      setReceiptsUploaded(shoppingDetail?.data?.receipts?.length > 0);
    }
  },[isUploadingReceipts]);

  // handle edge cases
  if (isLoading && !loaded) return <div>Loading</div>;
  if (loaded && !shoppingDetail) return <a href="/runner">go back</a>;

  const {
    data: { buyerStreetAddress, shoppingList, estOrderAmount, state, buyerFirstName, receipts, appliedByMe },
  } = shoppingDetail;

  const allowEdit = state === 'InProgress' || state === 'Assigned';
  const doneShopping = state === 'Completed';

  const endPointState = doneShopping ? 'Delivered' : 'Completed';

  const updateCartStatusHandler = async () => {
    const missingPaidAmounts = shoppingList.filter((item) => (!item.paidAmount)).length > 0;
    const missingReceipts = receipts

    if(missingPaidAmounts && !confirm(formatMessage({id: "missing_paid_amount", defaultMessage: "Paid amount is 0 on one or more of the shops. Are you sure this is correct?"}))) {
      return;
    } else if ((!receiptsUploaded || receipts?.length == 0) && !confirm(formatMessage({id: "missing_receipts", defaultMessage: "You haven't uploaded any receipts. Are you sure you want to continue without?"}))) {
      return; 
    }

    try {
      setUpdateState(true);
      if (endPointState === 'Delivered') {
        await shoppingAPI
          .completeShoppingOrder(hashId)
          .then(
            async () =>
              await shoppingAPI.updateShoppingOrder(hashId, { state: endPointState }).then(() => {
                dispatch({ type: getShoppingList.REQUESTED });
              }),
          )
          .then(() => history.push('/runner'));
      } else {
        await shoppingAPI.updateShoppingOrder(hashId, { state: endPointState }).then(() => {
          dispatch({ type: getShoppingList.REQUESTED });
        });
      }
      setUpdateState(false);
    } catch (error) {
      setUpdateState(false);
      setError(error?.message);
    }
  };

  const uploadReceipt = (file: File) => {
    dispatch(uploadReceiptActionCreator({ file, hashId }));
  };

  return (
    <StyledDiv>
      <div className="header">
        <Avatar src={shoppingDetail.data.buyerPhoto} size={148} />
        <div className="header--title">
          <Paragraph isBold={true} fontSize={22}>
            {buyerFirstName}
          </Paragraph>
          <Paragraph fontSize={16}>{getValuesByState(state, appliedByMe, T).text}</Paragraph>
        </div>
      </div>
      <div className="location location-special">
        <label><FormattedMessage id="runner_orderdetails_deliveryaddress" defaultMessage="Delivery address" /></label>
        <div className="location">
          <Location />
          <span className="address">{buyerStreetAddress}</span>
        </div>
      </div>
      <div className="shopping-cart-details">
        <div className="shopping-cart-details__head">
          <span className="shopping-cart-details__head-title">
            <FormattedMessage id="runner_orderdetails_shoppinglist" defaultMessage="Shopping List" />
          </span>
          <div className="shopping-cart-details__head-cost">
            est <strong>{estOrderAmount} Kr</strong>
          </div>
        </div>
        <ul>
          {shoppingList.map((item) => (
            <ShoppingItems hashId={hashId} item={item} key={item.storeNumber} allowEdit={allowEdit} />
          ))}
        </ul>
      </div>
      {!allowEdit && (
        <div>
          <div className="total-cost">
            <FormattedMessage id="runner_orderdetails_totalcost" defaultMessage="Total Cost" /> :{' '}
            {shoppingDetail.data.totalAmount}
          </div>
        </div>
      )}
      <div className="done">
        {receipts && <ReceiptItems receipts={receipts} />}
        {isUploadingReceipts && <ColorCircularProgress size={25} thickness={5} />}
        {allowEdit && <UploadReceipt onUpload={uploadReceipt} />}
        {allowEdit && shoppingDetail.data.acceptItemReplacements &&  
          <span className="replacement-items">
            <FormattedMessage id="runner_replacementitems_message"  defaultMessage="The buyer wants you to call or text if you can't find an item on the shopping list" /> 
          </span>
        }
        {!allowEdit && shoppingDetail.data.hasSpecialDeliveryConditions &&  
          <span className="buyer-comment">
            <label><FormattedMessage id="comment_from_buyer"  defaultMessage="Comment from buyer" /></label>
            {shoppingDetail.data.specialDeliveryConditions}
          </span>
        }
        {!allowEdit && (
          <span className="delivery-question">
            <FormattedMessage id="runner_orderdetails_paidtext" values={{[0]:shoppingDetail.data.totalAmount,[1]:shoppingDetail.data.buyerFirstName,"\n":<br /> }} defaultMessage="Have you delivered the goods and received payment?" /> 
          </span>
        )}
        <Button
          variant="primary"
          text={
            doneShopping
              ? T.formatMessage({
                  id: 'runner_orderdetails_gotpayment',
                  defaultMessage: 'I have been Paid',
                })
              : T.formatMessage({
                  id: 'runner_orderdetails_doneshopping',
                  defaultMessage: 'I am done Shopping',
                })
          }
          onClick={updateCartStatusHandler}
          loading={isUpdating}
          fullWidth
          disabled={shoppingList.filter((item) => (item.paidAmount !== undefined)).length == 0}
        />
      </div>
      <a href={`tel:${shoppingDetail.data.buyerPhoneNumber}`} className="contact-buyer">
        <FormattedMessage id="runner_orderdetails_link_contactbuyer" defaultMessage="Contact Buyer" />
      </a>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledDiv>
  );
};

const connected = withRouter(RunnerStartShopping);

export { connected as default };
