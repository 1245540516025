import * as React from 'react';
import { Header, SubHeader, Term, HeaderContainer, StyledButton, Container } from './Terms.style';

interface ITerms {
  subheadingTitle?: string;
  terms: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

const Terms: React.FC<ITerms> = ({ subheadingTitle = 'Policy Privacy', terms, buttonLabel, onButtonClick }) => (
  <Container style={{ paddingTop: 40 }}>
    <HeaderContainer>
      <Header>Svop</Header>
    </HeaderContainer>
    <Term dangerouslySetInnerHTML={{ __html: terms }} />
    <StyledButton variant="primary" fullWidth text={buttonLabel} buttonSize="XS" onClick={onButtonClick} />
  </Container>
);

export default Terms;
