import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { PopUpBottom } from 'src/components/PopUpBottom';
import { OpenOrders, OrderDetails, RunnerStartShopping } from 'src/containers/Orders';
import { getShoppingList } from 'src/redux/shoppingList/shoppingListActionCreator';
import { IShoppingListReducer } from 'src/redux/shoppingList/shoppingListReducer';
import { OrderDetailsHeaderPopup } from '../Orders/orderDetailsHeaderPopup';

interface IProps {
  activeShopping: boolean;
  hashId: string;
  isMap: boolean;
}

export const PopUpComponent: React.FC<IProps> = ({ activeShopping, hashId, isMap }) => {
  const { data, loaded }: IShoppingListReducer = useSelector<any, IShoppingListReducer>((state) => state.shoppingList); // FIXME: check the error cause
  // TODO: WIP
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: mapData, loaded: mapLoaded }: IShoppingListReducer = useSelector<any, IShoppingListReducer>(
    (state) => state.mapDataList,
  ); // FIXME: check the error cause

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (!loaded) {
      dispatch({ type: getShoppingList.REQUESTED });
    }
  }, [data, dispatch, loaded]);

  const [component, setComponent]: any = React.useState<any>({
    head: (
      <div>
        <FormattedMessage id="popup_open_orders" defaultMessage="Open Orders" />
      </div>
    ),
    component: <OpenOrders data={data} />,
  });

  const dataSource = isMap ? mapData : data;
  React.useEffect(() => {
    if (!hashId) {
      setComponent({
        head: (
          <OrderDetailsHeaderPopup
            type={'orders'}
            text={formatMessage({ id: 'popup_open_orders', defaultMessage: 'Open Orders' })}
            isMap={isMap}
            isDefault={true}
          />
        ),
        component: <OpenOrders data={dataSource} />,
      });
    } else {
      let component = <OrderDetails hashId={hashId} isMap={isMap} />;
      if (activeShopping) {
        component = <RunnerStartShopping hashId={hashId} />;
      }
      setComponent({
        head: <OrderDetailsHeaderPopup type={'details'} hashId={hashId} isMap={isMap} />,
        component,
      });
    }
  }, [activeShopping, dataSource, formatMessage, hashId, isMap]);

  return (
    <PopUpBottom
      hashId={hashId}
      headComponent={component.head}
      bodyComponent={component.component}
      activeShopping={activeShopping}
      isMap={isMap}
      buttonOpenText={formatMessage({ id: 'common_open', defaultMessage: 'Open' })}
      buttonCloseText={formatMessage({ id: 'common_minimize', defaultMessage: 'Minimize' })}
    />
  );
};
