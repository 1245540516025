export enum Color {
  BLACK = '#000',
  GREY = '#ccc',
  WHITE = '#fff',
  BLUE = '#5080c9',
  INDIGO = '#637ba1',
  RED = '#ff1111',
  BROWN = '#C07067',
  GREEN = '#0EA085',
  DARK_GREY = '#3E3E3E',
  SKY_BLUE = '#5995F2',
  LIGHT_GREY = '#f7f7f9',
  BRIDESMAID = '#fef4eb',
  MANDY = '#e04d4c',
  MANHATTAN = '#f6cba0',
  ROMANCE = '#fffcf9',
  HEATH = '#560F0F',
  SCORPION = '#585858',
  PORSCHE = '#e6a96d',
  ROOF_TERRACOTTA = '#ac1d1d',
  WHEAT = '#f7d5b4',
  MINE_SHAFT = '#363636'
}
