import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'src/components/Button';
import { Paragraph } from 'src/components/Typography';
import { LabeledInput, CheckInput } from 'src/components/Input';
import './DialogMessage.style.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface IProps {
  showDialog: boolean;
  onCloseHandler: (dismiss: boolean) => void;
}

const DialogMessage: React.FC<IProps> = ({ showDialog, onCloseHandler }) => {
  const [open, setOpen] = React.useState(false);
  const [dismiss, setDismiss] = React.useState(false);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    setOpen(showDialog);
  }, [showDialog]);

  

  const dismissChangeHandler = React.useCallback(
    (evt) => {
      setDismiss(evt?.target?.checked || false);
    },
    [setDismiss],
  );

  const handleCheckedChange = () => {
    setDismiss(true);
  }

  const handleClose = () => {
    setOpen(false);
    onCloseHandler(dismiss);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="dialog-text_padding dialog-text_center">
          <FormattedMessage id="runner_welcome_message_title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="list-description">
              <Paragraph>
                <FormattedMessage id="runner_welcome_message_body" />
              </Paragraph>
            </div>
            <ul className="list">
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt1" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt2" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt3" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt4" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt5" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt6" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt7" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt8" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt9" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt10" /></li>
              <li><span className="circle"></span><FormattedMessage id="runner_welcome_message_pt11" /></li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <div className="list-description">
          <Paragraph>
            <label data-for="gotit">
              <input name="gotit" type="checkbox" onChange={dismissChangeHandler} />
              <FormattedMessage id="runner_welcome_gotit" />
            </label>
          </Paragraph>
        </div>
        <DialogActions className="letsgo">
          <Button
            text={formatMessage({ id: 'runner_lets_go' })}
            variant="primary"
            buttonSize="S"
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DialogMessage;
