import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { useFirebase } from 'src/components/Firebase/FirebaseContext';
import { Input } from 'src/components/Input';
import { Wrapper } from 'src/components/Wrapper';
import { PUBLIC_ROUTES } from 'src/constants/Routes';
import { StyledCentered } from 'src/styles';
import { Container, Logo, Description, SubHeader, PhoneInput, ErrorMessage } from '../onboarding.styles';
import { SendAgainButton } from './verification-page.style';

const VerificationPage = () => {
  const history = useHistory();
  const { firebase, signInWithPhoneNumber, setRecaptchaVerifier, phoneNumber, confirmOtp } = useFirebase();
  const [OTP, setOTP] = React.useState('');
  const [error, setError] = React.useState('');
  const { formatMessage } = useIntl();

  const handleResendClick = async () => {
    try {
      await signInWithPhoneNumber(phoneNumber);
    } catch (error) {
      setError(error?.message);
    }
  };

  const handleConfirmClick = async () => {
    try {
      await confirmOtp(OTP);
    } catch (error) {
      setError(error?.message);
    }
  };

  React.useEffect(() => {
    if (!phoneNumber) {
      history.replace(PUBLIC_ROUTES.LANDING);
    }
  }, [history, phoneNumber]);

  React.useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    setRecaptchaVerifier(recaptchaVerifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper wrapperHeight="100vh">
      <Container>
        <Logo>
          <img src="images/svop_logo.png" />
        </Logo>
        <SubHeader>
          <FormattedMessage id="register_otp_title" defaultMessage="Enter the code sent to your phone" />
        </SubHeader>

        <PhoneInput>
          <Input
            type="numeric"
            pattern="[0-9]*"
            placeholder={formatMessage({ id: 'common_otp', defaultMessage: 'OTP' })}
            onChange={(evt) => {
              setOTP(evt?.target?.value);
            }}
          />
          <Button
            text={formatMessage({ id: 'register_otp_confirmcode', defaultMessage: 'Confirm code' })}
            variant="primary"
            buttonSize="XS"
            fullWidth
            onClick={handleConfirmClick}
          />
        </PhoneInput>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Description>
          {formatMessage(
            { id: 'register_otp_codesentto', defaultMessage: 'Your code was sent to {0}' },
            { [0]: phoneNumber },
          )}
          <br />
          <SendAgainButton onClick={handleResendClick}>
            <FormattedMessage id="register_otp_link_sendagain" defaultMessage="Send again" />
          </SendAgainButton>
        </Description>
        <div id="recaptcha-container" />
      </Container>
    </Wrapper>
  );
};

export default VerificationPage;
