import styled from '@emotion/styled';

export const SendAgainButton = styled.button`
  margin-top: 8px;
  background: none;
  font-size: 18px;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
