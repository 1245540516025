import { IntlShape } from 'react-intl';

export const typingDelayInMs = 1000;

export enum Fee {
  BASIC = 75,
  OTHERS = 45,
}
export const getMessage1 = (buyerName, availableRunnersList, formatter: IntlShape) => {
  const runnerName = availableRunnersList[0]?.runnerFirstName || '';
  return formatter.formatMessage({ id: 'buyer_wizard_runnerfound' }, { buyer: buyerName, runner: runnerName });
};

export const getMessage2 = (buyerName, formatter: IntlShape) =>
  formatter.formatMessage({ id: 'buyer_wizard_multiple_runnerfound' }, { buyer: buyerName });

export const obligedMessage = (intlShape: IntlShape, orderNumber, isSpecialOrder) =>
  intlShape.formatMessage(
    {
      id: (isSpecialOrder ? 'buyer_wizard_msg_confirmation_specialOrder' : 'buyer_wizard_msg_confirmation'),
      defaultMessage: 'Cool. We’ll notify you when a shopper is ready for you. Stay safe and hang on',
    },
    { orderNumber: orderNumber || '' },
  );

export const runnerAssignedMessage = (runnerFirstName, formatter: IntlShape) =>
  formatter.formatMessage({ id: 'buyer_wizard_msg_accepted' }, { runnerFirstName: runnerFirstName });

export const runnerInProgressMessage = (runnerFirstName, formatter: IntlShape) =>
  formatter.formatMessage({ id: 'buyer_wizard_msg_runnerconfirmation' }, { runnerFirstName: runnerFirstName });

export const getDate = (intlShape: IntlShape) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dateObj = new Date();

  const localizedDayName = localizedDate(dayNames[dateObj.getDay()], intlShape);
  const localizedMonthName = localizedDate(monthNames[dateObj.getMonth()], intlShape);

  return `${localizedDayName} ${dateObj.getDate()}. ${
    intlShape.locale === 'no' ? localizedMonthName.toLocaleLowerCase() : localizedMonthName
  }, ${(dateObj.getHours() < 10 ? '0' : '') + dateObj.getHours()}:${
    (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes()
  } `;
};

const localizedDate = (date: string, intlShape: IntlShape) =>
  intlShape.formatMessage({
    id: date.toLocaleLowerCase(),
    defaultMessage: date,
  });

export const feeCalculator = (numberOfStores: number) => {
  if (numberOfStores > 1) {
    return Fee.BASIC + Fee.OTHERS * (numberOfStores - 1);
  }
  return Fee.BASIC;
};
