import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getValuesByState } from 'src/helpers/orderHelpers';
import { IOrderState } from 'src/types';
import { Button } from '../Button';
import { Paragraph } from '../Typography';

interface IOrderLine {
  id: string;
  name: string;
  status: IOrderState;
  appliedByMe: boolean;
  buyerPhoto: string;
}

const OpenOrderLine: React.FC<IOrderLine> = ({ id, name, status, appliedByMe, buyerPhoto }) => {
  const formatMessage = useIntl();

  let to = `/runner?active_id=${id}`;
  if (['InProgress', 'Completed'].includes(status)) {
    to += '&active_shopping=true';
  }

  return (
    <>
      <img src={buyerPhoto} />
      <div className="item2">
        <Paragraph isBold={true} fontSize={22}>
          {name}
        </Paragraph>
        <Paragraph fontSize={16} textAlign="left">{getValuesByState(status, appliedByMe, formatMessage).text}</Paragraph>
      </div>
      <Link to={to}>
        <Button
          variant="primary"
          text={getValuesByState(status, appliedByMe, formatMessage).button}
          buttonHeight="XS"
          buttonSize="XS"
          buttonBackground={getValuesByState(status, appliedByMe, formatMessage).buttonBackground}
          fullWidth
        />
      </Link>
    </>
  );
};

export default OpenOrderLine;
