import autobind from 'autobind-decorator';
import { call, CallEffect, put, PutEffect, race, take, takeLatest } from 'redux-saga/effects';
import { shoppingAPI } from 'src/api/shopping';
import { filterData } from 'src/helpers/dataBeautifier';
import {
  getShoppingList,
  UPDATE_RECEIPT as uploadReceiptActionCreator,
} from 'src/redux/shoppingList/shoppingListActionCreator';
import { BaseSaga } from '../../BaseSaga';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export class ShoppingListSaga extends BaseSaga {
  @autobind
  public *fetchShoppingList(): IterableIterator<CallEffect | PutEffect<any> | any> {
    while (true) {
      try {
        yield put({ type: getShoppingList.PENDING });
        const data = yield call(shoppingAPI.fetchOrderList); // 1,2,3 => latitude,longitude, radius
        yield put({ type: getShoppingList.SUCCEEDED, payload: filterData(data) });
        yield call(delay, process.env.REACT_APP_DATA_POLLING_MS);
      } catch (e) {
        yield call(delay, process.env.REACT_APP_DATA_POLLING_MS);
        yield put({ type: getShoppingList.FAILED, error: e });
      }
    }
  }

  public *uploadReceipt(action) {
    try {
      yield put({ type: uploadReceiptActionCreator.PENDING });
      yield call(shoppingAPI.uploadReceipt, action.payload.file, action.payload.hashId); //FIXME type is not showing up
      yield put({ type: uploadReceiptActionCreator.SUCCEEDED });
    } catch (e) {
      yield put({ type: uploadReceiptActionCreator.FAILED, error: e });
    }
  }

  protected *registerListeners(): IterableIterator<any> {
    yield takeLatest(getShoppingList.REQUESTED, this.fetchShoppingList);
    yield takeLatest(uploadReceiptActionCreator.REQUESTED, this.uploadReceipt);
    while (true) {
      yield take(getShoppingList.REQUESTED);
      yield race([call(this.fetchShoppingList), take(getShoppingList.REQUESTED)]);
    }
  }
}
