import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionWithButton } from 'src/components/Questions';
import { Paragraph } from 'src/components/Typography';
import { Color } from 'src/constants/Color';
import { Fee, feeCalculator } from 'src/constants/General';
import { getIsComplimentary } from 'src/redux/order/actions/orderActionCreator';

const Step5 = ({ storesSelected, goAheadHandler, goAheadAnswer, buyerName, isSpecialOrder }) => {
  const { formatMessage } = useIntl();
  const [isComplimentary, setIsComplimentary] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!isSpecialOrder) {
      getIsComplimentary().then((result: boolean) => setIsComplimentary(result)); // TODO: Call it via dispatch if need to persist in redux store.
    }
  }, []);

  return (
    <QuestionWithButton
      userClickHandler={goAheadHandler}
      eventVal1={true}
      eventVal2={false}
      caption1={formatMessage({ id: 'common_goahead', defaultMessage: 'Go ahead' })}
      caption2={formatMessage({ id: 'common_cancel', defaultMessage: 'Cancel' })}
      buyerResponse={goAheadAnswer}
      typing={true}
    >
      <Paragraph>
        <FormattedMessage
          id={isSpecialOrder ? "buyer_wizard_confirmorder_specialOrder" : (isComplimentary? "buyer_wizard_confirmorder_complimentary" : "buyer_wizard_confirmorder")}
          values={{
            name: buyerName,
            initialFee: Fee.BASIC,
            serviceFeeOtherStore: Fee.OTHERS,
            totalFee: <b style={{ color: Color.GREEN }}>{feeCalculator(storesSelected)}</b>,
          }}
        />
      </Paragraph>
    </QuestionWithButton>
  );
};

export default Step5;
