import styled from '@emotion/styled';
import { Font, Color } from 'src/constants';

export const Greeting = styled.h1`
  height: 30px;
  color: rgb(62, 62, 62);
  font-size: 34px;
  font-family: ${Font.HELVETICA};
  text-align: left;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Description = styled.p`
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 34px;
`;

export const Circle = styled.span`
  background: ${Color.MANDY};
  color: ${Color.WHITE};
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 23px;
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: bold;
`;

export const UserTypeWrapper = styled.div`
  margin-top: 105px;
  margin-bottom: 140px;

  & > Button {
    margin: 6px 0;
  }
`;

export const BulletItem = styled.div`

  display: inline-block;
  margin-bottom: 5px;

  .message {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
  }

  & div {
    display: inline-block;
  }

  
`;

export const Header = styled.div`
  margin: 0px 0 30px 0;
  height: 68px;
  font-family: ${Font.HELVETICA};
  font-size: 24px;
  font-weight: bold;

  & div:nth-child(2) {
    text-align: center;
  }

  & div {
    display: inline-block;
    width: 30%;

    h2 {
      color: rgb(62, 62, 62);
      font-size: 24px;
      text-align: center;
      font-weight: bold;
    }
  }

  img {
    height: 40px;
  }
`;
