import styled from '@emotion/styled';
import { Width, Font } from 'src/constants';

export const ExternalAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const ExternalLaunchIcon = styled.span`
  margin-left: 5px;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner_container {
    display: flex;

    .stacked {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: ${Width.SMALLMOBILE}) {
      flex-direction: column;
    }
  }
`;

export const Header = styled.h1`
  color: rgb(62, 62, 62);
  font-size: 48px;
  font-family: ${Font.HELVETICA};
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin: 30px 0 0 40px;
`;

export const Section = styled.div`
  margin-left: 50px;
  margin-top: 29px;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
  max-width: ${Width.SMALLMOBILE};
  padding-bottom: 5px;

  & p {
    font-size: 18px;
    font-family: ${Font.HELVETICA};
    color: rgb(62, 62, 62);
    margin-bottom: 18px;
    line-height: 30px;

    & span {
      margin-right: 30px;
    }

    & a {
      cursor: pointer;
    }
  }
`;

export const Close = styled.button`
  width: 60px;
  right: 10px;
  top: 30px;
  position: absolute;
  background-color: transparent;
`;
