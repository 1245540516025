import styled from '@emotion/styled';
import {Font, Color} from 'src/constants';

export const StyledDiv = styled.div`
  padding: 20px;
  background: ${Color.ROMANCE};
  .location {
    display: flex;
    padding: 0 20px;
    svg {
      height: 30px;
      width: 30px;
      color: #161616;
    }
    .address {
      color: rgb(62, 62, 62);
      padding-left: 20px;
      font-size: 21px;
      font-family: ${Font.HELVETICA};
      text-transform: Uppercase;
    }
    align-items: center;
    height: 56px;
    border-bottom: 1px solid ${Color.WHEAT};
  }

  .shops {
    padding: 0 8px;
    ul {
      display: grid;
      grid-row-gap: 20px;
      margin: 25px 0px;
      li {
        display: grid;
        grid-template-columns: 50px auto;
        justify-content: flex-start;

        .pointer {
          align-self: baseline;
          justify-self: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: ${Color.PORSCHE};
        }
        .shop-details {
          display: grid;
          text-align: left;
          grid-row-gap: 10px;
          padding: 0 8px;

          .name {
            color: rgb(62, 62, 62);
            font-size: 18px;
            font-family: Helvetica-Bold;
            font-weight: bold;
          }
          .content {
            color: rgb(62, 62, 62);
            font-size: 18px;
          }
        }
      }
    }
  }

  .delivery-conditions {
    border-top: 1px solid ${Color.WHEAT};
    padding: 25px;

    label {
      display: block;
      height: 19px;
      margin-bottom: 8px;
      color: #161616;
      font-size: 16px;
      font-family: Helvetica;
      letter-spacing: 0px;
      text-transform: uppercase;
    }
  }

  .footer {
    border-top: 1px solid ${Color.WHEAT};
    padding: 15px 0px;
    width: 100%;
    bottom: 0;
    .summary {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 20px 0;
      label {
        width: 200px;
        height: 19px;
        color: #161616;
        font-size: 16px;
        font-family: Helvetica;
        letter-spacing: 0px;
        text-transform: uppercase;
        text-align: right;
        padding-right: 10px;
      }
      .estimated-value {
        font-family: Helvetica-Bold;
        padding: 0 20px;
        color: #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        height: 37px;
        background: ${Color.BRIDESMAID};
      }
      .buttonWrapper {
        position: absolute;
        bottom: 0px;
      }
    }
  }
`;
