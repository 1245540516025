// TODO : move me to styles!!!
import styled from '@emotion/styled';
import * as React from 'react';
import { Font, Width, Color } from 'src/constants';

export const StyledHeader = styled.span`
  color: rgb(62, 62, 62);
  font-size: 82px;
  font-family: ${Font.HELVETICA_BOLD};
  text-align: center;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 150px;

  @media (max-width: ${Width.SMALLMOBILE}) {
    font-size: 44px;
    margin-bottom: 50px;
  }
`;

export const StyledMobHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 111px;
  font-weight: bold;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border-bottom: 1px solid ${Color.MANHATTAN};
  
  & > div {
    display: inline-block;

    .inline {
      display: inline-block;
    }

    .left {
      margin-left: 20px;
      position: absolute;
    }
    .center {
      width: 100%;
    }
    .right {
      right: 50px;
      position: absolute;
    }
  }

  .back-button {
    color: #347ef0;
    margin-left: 20px;
    width: 30px;
  }
`;

const Header: React.FC = ({ children }) => <StyledHeader>{children}</StyledHeader>;
const MobHeader: React.FC = ({ children }) => <StyledMobHeader>{children}</StyledMobHeader>;
export { Header, MobHeader };
