import styled from '@emotion/styled';
import {Color} from 'src/constants';

export const StyledDiv = styled.div`
  display: grid;
  grid-row-gap: 30px;
  padding: 20px;
  width: 100%;
  background: ${Color.ROMANCE};

  form {
    display: flex;
    position: relative;
    grid-template-columns: auto 275px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 25px;
    height: 60px;

    input {
      padding-left: 30px;
      color: rgb(63, 64, 122);
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.45px;
      line-height: 36px;
      flex: 5 1 0%;
      outline: none;
      border: 1px solid ${Color.WHEAT};
      margin-right:5px;
    }

    button {
      color: ${Color.WHITE};
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.45px;
      line-height: 36px;
      cursor: pointer;
      flex: 3 1 0%;
      background: ${Color.MANDY};
      border: unset;
      padding: 0px;
      text-transform: uppercase;

      &:disabled {
        border-color: lightgrey;
        cursor: initial;
        background-color: lightgrey;
        color: grey;
      }

      &:hover {
        box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.42);
      }
    }
  }

  .upload {
    cursor: pointer;

    .address {
      text-transform:none !important;
    }
  }
  .upload:hover {
    border: 1px solid ${Color.MANHATTAN};
  }

  .location {
    display: flex;
    padding: 0 20px;
    color: #315080;
    svg {
      height: 30px;
      width: 30px;
      color: #161616;
    }
    .address {
      color: #161616;
      padding-left: 20px;
      font-size: 21px;
      font-family: Helvetica;
      text-transform: uppercase;
    }
    align-items: center;
    height: 56px;
    background: ${Color.WHITE};
  }
  .header {
    display: grid;
    grid-row-gap: 10px;
    justify-items: center;
    justify-content: center;
    height: 100%;

    .header--title {
      display: grid;
    }
  }
  .shopping-cart-details {
    display: grid;
    grid-row-gap: 10px;
    margin-top: 30px;
    .shopping-cart-details__head {
      display: flex;
      justify-content: space-between;
      .shopping-cart-details__head-title {
        color: #3e3e3e;
        font-family: Helvetica;
        font-size: 18px;
        text-transform: uppercase;
        padding: 5px 10px 0 0;
      }
      .shopping-cart-details__head-cost {
        background: ${Color.BRIDESMAID};
        color: #3e3e3e;
        font-size: 22px;
        font-family: Helvetica;
        padding: 5px 20px;
        strong {
          color: #3e3e3e;
          font-size: 22px;
          font-family: Helvetica-Bold;
          font-weight: bold;
        }
      }
      .shopping-cart-details__head-item_price {
        background: ${Color.BRIDESMAID};
        height: 35px;

        color: #3e3e3e;
        font-size: 22px;
        font-family: Helvetica-Bold;
        font-weight: bold;
        padding: 5px 20px;
      }
    }

    ul {
      li {
        margin: 20px 0px;
      }
    }

    .content {
      text-align: left;
    }
  }
  .location-special {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
    padding: 15px 0px;
    label {
      padding-left: 20px;
      padding-bottom: 5px;
      color: #161616;
      font-size: 14px;
    }
  }
  .upload {
    text-transform: none;
  }
  .done {
    display: grid;
    grid-row-gap: 30px;
  }
  .contact-buyer {
    text-align: center;
    color: rgb(62, 62, 62);
    font-family: Helvetica-Bold;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
  }

  .total-cost {
    float: right;
    text-align: right;
    padding: 5px 20px;
    background: ${Color.BRIDESMAID};
    color: #161616;
    font-size: 22px;
    font-family: Helvetica-Bold;
    font-weight: bold;
    text-align: right;
  }

  .replacement-items {
    color: ${Color.MANDY};
    font-size: 20px;
    font-family: Helvetica;

    text-align: center;
    letter-spacing: 0px;
  }

  .buyer-comment {
    font-size: 20px;
    font-family: Helvetica;
    padding: 20px 0;
    background: ${Color.WHITE};
    text-align: center;
    letter-spacing: 0px;

    label {
      display: block;
      height: 19px;
      margin-bottom: 8px;
      color: #161616;
      font-size: 16px;
      font-family: Helvetica;
      letter-spacing: 0px;
      text-transform: uppercase;
    }
  }

  .delivery-question {
    color: rgb(62, 62, 62);
    font-size: 20px;
    font-family: Helvetica;
    text-align: center;
    letter-spacing: 0px;
  }
`;
