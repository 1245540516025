import * as React from 'react';

export const useSwipeButtonHooks: any = ({ container, slider, isTouchDevice, isReverse }) => {
  const [startX, setStartX] = React.useState<number>(0);
  const [sliderLeft, setSliderLeft] = React.useState<number>(0);
  const [unlocked, setUnlocked] = React.useState<boolean>(false);
  const [isDragging, setDragging] = React.useState<boolean>(false);
  const [containerWidth, setContainerWidth] = React.useState<number>(0);

  React.useEffect(() => {
    setContainerWidth(container.current.clientWidth - 50);
  }, [container]);

  const updateSliderStyle = React.useCallback(() => {
    if (isReverse) {
      if (sliderLeft === 0) return;
      slider.current.style.left = sliderLeft + 'px';
    } else {
      slider.current.style.left = sliderLeft + 50 + 'px';
    }
  }, [isReverse, slider, sliderLeft]);

  const onDrag = React.useCallback(
    (e) => {
      if (isDragging) {
        if (isReverse) {
          if (isTouchDevice) {
            setSliderLeft(Math.max(Math.min(containerWidth, containerWidth - (startX - e.touches[0].clientX)), 0));
          } else {
            setSliderLeft(Math.max(Math.min(containerWidth, containerWidth - (startX - e.clientX)), 0));
          }
        } else {
          if (isTouchDevice) {
            setSliderLeft(Math.min(Math.max(0, e.touches[0].clientX - startX), containerWidth));
          } else {
            setSliderLeft(Math.min(Math.max(0, e.clientX - startX), containerWidth));
          }
        }
        updateSliderStyle();
      }
    },
    [containerWidth, isDragging, isReverse, isTouchDevice, startX, updateSliderStyle],
  );

  const stopDrag = React.useCallback(() => {
    if (isReverse) {
      if (isDragging && sliderLeft != 0) {
        setDragging(false);
        if (sliderLeft < containerWidth * 0.1) {
          setSliderLeft(0);
          setUnlocked(true);
          // slider.current.style.left = 0 + 'px';
        } else {
          setSliderLeft(containerWidth);
          slider.current.style.left = containerWidth + 'px';
        }
      }
    } else {
      if (isDragging) {
        setDragging(false);
        if (sliderLeft > containerWidth * 0.9) {
          setSliderLeft(containerWidth);
          slider.current.style.left = container.current.clientWidth + 'px';
          setUnlocked(true);
        } else {
          setSliderLeft(0);
          slider.current.style.left = 50 + 'px';
        }
      }
    }
  }, [container, containerWidth, isDragging, isReverse, slider, sliderLeft]);

  React.useEffect(() => {
    if (isTouchDevice) {
      document.addEventListener('touchmove', onDrag);
      document.addEventListener('touchend', stopDrag);
    } else {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', stopDrag);
    }
    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', stopDrag);
    };
  }, [onDrag, stopDrag, isDragging, isTouchDevice]);
  const setDefault = () => {
    setStartX(0);
    setStartX(0);
    setSliderLeft(0);
    setUnlocked(false);
    setDragging(false);
    setContainerWidth(0);
  };

  return [setDragging, setStartX, unlocked, setDragging, setDefault];
};
