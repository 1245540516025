import autobind from 'autobind-decorator';
import { call, CallEffect, put, PutEffect, race, take } from 'redux-saga/effects';
import { shoppingAPI } from 'src/api/shopping';
import { filterData } from 'src/helpers/dataBeautifier';
import { getOrdersMap } from 'src/redux/shoppingList/shoppingListActionCreator';
import { BaseSaga } from '../../BaseSaga';

const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position.coords);
      }, (error) => {
        resolve({longitude: 10.7522, latitude: 59.9139}); // OSLO
      });
    } else {
      resolve({longitude: 10.7522, latitude: 59.9139}); // OSLO
      //reject('No Geo Coordinates Available');
    }
  });
};

const radius = process.env.REACT_APP_MAP_SEARCH_RADIUS_KM;
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export class OrderListMapSaga extends BaseSaga {
  @autobind
  public *fetchOrderListMap(): IterableIterator<CallEffect | PutEffect<any>> {
    while (true) {
      try {
        yield put({ type: getOrdersMap.PENDING });
        const coordinates: any = yield call(getGeoLocation); // FIXME if undefined
        const data = yield call(shoppingAPI.fetchOrderListMap, coordinates.latitude, coordinates.longitude, radius);
        yield put({ type: getOrdersMap.SUCCEEDED, payload: filterData(data) });
        yield call(delay, process.env.REACT_APP_DATA_POLLING_MS);
      } catch (e) {
        yield call(delay, process.env.REACT_APP_DATA_POLLING_MS);
        yield put({ type: getOrdersMap.FAILED, error: e });
      }
    }
  }

  protected *registerListeners(): IterableIterator<any> {
    while (true) {
      yield take(getOrdersMap.REQUESTED);
      yield race([call(this.fetchOrderListMap), take(getOrdersMap.REQUESTED)]);
    }
  }
}
