import styled from '@emotion/styled';
import { Color, Font } from 'src/constants/';

const StyledNumbers = styled.div(() => ({
  background: Color.MANDY,
  borderRadius: '44px',
  width: '44px',
  height: '44px',
  lineHeight: '44px',
  cursor: 'pointer',
  color: Color.WHITE,
  fontFamily: Font.HELVETICA,
  fontSize: '26px',
  letterSpacing: 0,
  textAlign: 'center',
  marginRight: '16px',
}));

export default StyledNumbers;
