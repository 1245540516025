import styled from '@emotion/styled';
import { Color, Font } from 'src/constants';

export type buttonSize = 'BXS' | 'XS' | 'S' | 'L';
export type buttonImpression = 'positive' | 'negative';

export interface IStyledComponentProps {
  variant: 'primary' | 'secondary' | 'mbutton';
  buttonSize?: buttonSize; // FIXME: this keeps repeating with the button component.
  impression?: buttonImpression;
  fullWidth?: boolean;
  buttonBackground?: string;
  buttonHeight?: buttonSize;
}

const getButtonWidth = (type: buttonSize) => {
  switch (type) {
    case 'S':
      return '376px';
    case 'L':
      return '376px';
    case 'XS':
      return '120px';
    case 'BXS':
      return '147px;';
  }
};

const getButtonHeight = (type) => {
  switch (type) {
    case 'XS':
      return '50px';
    default:
      return '60px';
  }
};

const StyledButton = styled.button<IStyledComponentProps>`
  background: ${(props: IStyledComponentProps) =>
    props.buttonBackground ? props.buttonBackground : props.variant === 'primary' ? Color.MANDY : Color.INDIGO};
  color: rgb(255, 255, 255);
  font-size: 18px; 
  text-transform: uppercase;
  font-family: ${Font.HELVETICA};
  text-align: center;
  letter-spacing: 0px;

  height: ${(props: IStyledComponentProps) => getButtonHeight(props.buttonHeight)};

  &:disabled {
    border-color: lightgrey;
    cursor: initial;
    background-color: lightgrey;
    color: grey;
  }

  &:hover {
    box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.42);
  }

  width: ${(props: IStyledComponentProps) => (props.fullWidth ? '100%' : getButtonWidth(props.buttonSize || 'S'))};
`;

const StyledMobButton = styled.button<IStyledComponentProps>`
  background: ${Color.WHITE};
  box-shadow: 0px 0px 34px -20px rgb(19, 196, 163);
  border-radius: 15px;
  padding: 13px 33px;
  color: ${(props: IStyledComponentProps) => (props.impression === 'positive' ? Color.GREEN : Color.BROWN)};
  font-size: 18px; 
  font-family: ${Font.HELVETICA_BOLD};
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  margin-left: 15px;
`;

export { StyledButton, StyledMobButton };
