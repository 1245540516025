import * as React from 'react';
import { useIntl } from 'react-intl';
import { Bubble, BlueBubble } from 'src/components/Bubble';
import { MobInputText, MobInput } from 'src/components/Input';
import { typingDelayInMs } from 'src/constants/General';
import {
  InputContainer,
  InputFreeTextContainer,
  SendBtnTextContainer,
  SendBtnFreeTextContainer,
  StyledAnswerDiv,
} from './GeneralQuestion.style';
import { ReactComponent as SendBtn } from './images/send.svg';

export interface IProps {
  onChangeHandler: (evt, identifier) => void;
  onCommitAnswer: (identifier) => void;
  identifier: string;
  buyerResponse: string;
  typing?: boolean;
  counter?: number;
  placeholder?: string;
}

export interface IQAProps {
  onChangeHandler: (evt, identifier) => void;
  onCommitAnswer: (identifier) => void;
  identifier: string;
  buyerResponse: any;
  type: string;
  typing?: boolean;
  placeholder?: string;
}

const GeneralQuestion: React.FC<IProps> = ({
  typing,
  counter,
  identifier,
  children,
  buyerResponse,
  onChangeHandler,
  onCommitAnswer,
  placeholder,
}) => {
  const [showInput, setShowInput] = React.useState<boolean>(typing ? !typing : true);
  const [currentCounter, setcurrentCounter] = React.useState<number>(counter || 0);

  React.useEffect(() => {
    if (counter !== undefined && currentCounter < counter) {
      setShowInput(false);
      setcurrentCounter(counter);
    }

    const timer = setTimeout(() => setShowInput(true), typingDelayInMs);
    return () => clearTimeout(timer);
  }, [counter, currentCounter]);

  return (
    <div>
      <Bubble typing={typing} counter={counter}>
        {children}
      </Bubble>
      {showInput && (
        <React.Fragment>
          <InputFreeTextContainer>
            <MobInputText
              value={buyerResponse}
              identifier={identifier}
              onChangeHandler={onChangeHandler}
              placeholder={placeholder ? placeholder : 'Write here...'}
            />
            <SendBtnFreeTextContainer
              onClick={() => {
                onCommitAnswer(identifier);
              }}
            >
              <SendBtn />
            </SendBtnFreeTextContainer>
          </InputFreeTextContainer>
        </React.Fragment>
      )}
    </div>
  );
};

const GeneralQuestionWithAnwer: React.FC<IQAProps> = ({
  typing,
  type,
  identifier,
  children,
  buyerResponse,
  onChangeHandler,
  onCommitAnswer,
  placeholder,
}) => {
  const [showInput, setShowInput] = React.useState<boolean>(typing ? !typing : true);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowInput(true), typingDelayInMs);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <Bubble typing={typing}>{children}</Bubble>
      {showInput && (
        <React.Fragment>
          {!buyerResponse ? (
            <InputContainer>
              <MobInput
                type={type}
                identifier={identifier}
                onChangeHandler={onChangeHandler}
                placeholder={placeholder || 'Write here...'}
              />
              <SendBtnTextContainer onClick={() => onCommitAnswer(identifier)}>
                <SendBtn />
              </SendBtnTextContainer>
            </InputContainer>
          ) : (
            ''
          )}

          <StyledAnswerDiv>{buyerResponse ? <BlueBubble>{buyerResponse}</BlueBubble> : ''}</StyledAnswerDiv>
        </React.Fragment>
      )}
    </div>
  );
};

export interface IEditAnswerProps {
  isFreeText: boolean;
  value: string;
  inputType: string;
  identifier: string;
  placeholder: string;
  onChangeHandler: (evt, identifier) => void;
  onCommitChange: (identifier) => void;
};

const EditAnswer: React.FC<IEditAnswerProps> = ({isFreeText, value, inputType, identifier, placeholder, onChangeHandler, onCommitChange}) => {
  const singleInput = 
    <InputContainer>
      <MobInput
        value={value}
        type={inputType}
        identifier={identifier}
        onChangeHandler={onChangeHandler}
        placeholder={placeholder || 'Write here...'}
      />
      <SendBtnTextContainer onClick={() => onCommitChange(identifier)}>
        <SendBtn />
      </SendBtnTextContainer>
    </InputContainer>;

  const freeTextInput = 
    <InputFreeTextContainer>
      <MobInputText
        value={value}
        identifier={identifier}
        onChangeHandler={onChangeHandler}
        placeholder={placeholder ? placeholder : 'Write here...'}
      />
      <SendBtnFreeTextContainer
        onClick={() => {
          onCommitChange(identifier);
        }}
      >
        <SendBtn />
      </SendBtnFreeTextContainer>
    </InputFreeTextContainer>;

  return (
    <>
    {isFreeText && freeTextInput}
    {!isFreeText && singleInput}
    </>
  );
};

export { GeneralQuestion, GeneralQuestionWithAnwer, EditAnswer };
