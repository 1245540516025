import styled from '@emotion/styled';

export const StyledOrdrderLine = styled.div`
  display: grid;
  grid-template-columns: 90px auto 117px;
  grid-column-gap: 10px;
  grid-row-gap: 20px;

  align-items: center;

  margin-top: 15px;
  padding: 25px 20px;

  img {
    width: 81px;
    height: 81px;
    border-radius: 50%;
    overflow: hidden;

    & > img {
      height: 100%;
    }
  }

  .item2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  a {
    text-align: right;
  }
`;
