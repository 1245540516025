import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useWindowDimensions } from 'src/hooks/WindowDimension';
import { StyledDiv } from './PopUpBottom.style';

interface IProps extends RouteComponentProps {
  activeShopping?: boolean;
  headComponent: any; // FIXME: get me the type
  bodyComponent: any;
  buttonOpenText?: string;
  buttonCloseText?: string;
  isMap: boolean;
  hashId: string;
}

const PopUpBottom: React.FC<IProps> = ({
  isMap,
  hashId,
  history,
  bodyComponent,
  headComponent,
  activeShopping = false,
  buttonOpenText,
  buttonCloseText,
}) => {
  const [buttonText, setButtonText] = React.useState(buttonOpenText);
  const [isOpen, setIsOpen] = React.useState(false);
  let { height } = useWindowDimensions();

  height = activeShopping ? height : height / 2;

  const fade = useSpring({
    height: isOpen ? height : 0,
  });

  React.useEffect(() => {
    if (isMap) {
      setIsOpen(true);
    }
  }, [isMap]);

  React.useEffect(() => {
    setButtonText(isOpen ? buttonCloseText : buttonOpenText);
  }, [buttonCloseText, buttonOpenText, isOpen]);

  const toggleHandler = (): void => {
    setIsOpen(!isOpen);
    history.push('/runner');
  };

  return (
    <StyledDiv isOpen activeShopping={activeShopping}>
      <animated.div className={'popUp'} style={fade}>
        {!activeShopping && (
          <div className={'header' + (isOpen ? ' popup-open' : ' popup-close')}>
            <div className="header--state-open" onClick={toggleHandler} />
            {headComponent}
            <button onClick={toggleHandler}>{hashId ? <Close /> : buttonText}</button>
          </div>
        )}
        <div className="body-content">
          {activeShopping && (
            <button className="button-close" onClick={toggleHandler}>
              <Close />
            </button>
          )}
          {bodyComponent}
        </div>
      </animated.div>
    </StyledDiv>
  );
};

PopUpBottom.defaultProps = {
  buttonOpenText: 'Open',
  buttonCloseText: 'Minimize',
};

const connected = withRouter(PopUpBottom);

export default connected;
