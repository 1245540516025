/* eslint-disable @typescript-eslint/camelcase */
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LabeledInput, CheckInput } from 'src/components/Input';
import { InputForm } from 'src/components/Input/Input.style';
import { StripeCreditCard } from 'src/components/StripeCreditCard';
import { Wrapper } from 'src/components/Wrapper';
import { USER_TYPE } from 'src/constants';
import { validatePaymentProfile, ValidationResult, userRequiresCard } from 'src/helpers/user';
import { useStateSelector } from 'src/redux/reducers';
import { confirmCardRegistration, setupPaymentCustomer, updateUserProfile } from 'src/redux/user/userActionCreator';
import { StyledCentered } from 'src/styles';
import { Container, Description } from '../onboarding.styles';
import { Section, SectionHeader, SectionDescription, Header, StyledButton as Button } from './payment-page.style';

export interface ICardInformationErrors {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

const PaymentPage = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const {
    userType,
    stripeClientSecretKey,
    isRequestingStripeClientKey,
    isUpdatingUserProfile,
    profile: { bankAccountNumber, stripCustomerId, isCardRegistered, isVippsUser, birthDate },
  } = useStateSelector(({ user }) => user);

  const [cardConfirmError, setCardConfirmError] = React.useState<string>('');
  const [userBankAccount, setUserBankAccount] = React.useState<string>(bankAccountNumber || '');
  const [userIsVippsUser, setIsVippsUser] = React.useState<boolean>(isVippsUser || false);
  const [paymentValidationResult, setPaymentValidationResult] = React.useState<ValidationResult>(
    new ValidationResult(false, []),
  );
  const [cardInformationErrors, setCardInformationErrors] = React.useState<ICardInformationErrors>({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const isCardValid = React.useCallback(() => Object.values(cardInformationErrors).every((value) => value), [
    cardInformationErrors,
  ]);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (!isCardRegistered) {
      dispatch(setupPaymentCustomer());
    }
  }, [dispatch, stripCustomerId, userType, isCardRegistered]);

  React.useEffect(() => {
    let validationResult: ValidationResult = new ValidationResult(false, []);

    if (userType === USER_TYPE.RUNNER) {
      validationResult = validatePaymentProfile(USER_TYPE.RUNNER, {
        isVippsUser: userIsVippsUser,
        bankAccountNumber: userBankAccount,
      });
    }

    if (userType === USER_TYPE.BUYER) {
      validationResult = validatePaymentProfile(USER_TYPE.BUYER, {
        isVippsUser: userIsVippsUser,
        isCardRegistered: isCardRegistered,
      });
      if (isCardValid()) {
        validationResult = new ValidationResult(true, []);
      }
    }

    setPaymentValidationResult(validationResult);
  }, [userType, userBankAccount, userIsVippsUser, cardInformationErrors, isCardValid, isCardRegistered]);

  const formSummitHandler = async (evt) => {
    evt.preventDefault();

    if (userBankAccount || userIsVippsUser) {
      dispatch(
        updateUserProfile({ isVippsUser: userIsVippsUser, bankAccountNumber: userBankAccount, birthDate: birthDate }),
      );
    }

    if (!stripe || !elements || !stripeClientSecretKey || !isCardValid()) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    const result = await stripe.confirmCardSetup(stripeClientSecretKey, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result?.error) {
      setCardConfirmError(result?.error?.message || '');
    } else if (result?.setupIntent?.status === 'succeeded') {
      dispatch(confirmCardRegistration());
    }
  };

  const isVippsUserChangeHandler = React.useCallback(
    (evt) => {
      setIsVippsUser(evt?.target?.checked || false);
    },
    [setIsVippsUser],
  );

  const bankAccountNumberChangeHandler = React.useCallback(
    (evt) => {
      setUserBankAccount(evt?.target?.value || '');
    },
    [setUserBankAccount],
  );

  const onCardInformationChange = React.useCallback(
    (evt: StripeElementChangeEvent) => {
      if (!evt) {
        return;
      }

      const { elementType, error, complete } = evt;

      setCardInformationErrors((previousError) => ({ ...previousError, [elementType]: complete && !error }));
    },
    [setCardInformationErrors],
  );

  const isValid = (fieldName: string) => !paymentValidationResult.errorFields.includes(fieldName);

  const backAccountSection = (
    <React.Fragment>
      <SectionHeader>{`${
        userType === USER_TYPE.BUYER ? `(${formatMessage({ id: 'common_optional', defaultMessage: 'Optional' })})` : ''
      } ${formatMessage({
        id: 'payment_receivingpayments',
        defaultMessage: 'Receiving payments',
      })}`}</SectionHeader>
      <SectionDescription>
        <FormattedMessage
          id="payment_receivingpayments_text"
          defaultMessage="Payments get directly deposited into your bank account"
        />
      </SectionDescription>
      <LabeledInput
        type="numeric"
        pattern="[0-9]*"
        name="bankAccountNumber"
        value={userBankAccount}
        fullWidth
        label={formatMessage({ id: 'payment_bankaccountnumber', defaultMessage: 'Bank account' })}
        placeholder="1234 12 12345"
        onChange={bankAccountNumberChangeHandler}
        isValid={isValid('bankAccountNumber')}
      />
    </React.Fragment>
  );

  // TODO: Move labels into to StripeCreditCard to reduce passing unnecessary props
  const creditCardSection = (
    <React.Fragment>
      <SectionHeader>{`${
        userType === USER_TYPE.RUNNER ? `(${formatMessage({ id: 'common_optional', defaultMessage: 'Optional' })})` : ''
      } ${formatMessage({ id: 'payment_makingpayments', defaultMessage: 'Making Payments' })}`}</SectionHeader>
      <SectionDescription>
        <FormattedMessage
          id="payment_makingpayments_text"
          defaultMessage="Add a payment card to order from other delivery runners"
        />
      </SectionDescription>
      <StripeCreditCard
        onChange={onCardInformationChange}
        error={cardConfirmError}
        card_label={formatMessage({ id: 'payment_creditcardnumber', defaultMessage: 'Card' })}
        expiration_label={formatMessage({ id: 'payment_expiration', defaultMessage: 'Expiration' })}
        security_label={formatMessage({ id: 'payment_securitycode', defaultMessage: 'Security' })}
        cardInformationErrors={cardInformationErrors}
        userRequiresCard={userRequiresCard(userType)}
      />
    </React.Fragment>
  );

  const paymentFormElements =
    userType === USER_TYPE.BUYER ? [creditCardSection, backAccountSection] : [backAccountSection, creditCardSection];

  const renderFormElement = React.useMemo(
    () => paymentFormElements.map((element, index) => <Section key={index}>{element}</Section>),
    [paymentFormElements],
  );

  return (
    <Wrapper>
      <Container style={{ paddingTop: 40 }}>
        <StyledCentered>
          <Header>
            <FormattedMessage id="payment_title" defaultMessage="Step 2: Payment info" />
          </Header>
          <Description>
            <FormattedMessage id="payment_subtitle" defaultMessage=" " />
          </Description>
          <InputForm fullWidth onSubmit={formSummitHandler}>
            <br />
            <SectionHeader>Vipps</SectionHeader>
            <SectionDescription>
              {formatMessage({ id: 'payment_vipps_subtitle', defaultMessage: ' ' })}
            </SectionDescription>
            <CheckInput
              name="isVippsUser"
              checked={userIsVippsUser}
              fullWidth
              label={formatMessage({ id: 'payment_isVippsUser_label', defaultMessage: 'Yes, I have a Vipps user' })}
              onChange={isVippsUserChangeHandler}
              isValid={isValid('isVippsUser')}
            />
            {renderFormElement}
            <Button
              type="submit"
              text={formatMessage({ id: 'common_next', defaultMessage: 'Next' })}
              variant="primary"
              buttonSize="XS"
              fullWidth
              disabled={!paymentValidationResult.isValid || isRequestingStripeClientKey || isUpdatingUserProfile}
            />
          </InputForm>
        </StyledCentered>
      </Container>
    </Wrapper>
  );
};

export default PaymentPage;
